import React from "react";
import { 
  Row, Col
} from "reactstrap";

import DataCard from "../DataCard";
import { useTranslation } from "react-i18next";
const General = ({ucData}) => {
  const { t } = useTranslation();

  return (
    <div className="mx-5">
      <Row>
        <Col>
          <DataCard 
            title={t('water.unitConsumption')} 
            value={ucData?.generalUcConsumption}
            unit="m³"
            dark
          />
        </Col> 

        <Col>
          <DataCard 
            title={t('water.unitValue')}
            value={ucData?.totalConsumption?.price}
            unit="R$"
            reverse
            dark
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <DataCard 
            title={t('water.comumAreaShare')}
            value={ucData?.commonAreaApportionment}
            unit="R$"
            reverse
            dark
          />
        </Col> 

        <Col>
          <DataCard 
            title={t('water.totalToPay')}
            value={ucData?.totalCost}
            unit="R$"
            reverse
            dark
          />
        </Col>
      </Row>
    </div>
  )
};

export default General;