const ptBrWaterTranslations = {
    water:{
        period:"Periodo",
        measurementCost: "Custo da Medição",
        companyRead:'Consumo Concessionária',
        meterRead: 'Consumo Medição',
        comumArea: 'Consumo Área Comum',
        companyBudget: 'Conta Concessionária',
        condominumBudget: 'Conta Condomínio',
        condominumRead: 'Medição Condomínio',
        comumAreaCost: 'Custo Área Comum',
        consumptionUnits: 'Unidades consumidoras',
        general: 'Geral',
        generalCondominiumData: 'Dados Gerais do Condomínio',
        cubicMeterPrice: 'Preço m³',
        metersNoData: 'Não há dados relativos à Unidade Consumidora selecionada',
        toDefine: 'À definir',
        ucInfo: "Informações da UC ",
        plusInfos: "Informações Adicionais",
        unitConsumption:'Consumo da Unidade',
        unitValue:'Valor da Unidade',
        comumAreaShare: 'Rateio da Área Comum',
        totalToPay: 'Total a pagar',
        registerNo: 'Registro',
        previousRead: 'Leitura anterior',
        actualRead: 'Leitura atual',
        companyConsumption: 'Consumo da concessionaria',
        readConsumption: 'Consumo Medição',
        settings: 'Configurações',
        valueToPay:'Valor a pagar à concessionária:',
        consumptionByCompany: 'Consumo informado pela concessionária:',
        periodEnd:'Final do Período de Medição:',
        periodInit: 'Início do Período de Medição:',
        waterModuleConfigure: 'Configurações de Módulo Água',
        ucName: 'Nome da unidade',
        ucLinkUser: 'Vincular',
        deleteConfirm: '  Para confirmar a remoção da Unidade, por favor, digite "DELETE" no campo abaixo:',
        managerUC:' Gerenciar Medidores da Unidade Consumidora',
        meterName: 'Nome do medidor:',
        selectDevice: 'Selecione o dispositivo:',
        selectVariable: 'Selecione a variavel:',
        selectUser: 'Selecione um usuário',
        noUser: 'Nenhum usuário',
        initialRead: 'Leitura Inicial',
        addUc:' Adicionar Unidade Consumidora ao Projeto ',
        manageMeters: 'Gerenciar Medidores',
        managerUcs:' Gerenciar Unidades Consumidoras',
        previousValue: "Leitura anterior",
        currentValue: "Leitura atual",
        searchByReport: "Procurar por relatório",
        price: "Preço",
        consumptionUnit: "Unidade Consumidora",
        meter: "Medidor",
        searchByDataConfig: "Dados Configurados",
        generalConsumption: "Consumo Geral",
        noData: "Sem dados",
        report: "Relatório",
        pdf: {
          graphicAnnualConsumption: "Gráfico de consumo anual",
          currentMonthConsumptionTable: "Tabela de consumo mês atual"
        },
        erro: {
          fetchListUcs: "Erro ao buscar lista de Unidades Consumidoras",
          fetchListUsers: "Erro ao buscar lista de usuários",
          fetchGeneralData: "Erro ao buscar dados gerais",
          fetchEditUc: "Erro ao editar a Unidade Consumidora",
          fetchDeleteUc: "Erro ao deletar a Unidade Consumidora",
          fetchEditMeter: "Erro ao editar o medidor",
          fetchAddMeters: "Erro ao adicionar medidores",
          fetchSearchMeters: "Erro ao buscar medidores",
          fetchDeleteMeters: "Erro ao deletar medidores",
          fetchAddUc: "Erro ao adicionar Unidade Consumidora",
          fetchSearhDevices: "Erro ao buscar dispositivos",
          fetchSearchVariables: "Erro ao buscar variáveis",
          fetchUpdatePlant: "Erro ao gerar fatura",
          fetchReadPreviousMonthWaterData: "Erro ao buscar dados de água do mês anterior",
        }
    },
}

export default ptBrWaterTranslations;