import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useConsumerUnitContext } from "../../ConsumerUnitProvider";

import {
  updateConsumerUnit,
} from "../../ConsumerUnitService";

export const ModalConsumerUnitParams = ({ open, toggle, }) => {

  const { t } = useTranslation();

  const { setLoadingData, consumerUnit, deviceId, getUc } = useConsumerUnitContext();
  const [ucName, setUcName] = useState(null);

  const getUcValues = async () => {
    if(consumerUnit) {
      setUcName(consumerUnit.description);
    }
  };

  async function handleUpdate() {
    setLoadingData(true);
    toggle();
    
    const data = await updateConsumerUnit({
      ucId: consumerUnit.ucId,
      ucName
    });

    if (data?.uc) {
      getUc(deviceId);
    }

    setLoadingData(false);
  }

  useEffect(() => {
      getUcValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId]);

  return (
    <>
        <Modal isOpen={open} toggle={toggle}>
          <ModalHeader>
            <Label>
              {t("params.editUc")}
            </Label>
          </ModalHeader>

          <ModalBody>
            <Row className="mt-2">
              <Col sm={12}>
                <Label htmlFor="ucName">{t("consumerUnit.ucName")}</Label>
                <Input
                  id="ucName"
                  type="text"
                  name="ucName"
                  value={ucName}
                  onChange={(e) => {
                    setUcName(e.target.value);
                  }}
                ></Input>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
              <Button
                color="primary"
                onClick={() => handleUpdate()}
              >
                {t("params.save")}
              </Button>
            <Button color="secondary" onClick={toggle}>
                {t("params.cancel")}
            </Button>
          </ModalFooter>
        </Modal>
    </>
  );
};
