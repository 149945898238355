import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSolarPanel } from "react-icons/fa";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
import { CardHeaderNoc } from "../Card";

export const InfoSolar = ({
  companyId = localStorage.getItem("companyId"),
}) => {
  const refetchInterval = 1000 * 60 * 15;
  const [selectedStatus, setSelectedStatus] = useState("online");
  const currentTheme = useSelector((store) => store.theme.currentTheme);
  const { t } = useTranslation();

  const {
    status,
    data: reactQuery,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["companyListStatusSolarDevices"],
    queryFn: async () => {
      const res = await axios.get(
        `v2/company/listStatusSolarDevices?companyId=${companyId}`
      );
      return res.data;
    },
    refetchInterval: refetchInterval,
  });

  const statusLabels = {
    online: t("noc.labels.online"),
    offline: t("noc.labels.offline"),
    noCommunication: t("noc.labels.noCommunication"),
  };

  const statusColors = {
    online: currentTheme.success,
    offline: currentTheme.warning,
    noCommunication: currentTheme.disabled,
  };

  const statusKeys = Object.keys(statusLabels);
  const totalDevices = reactQuery
    ? Object.values(reactQuery).reduce((sum, count) => sum + count, 0)
    : 0;

  const handleNextStatus = () => {
    setSelectedStatus((prevStatus) => {
      const currentIndex = statusKeys.indexOf(prevStatus);
      return statusKeys[(currentIndex + 1) % statusKeys.length];
    });
  };

  const handlePreviousStatus = () => {
    setSelectedStatus((prevStatus) => {
      const currentIndex = statusKeys.indexOf(prevStatus);
      return statusKeys[
        currentIndex === 0 ? statusKeys.length - 1 : currentIndex - 1
      ];
    });
  };

  useEffect(() => {
    refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[companyId])

  return (
    <CardHeaderNoc
      IconComponent={FaSolarPanel}
      color={statusColors[selectedStatus]}
      numberSelected={reactQuery ? reactQuery[selectedStatus] : "0"}
      total={totalDevices}
      status={statusLabels[selectedStatus]}
      next={handleNextStatus}
      prev={handlePreviousStatus}
      loading={status === "loading" || isFetching}
      error={status === "error"}
      handleReload={refetch}
      title={t("noc.labels.solar")}
      subtitle={t("noc.main.headerCards.devices")}
    />
  );
};
