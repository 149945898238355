import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { useTranslation } from 'react-i18next';
import { useState } from "react";
import { useAPI } from '../../SolarProvider';

const ModalPvSyst = ({ open, toggle }) => {
  const { t } = useTranslation();
  const { setPvSyst, readPvSyst } = useAPI();
  const [valores, setValores] = useState(readPvSyst.map((item, index) => Object.values(item)[0]) || []);

  const handleChange = (event, mes) => {
    const novoValor = parseInt(event.target.value);
    setValores((prevValores) => ({ ...prevValores, [mes]: novoValor || 0 }));
  };

  const mesesAbreviados = [
    t("solar.pvSystJan"),
    t("solar.pvSystFev"),
    t("solar.pvSystMar"),
    t("solar.pvSystAbr"),
    t("solar.pvSystMai"),
    t("solar.pvSystJun"),
    t("solar.pvSystJul"),
    t("solar.pvSystAgo"),
    t("solar.pvSystSet"),
    t("solar.pvSystOut"),
    t("solar.pvSystNov"),
    t("solar.pvSystDez"),
  ];

  const handleSubmit = (event) => {
    event.preventDefault();

    const valoresFormatados = Object.values(valores).map((valor, index) => ({ [mesesAbreviados[index]]: valor || 0 }));

    setPvSyst(valoresFormatados);
    toggle();
  };
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      size='md'
      scrollable
    >
      <ModalHeader toggle={toggle}>
        {t("solar.modalPvSystHeader")}
      </ModalHeader>

      <ModalBody className="d-flex flex-column align-items-center">
        <Row>
          <Col style={{ maxWidth: 400 }}>
            <Label tag={"h4"} className="mb-4 text-center">
              O valor da projeção mensal deve conter apenas números em quilowatt-hora (kWh)
            </Label>
          </Col>
        </Row>
        {(readPvSyst?.length) ? readPvSyst.map((item, index) => (
          <Row className="d-flex flex-column align-items-center" style={{ display: "flex" }} >
            <Col sm={6} className="d-flex" key={index + 1}>
              <Label htmlFor={`mes-${Object.keys(item)[0]}`}>{Object.keys(item)[0]}</Label>
            </Col>
            <Col sm={8}>
              <Input
                type="number"
                id={`mes-${index}`}
                value={valores[index]}
                onChange={(event) => handleChange(event, index)}
              />
            </Col>
          </Row>
        ))
          : Array.from({ length: 12 }, (_, index) => {
            const mes = index + 1;
            const mesAbreviado = mesesAbreviados[index];
            return (
              <Row className="d-flex flex-column align-items-center" style={{ display: "flex" }} >
                <Col sm={6} className="d-flex" key={mes}>
                  <Label htmlFor={`mes-${mes}`}>{mesAbreviado}</Label>
                </Col>
                <Col sm={8}>
                  <Input
                    type="number"
                    id={`mes-${mes}`}
                    value={valores[mes] || ''}
                    onChange={(event) => handleChange(event, mes)}
                  />
                </Col>
              </Row>
            );
          })
        }



      </ModalBody>

      <ModalFooter>
        <Button
          type="button"
          color='primary'
          className="mb-1"
          onClick={handleSubmit}
        >
          {t('solar.save')}
        </Button>
        <Button
          color='primary'
          className="mb-1"
          onClick={toggle}
          outline

        >
          {t('solarReport.closeButton')}
        </Button>
      </ModalFooter>
    </Modal >
  )
}

export default ModalPvSyst;