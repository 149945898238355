import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { Maximize2 } from "react-feather";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Button,
  CardHeader,
  CardText,
  CardTitle,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import { usePlantContext } from "../PlantProvider";

export const LineChartCarbonEmissionGeneralPlant = ({
  windowObervable,
  projectId,
  isModal = false,
}) => {
  const { t } = useTranslation();
  const currentTheme = useSelector((store) => store.theme.currentTheme);
  const { chart, loadingChart } = usePlantContext();

  const [toggleModal, setToggleModal] = useState(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  const dates = chart?.arrayConsumption.map((data) => data.date);
  const carbonEmission = chart?.arrayCarbonEmission.map((data) =>
    parseFloat(data.carbonEmission).toFixed(2)
  );

  const data = {
    labels: dates,
    datasets: [
      {
        label: "tco²",
        data: carbonEmission,
        borderWidth: 1,
        backgroundColor: currentTheme.secondary,
        fill: true,
        stack: true,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: "bottom",
      labels: {
        padding: 30,
        fontSize: 14,
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
  };

  if (loadingChart)
    return (
      <div className="d-flex justify-content-center m-4">
        <Spinner />
      </div>
    );
  if (!chart?.arrayConsumption.length && !loadingChart)
    return (
      <CardText tag="h5" className="d-flex justify-content-center m-4">
        {t("charts.noData")}
      </CardText>
    );

  return (
    <>
      <CardHeader className="d-flex align-content-center justify-content-between">
        <CardTitle tag={"h5"}>
          {t("homePlant.carbonEmission")}
          {" - "}
          {chart?.hasRefMeter
            ? t("homePlant.valueByRefMeter")
            : t("homePlant.valueByConsumerUnit")}
        </CardTitle>
        {!isModal && (
          <Button
            className="bg-transparent border-0"
            id={"button-maximize-dashboard"}
            onClick={handleToggleModal}
          >
            <Maximize2 size={16} color="grey" />
            <UncontrolledTooltip target={"button-maximize-dashboard"}>
              {t("public.expand")}
            </UncontrolledTooltip>
          </Button>
        )}
      </CardHeader>
      {loadingChart ? (
        <div
          className={`d-flex justify-content-center ${
            loadingChart ? "align-items-center" : ""
          }`}
          style={{
            height: isModal
              ? windowObervable < 1400
                ? "60vh"
                : "80vh"
              : windowObervable < 1400
              ? "40rem"
              : "80rem",
            padding: isModal ? 0 : 32,
          }}
        >
          <Spinner />
        </div>
      ) : (
        <div style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          height: isModal? '500px': "100%",
          position: "relative",
        }}>
          <Line
            data={data}
            options={options}
            height={isModal? '500px': undefined}
            width={isModal? '700px': undefined}
            redraw={!!windowObervable || toggleModal}
          />
        </div>
      )}
      {toggleModal && (
        <Modal
          isOpen={toggleModal}
          toggle={handleToggleModal}
          size={windowObervable < 1400 ? "lg" : "xl"}
        >
          <ModalBody
            style={{
              maxHeight: windowObervable < 1400 ? "60vh" : "80vh",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <LineChartCarbonEmissionGeneralPlant
              isModal={true}
              projectId={projectId}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
