import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import DataCard from "./DataCard";

export const HeaderCards = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Row className="mt-2">
      <Col>
        <DataCard
          title={t("water.companyRead")}
          value={data?.dealerConsumption ?? t("none")}
          unit="m³"
        />
      </Col>

      <Col>
        <DataCard
          title={t("water.meterRead")}
          value={data?.generalConsumption ?? t("none")}
          unit="m³"
        />
      </Col>

      <Col>
        <DataCard
          title={t("water.comumArea")}
          value={data?.commonAreaConsumption ?? t("none")}
          unit="m³"
        />
      </Col>

      <Col>
        <DataCard
          title={t("water.companyBudget")}
          value={data?.utilityBill ?? t("none")}
          unit="R$"
          reverse
        />
      </Col>

      <Col>
        <DataCard
          title={t("water.comumAreaCost")}
          value={data?.countMetering ?? t("none")}
          unit="R$"
          reverse
        />
      </Col>
      <Col>
        <DataCard
          title={t("water.cubicMeterPrice")}
          value={data?.pricem3 ?? t("none")}
          unit="R$"
          reverse
        />
      </Col>
    </Row>
  );
};
