import { faFilePdf, faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { UncontrolledTooltip } from "reactstrap";
import NotificationsAlarm from "../../Alarm/NotificationsAlarm";
import { usePlantContext } from "../../PlantProvider";
import { useRefMeterContext } from "../RefMeterProvider";
import { ModalCalculationBase } from "../components/ModalCalculationBase";
import { ModalSelectReport } from "./ModalSelectReport";
import SelectMonth from "./components/SelectMonth";

const SubMenu = ({ plant }) => {
  const currentTheme = useSelector((store) => store.theme.currentTheme);
  const { dvcId } = useRefMeterContext();
  const { selectedDate } = usePlantContext();

  const [openModal, setOpenModal] = useState(false);

  const handleToggleModal = (status) => {
    setOpenModal(status);
  };

  const { t } = useTranslation();

  return (
    <div className="d-flex mx-4 align-items-center">
      <div className="px-2 flex-column inputs form-control">
        REF {"  "}
        {selectedDate
          ? moment(selectedDate).format("MM/YYYY")
          : moment().format("MM/YYYY")}
      </div>
      <div className="px-2">
        <SelectMonth />
      </div>
      <div
        id="energyexport"
        className="cursor-pointer d-flex align-items-center justify-content-center px-1"
      >
        <FontAwesomeIcon
          icon={faFilePdf}
          style={{ height: "18px", width: "18px" }}
          color={currentTheme.primary}
          onClick={() => handleToggleModal("report")}
        />

        <UncontrolledTooltip placement="top" target="energyexport">
          {t("subMenu.generateReport")}
        </UncontrolledTooltip>
      </div>

      {openModal === "report" && (
        <ModalSelectReport
          open={openModal === "report"}
          toggle={() => handleToggleModal(false)}
          projName={plant.name}
          projId={plant.id}
        />
      )}

      <div
        id="infoCalculation"
        className="cursor-pointer d-flex align-items-center justify-content-center px-1"
      >
        <FontAwesomeIcon
          icon={faInfo}
          style={{ height: "18px", width: "18px" }}
          color={currentTheme.primary}
          onClick={() => handleToggleModal("calculationBase")}
        />

        <UncontrolledTooltip placement="top" target="infoCalculation">
          {t("calculationBase.title")}
        </UncontrolledTooltip>
      </div>

      {openModal === "calculationBase" && (
        <ModalCalculationBase
          isOpen={openModal === "calculationBase"}
          handleCloseModal={() => handleToggleModal(false)}
        />
      )}
        
      {/* NOTE: Notificações de Alarmes */}
      <NotificationsAlarm
        deviceId={dvcId}
      />

    </div>

    
  );
};

export default SubMenu;
