import axios from "axios";

export const listAlarmsHistory = async ( 
    devices,
    offset,
    maxAlarms 
) => {

    try {
      const response = await axios.get(
        `/v2/alarms/listAlarmsHistory?devices=${devices}&offset=${offset}&maxAlarms=${maxAlarms}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
    
};