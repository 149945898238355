import React, { useEffect, useState } from "react";
import {
  Button,
  // CardTitle,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  UncontrolledTooltip,
  FormGroup,
  Label,
  Input,
  CardTitle,
  Spinner,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faPlus,
  faSort,
  faSortUp,
  faSortDown,
  faTachometerAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import ModalDeleteMeters from "./ModalDeleteMeters";
import { fetchListUsers } from "../../WaterService";

import { useWaterModuleAPI } from "../../WaterProvider";
import {
  fetchAddMeters,
  fetchAddUc,
  fetchDeleteUc,
  fetchEditMeter,
  fetchEditUc,
  fetchSearchMeters,
  fetchSearchVariables,
  fetchSearhDevice,
} from "../../WaterService";
const timeInterval = 750;


const ModalWaterUCs = ({ open, toggle }) => {
  const { ucList,  projectId } = useWaterModuleAPI();
  const [loading, setLoading] = useState(false); // estado de carregando
  // eslint-disable-next-line
  const [waterMeter, setWaterMeter] = useState([]);
  const [editUC, setEditUC] = useState(); // Unidade que está se editando
  const [removeUC, setRemoveUC] = useState(); // Unidade que se deseja remover
  const [manageMeters, setManageMeters] = useState(); // Unidade que está se gerenciando medidores
  const [createUC, setCreateUC] = useState(false); // estado que indica cadastro de nova Unidade

  const [modalDeleteMeter, setModalDeleteMeter] = useState(false);

  const isDeleteOpen = () => setModalDeleteMeter(!modalDeleteMeter);

  // Estados e chamadas do ManageMeters

  const [meterId, setMeterId] = useState(1);
  const [name, setName] = useState("");
  const [variableTag, setVariableTag] = useState("");
  const [registerNumber, setRegisterNumber] = useState("");
  const [variableName, setVariableName] = useState("");
  const [deviceName, setDeviceName] = useState([]);
  const [showDeviceName, setShowDeviceName] = useState("");
  const [showVariableName, setShowVariableName] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [ucId, setUcId] = useState("");
  const [initialRead, setInitialRead] = useState("");
  const [idToDelete, setIdToDelete] = useState();
  const [editMeter, setEditMeter] = useState(false);

  const [userList, setUserList] = useState([]);

  const readUsersByProject = async () => {
    const { data, status } = await fetchListUsers({ projectId });
    if (status === 200) {
      setUserList(data);
    }
  };

  const searchMeters = async (id) => {
    if (!id) return;
    setUcId(id);
    const response = await fetchSearchMeters({ucId: id});
    if (response.status === 200) {
      setWaterMeter(response.data);
    } else {
      toastr.error("Erro", "Erro ao buscar os medidores");
    }
  };

  useEffect(() => {
    if (manageMeters?.id || deviceId) {
      searchMeters(manageMeters?.id || deviceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId, manageMeters]);

  const searchDevice = async () => {
    const response = await fetchSearhDevice({projId: projectId});
    if (response.status === 200) {
      setDeviceName(response.data);
    } else {
      toastr.error("Erro", "Erro ao buscar os dispositivos");
    }
  }

  useEffect(() => {
    if (projectId) {
      searchDevice();
      readUsersByProject();
    }
    // eslint-disable-next-line
  }, [projectId]);

  //  BUSCA GANHO DE VARIAVEIS
  useEffect(() => {
    async function fetchVariableData() {
      const response = await fetchSearchVariables({id: deviceId});
      if (response.status === 200) {
        setVariableName(response.data);
      } 
    }
    if (deviceId) {
      fetchVariableData();
    }
    // eslint-disable-next-line
  }, [deviceId, editMeter, loading]);

  function handleDeleteRegister(id) {
    isDeleteOpen();
    setIdToDelete(id);
  }

  async function handleEditInput(id) {
    const findMeter = waterMeter.find((item) => item.id === id);

    if (findMeter) {
      setEditMeter(true);
      setMeterId(findMeter.id);
      setName(findMeter.description);
      setDeviceId(findMeter.deviceId);
      setInitialRead(findMeter.leituraInicial);
      setRegisterNumber(findMeter.registerNo);
      setVariableTag(findMeter.varTag);
    }

    const device = deviceName.find((dvc) => dvc.id === findMeter.deviceId);

    if (findMeter && device) {
      setShowDeviceName(device.description);

      const findTag = await variableName?.find(
        (tag) => tag.tag === findMeter.varTag
      );

      if (findTag) {
        setShowVariableName(findTag.varDesc); // update showVariableName here
      }
    }
  }

  const waterRegister = [
    {
      // ID (oculto)
      dataField: "id",
      text: "ID",
      sort: true,
      hidden: true,
    },
    {
      // nome
      dataField: "description",
      text: "Nome",
      sort: true,
    },
    {
      // ícones de ação (medidores/editar/remover)
      dataField: "actions",
      text: "Ações",
      headerStyle: { width: "20%" },
      // formatExtraData: {deviceList: deviceList},
      formatter: (cell, row, rowIndex, extra) => (
        <div className="d-flex justify-content-left">
          <div // ícone de editar
            id={"edit" + row.id}
            className="align-middle cursor-pointer mr-1"
            onClick={() => handleEditInput(row.id)}
          >
            <FontAwesomeIcon icon={faPen} fixedWidth />
            <UncontrolledTooltip placement="top" target={"edit" + row.id}>
              {t("dashboards.edit")}
            </UncontrolledTooltip>
          </div>

          <div // ícone de remover
            id={"delete" + row.id}
            className="align-middle cursor-pointer mr-1"
            onClick={() => handleDeleteRegister(row.id)}
          >
            <FontAwesomeIcon icon={faTrash} fixedWidth />
            <UncontrolledTooltip placement="top" target={"delete" + row.id}>
              {t("dataTable.delete")}
            </UncontrolledTooltip>
          </div>
        </div>
      ),
    },
  ];

  const colsUCs = [
    {
      // ID (oculto)
      dataField: "id",
      text: "ID",
      sort: true,
      hidden: true,
    },
    {
      // nome
      dataField: "description",
      text: "Nome",
      sort: true,
    },
    {
      // ícones de ação (medidores/editar/remover)
      dataField: "actions",
      text: "Ações",
      headerStyle: { width: "20%" },
      // formatExtraData: {deviceList: deviceList},
      formatter: (cell, row, rowIndex, extra) => (
        <div className="d-flex justify-content-left">
          <div // ícone de editar
            id={"edit" + row.id}
            className="align-middle cursor-pointer mr-1"
            onClick={() => {
              setLoading(true);

              setEditUC(row);
              setRemoveUC();
              setManageMeters();
              setCreateUC(false);

              setTimeout(() => {
                setLoading(false);
              }, timeInterval);
            }}
          >
            <FontAwesomeIcon icon={faPen} fixedWidth />
            <UncontrolledTooltip placement="top" target={"edit" + row.id}>
              {t("dashboards.edit")}
            </UncontrolledTooltip>
          </div>

          <div // ícone de remover
            id={"delete" + row.id}
            className="align-middle cursor-pointer mr-1"
            onClick={() => {
              setLoading(true);

              setEditUC();
              setRemoveUC(row);
              setManageMeters();
              setCreateUC(false);

              setTimeout(() => {
                setLoading(false);
              }, timeInterval);
            }}
          >
            <FontAwesomeIcon icon={faTrash} fixedWidth />
            <UncontrolledTooltip placement="top" target={"delete" + row.id}>
              {t("dataTable.delete")}
            </UncontrolledTooltip>
          </div>

          <div // ícone de gerenciar medidores
            id={"gauge" + row.id}
            className="align-middle cursor-pointer mr-1"
            onClick={() => {
              setLoading(true);

              setEditUC();
              setRemoveUC();
              setManageMeters(row);
              setCreateUC(false);

              setTimeout(() => {
                setLoading(false);
              }, timeInterval);
            }}
          >
            <FontAwesomeIcon icon={faTachometerAlt} fixedWidth />
            <UncontrolledTooltip placement="top" target={"gauge" + row.id}>
              {t("water.manageMeters")}
            </UncontrolledTooltip>
          </div>
        </div>
      ),
    },
  ];

  /* Ícones de ordenação */
  const sortIcon = {
    sortCaret: (order) =>
      !order ? (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faSort} fixedWidth />
      ) : order === "asc" ? (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faSortUp} fixedWidth />
      ) : (
        <FontAwesomeIcon
          className={"fa-xs ml-1"}
          icon={faSortDown}
          fixedWidth
        />
      ),
  };

  return (
    <Modal isOpen={open} toggle={toggle} size="lg" scrollable>
      <ModalHeader toggle={toggle}>{t("water.managerUcs")}</ModalHeader>

      <ModalDeleteMeters
        toggle={toggle}
        modalDeleteMeter={modalDeleteMeter}
        isDeleteOpen={isDeleteOpen}
        waterMeter={waterMeter}
        id={idToDelete}
      />

      <ModalBody className="mx-3">
        <Row>
          {loading ? (
            <Col lg={12} md={12} className="d-flex justify-content-center">
              <Spinner color="primary" />
            </Col>
          ) : // :(manageMeters)?
          // <Col md={4}>
          // {/* <ListofMeters waterData={waterData} setLoading={setLoading} setManageMeters={setManageMeters} toggle={toggle} /> */}
          // </Col>

          manageMeters ? (
            <Col md={4}>
              <Label className="d-flex justify-content-center">Registros</Label>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={waterMeter}
                columns={waterRegister}
                condensed
                bordered={false}
                striped={true}
                // selectRow={selectRow}
                sort={sortIcon}
              />
            </Col>
          ) : (
            <Col md={4}>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={ucList}
                columns={colsUCs}
                condensed
                bordered={false}
                striped={true}
                // selectRow={selectRow}
                sort={sortIcon}
              />

              <div id="addBtn" className="text-center">
                <Button
                  color="primary"
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();
                    setLoading(true);

                    setEditUC();
                    setRemoveUC();
                    setManageMeters();
                    setCreateUC(true);

                    setTimeout(() => {
                      setLoading(false);
                    }, timeInterval);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} fixedWidth />
                </Button>
                <UncontrolledTooltip placement="top" target="addBtn">
                  Adicionar Unidade
                </UncontrolledTooltip>
              </div>
            </Col>
          )}
          <Col md={8}>
            {loading ? null : editUC ? (
              <EditUC 
              uc={editUC} 
              setLoading={setLoading} 
              toggle={toggle} 
              userList={userList}
              readUsersByProject={readUsersByProject}
              />
            ) : removeUC ? (
              <RemoveUC
                uc={removeUC}
                setLoading={setLoading}
                toggle={toggle}
                comeBack={() => setRemoveUC("")}
                readUsersByProject={readUsersByProject}
              />
            ) : manageMeters ? (
              <ListUcs
                searchMeters={searchMeters}
                meterId={meterId}
                setMeterId={setMeterId}
                name={name}
                setName={setName}
                variableTag={variableTag}
                setVariableTag={setVariableTag}
                registerNumber={registerNumber}
                setVariableName={setVariableName}
                setRegisterNumber={setRegisterNumber}
                variableName={variableName}
                deviceName={deviceName}
                deviceId={deviceId}
                setDeviceId={setDeviceId}
                ucId={ucId}
                initialRead={initialRead}
                setInitialRead={setInitialRead}
                uc={manageMeters}
                setManageMeters={setManageMeters}
                waterMeter={waterMeter}
                setWaterMeter={setWaterMeter}
                showDeviceName={showDeviceName}
                setShowDeviceName={setShowDeviceName}
                showVariableName={showVariableName}
                setShowVariableName={setShowVariableName}
                toggle={toggle}
                setEditMeter={setEditMeter}
                editMeter={editMeter}
                projId={projectId}
              />
            ) : createUC ? (
              <CreateUC
                setLoading={setLoading}
                toggle={toggle}
                projId={projectId}
                userList={userList}
                readUsersByProject={readUsersByProject}
              />
            ) : (
              "Clique em <> para editar uma Unidade. Clique em <> para gerenciar seus medidores de água. Clique em + para cadastrar uma nova Unidade."
            )}
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter>
        <Button
          type="button"
          color="primary"
          className="mr-1 mb-1"
          onClick={toggle}
        >
          Fechar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const ListUcs = ({
  searchMeters,
  uc,
  setManageMeters,
  waterMeter,
  setWaterMeter,
  ucId,
  deviceId,
  variableTag,
  name,
  deviceName,
  variableName,
  registerNumber,
  initialRead,
  editMeter,
  meterId,
  showVariableName,
  showDeviceName,
  setMeterId,
  setName,
  setRegisterNumber,
  setDeviceId,
  setVariableTag,
  setInitialRead,
  setShowDeviceName,
  setShowVariableName,
  setEditMeter,
}) => {
  function handleAddNewMeterOnList() {
    if (!deviceId || !variableTag || !name || !registerNumber || !initialRead) {
      return toastr.warning(
        "Atenção",
        "Alguns campos não foram preenchidos corretamente."
      );
    }

    let meterObj = {
      id: meterId,
      ucId: ucId,
      deviceId: deviceId,
      variableTag: variableTag,
      description: name,
      registerNumber: registerNumber,
      initialRead: initialRead,
    };

    setWaterMeter([...waterMeter, meterObj]);
    setMeterId(meterId + 1);
  }

  function backToUCs() {
    setName("");
    setEditMeter(false);
    setManageMeters("");
    setRegisterNumber("");
    setInitialRead("");
    setDeviceId("");
    setShowDeviceName("");
    setShowVariableName("");
  }

  async function onUpdateEditedMeter() {
    const updatedObj = {
      meterId: meterId,
      ucId: ucId,
      description: name,
      deviceId: deviceId,
      variableTag: variableTag,
      registerNumber: registerNumber,
      initialRead: initialRead,
    };

    const response = await fetchEditMeter({ body: JSON.stringify(updatedObj) });
    if (response.status === 200) {
      toastr.success(response.data.messageHead, response.data.messageBody);
      searchMeters();
    } else {
      toastr.warning("Atenção", response.data.messageBody);
    }
  }

  const handleAddNewMeters = async () => {
    const response = await fetchAddMeters({body: JSON.stringify(waterMeter)});
    if (response.status === 200) {
      toastr.success(response.data.messageHead, response.data.messageBody);
      backToUCs();
    } else {
      toastr.warning("Atenção", response.data.messageBody);
    }
  }

  return (
    <>
      <CardTitle tag="h5">
        {t("water.managerUC")} {uc.description}
      </CardTitle>

      <Form
        style={{ width: "50%" }}
        className="d-flex flex-column justify-content-between"
        onSubmit={(e) => {
          e.preventDefault();
          if (!waterMeter) {
            return toastr.warning(
              "Atenção",
              "Adicione o medidor antes de salvar."
            );
          }
          handleAddNewMeters();
        }}
      >
        <FormGroup>
          <Label>{t("water.meterName")}</Label>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <Label>{t("water.registerNo")}:</Label>
          <Input
            type="text"
            value={registerNumber}
            onChange={(e) => setRegisterNumber(e.target.value)}
          />

          <Label>{t("water.selectDevice")}</Label>
          <Input
            type="select"
            defaultValue={showVariableName ? showVariableName : ""}
            onChange={(e) => {
              setDeviceId(e.target.value);
            }}
          >
            <option key={0} value={""} disabled>
              {showDeviceName ? showDeviceName : "Selecione o dispositivo"}
            </option>
            {deviceName.length
              ? deviceName.map((value, index) => (
                  <option key={index} value={value.id}>
                    {value.description}
                  </option>
                ))
              : null}
          </Input>

          <Label>{t("water.selectVariable")}</Label>
          <Input
            type="select"
            value={variableTag ? variableTag : ""}
            onChange={(e) => {
              setVariableTag(e.target.value);
            }}
          >
            <option key={0} value={""} disabled>
              {showVariableName ? showVariableName : "Selecione a variavel"}
            </option>
            {variableName.length
              ? variableName.map((value, index) => (
                  <option key={index} value={value.tag}>
                    {value.varName}
                  </option>
                ))
              : null}
          </Input>

          <Label>{t("water.initialRead")}</Label>
          <Input
            type="number"
            value={initialRead}
            onChange={(e) => setInitialRead(e.target.value)}
          />
        </FormGroup>

        <div className="d-flex justify-content-between">
          <Button
            type="button"
            color="primary"
            size="sm"
            outline
            onClick={backToUCs}
          >
            {t("dashboards.cancel")}
          </Button>
          {!editMeter ? (
            <Button
              type="button"
              color="primary"
              size="sm"
              onClick={handleAddNewMeterOnList}
            >
              {t("Adicionar")}
            </Button>
          ) : null}
          {editMeter ? (
            <Button
              type="button"
              color="primary"
              size="sm"
              onClick={onUpdateEditedMeter}
            >
              {t("Atualizar")}
            </Button>
          ) : (
            <Button
              type="submit"
              color="primary"
              size="sm"
              disabled={!waterMeter.length ? true : false}
            >
              {t("dashboards.save")}
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

const CreateUC = ({ projId, setLoading, toggle, userList, readUsersByProject }) => {
  const { readUCsByProject } = useWaterModuleAPI();
  const [name, setName] = useState("");
  const [user, setUser] = useState(null);

  const handleAddNewUc = async () => {
    let sendParams = {
      projId,
      description: name,
      userId: user?.idUser || null,
    };

    const response = await fetchAddUc({body: JSON.stringify(sendParams)})
    if (response.status === 200) {
      toastr.success(response.data.messageHead, response.data.messageBody);
      setLoading(true);
    }
    setTimeout(() => {
      setLoading(false);
      readUCsByProject();
      readUsersByProject();
    }, timeInterval);
  }

  return (
    <>
      <CardTitle tag="h5">
        {t("water.addUc")}
      </CardTitle>

      <Form
        style={{ width: "50%", height: "83%" }}
        className="d-flex flex-column"
        onSubmit={(e) => {
          e.preventDefault();
          handleAddNewUc();
        }}
      >
        <FormGroup>
          <Label>{t("dashboards.name")}</Label>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <Label>{t("water.ucLinkUser")}</Label>
          <Input
            type="select"
            onChange={(e) => {
              const selectedUser = userList.find(user => user.idUser === parseInt(e.target.value));
              setUser(selectedUser);
            }}
            >
            <option value={null}>{t("water.noUser")}</option>
            {userList.filter(user => !user.isLinked).map(user => (
              <option key={user.idUser} value={user.idUser}>{user.name}</option>
            ))}
          </Input>
        </FormGroup>

        <div className="d-flex justify-content-between">
          <Button
            type="button"
            color="primary"
            size="sm"
            outline
            onClick={toggle}
          >
            {t("dashboards.cancel")}
          </Button>

          <Button type="submit" color="primary" size="sm">
            {t("dashboards.save")}
          </Button>
        </div>
      </Form>
    </>
  );
};

const EditUC = ({ uc, toggle, userList, readUsersByProject }) => {
  const { readUCsByProject } = useWaterModuleAPI();
  const { t } = useTranslation();
  const [name, setName] = useState(uc.description);
  const [user, setUser] = useState(userList.find(user => user.idUser === uc.userId));
  const [sendUpdate, setSendUpdate] = useState(false);
  const jsonData = {
    id: uc.id,
    description: name,
    userId: user?.idUser || null
  };

  const handleEditUc = async () => {
    setSendUpdate(true);
    const response = await fetchEditUc({ body: jsonData });
    if (response.status === 200) {
      toastr.success(response.data.messageHead, response.data.messageBody);
    }
    setSendUpdate(false);
    readUCsByProject();
    readUsersByProject();
  };

  return (
    <>
      <CardTitle tag="h5">Editar Unidade {uc.description}</CardTitle>

      <Form
        style={{ width: "50%" }}
        className="d-flex flex-column justify-content-between"
        onSubmit={(e) => {
          e.preventDefault();
          handleEditUc();
        }}
      >
        <FormGroup>
          <Label>{t("water.ucName")}</Label>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Label>{t("water.ucLinkUser")}</Label>
          <Input
            type="select"
            onChange={(e) => {
              const selectedUser = userList.find(user => user.idUser === parseInt(e.target.value));
              setUser(selectedUser);
            }}
            >
            <option value={null}>{t("water.noUser")}</option>
            {uc.userId && <option selected value={uc.userId}>{ uc.userName}</option>}
            {userList.filter(user => !user.isLinked).map(user => (
              <option key={user.idUser} value={user.idUser}>{user.name}</option>
            ))}
          </Input>
        </FormGroup>

        <div className="d-flex justify-content-between">
          <Button
            type="button"
            color="primary"
            size="sm"
            outline
            onClick={toggle}
          >
            {t("dashboards.cancel")}
          </Button>

          <Button type="submit" color="primary" size="sm" disabled={sendUpdate}>
            {t("dashboards.save")}
          </Button>
        </div>
      </Form>
    </>
  );
};

const RemoveUC = ({ uc, setLoading, toggle, comeBack, readUsersByProject }) => {
  const { readUCsByProject } = useWaterModuleAPI();
  const [captcha, setCaptcha] = useState("");

  const handleDeleteUc = async () => {
    const response = await fetchDeleteUc({ ucId: uc.id, userId: parseInt(uc?.userId) });
    if (response.status === 200) {
      toastr.success(response.data.messageHead, response.data.messageBody);
      setLoading(true);
    } else {
      toastr.error(response.data.messageHead, response.data.messageBody);
    }
    readUCsByProject();
    readUsersByProject();
    setTimeout(() => {
      setLoading(false);
      comeBack();
    }, timeInterval);
  };

  return (
    <>
      <CardTitle tag="h5">Remover Unidade {uc.description}</CardTitle>

      <Form
        style={{ width: "50%" }}
        className="d-flex flex-column justify-content-between"
        onSubmit={(e) => {
          e.preventDefault();
          handleDeleteUc();
        }}
      >
        <FormGroup>
          <Label className="mb-3">{t("water.deleteConfirm")}</Label>
          <Input
            type="text"
            value={captcha}
            onChange={(e) => setCaptcha(e.target.value)}
          />
        </FormGroup>

        <div className="d-flex justify-content-between">
          <Button
            type="button"
            color="primary"
            size="sm"
            outline
            onClick={toggle}
          >
            {t("dashboards.cancel")}
          </Button>

          <Button type="submit" color="primary" size="sm">
            {t("Confirmar")}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ModalWaterUCs;
