import {
  faCog,
  faCoins,
  faWindowRestore,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { UncontrolledTooltip } from "reactstrap";
import { usePlantContext } from "../PlantProvider";
import { PlantConfig } from "./PlantConfig/";
import SelectMonth from "./components/SelectMonth";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Preferences from "./components/Preferences";
import ModalDeletePlantData from "./components/ModalDeletePlantData";

const SubMenu = ({ plant }) => {
  const currentTheme = useSelector((store) => store.theme.currentTheme);
  const { selectedDate } = usePlantContext();
  const [isModalParamsOpen, setIsModalParamsOpen] = useState(false);
  const [isModalPreferencesOpen, setIsModalPreferencesOpen] = useState(false);
  const [isModalResetPlantOpen, setIsModalResetPlantOpen] = useState(false);

  function toggleModalParams() {
    setIsModalParamsOpen(!isModalParamsOpen);
  }

  function toggleModalPreferences() {
    setIsModalPreferencesOpen(!isModalPreferencesOpen);
  }

  function toggleModalResetPlant() {
    setIsModalResetPlantOpen(!isModalResetPlantOpen);
  }

  const { t } = useTranslation();

  return (
    <div className="d-flex mx-4 align-items-center justify-content-end">
      <div className="px-2 flex-column inputs form-control">
        REF {"  "}
        {selectedDate
          ? moment(selectedDate).format("MM/YYYY")
          : moment().format("MM/YYYY")}
      </div>
      <div className="px-2">
        <SelectMonth />
      </div>

      <div className="cursor-pointer d-flex align-items-center justify-content-center px-1">
        <Preferences
          projId={plant.id}
          open={isModalPreferencesOpen}
          toggle={toggleModalPreferences}
        />
      </div>

      <div
        id="energyexport"
        className="cursor-pointer d-flex align-items-center justify-content-center px-1"
      ></div>

      {localStorage.getItem("userType") !== "3" && (
        <>
          <div
            id="energyconfig"
            className="cursor-pointer d-flex align-items-center justify-content-center px-1"
          >
            <FontAwesomeIcon
              icon={faCog}
              style={{ height: "18px", width: "18px" }}
              color={currentTheme.primary}
              onClick={toggleModalParams}
            />

            <UncontrolledTooltip placement="top" target="energyconfig">
              {t("subMenu.configParams")}
            </UncontrolledTooltip>
          </div>

          <Link to={`/project-${plant.id}-default/sharing/${plant.companyId}`}>
            <div
              id="sharingPage"
              className="cursor-pointer d-flex align-items-center justify-content-center px-1"
            >
              <FontAwesomeIcon
                icon={faCoins}
                style={{ height: "18px", width: "18px" }}
                color={currentTheme.primary}
                onClick={toggleModalParams}
              />

              <UncontrolledTooltip placement="top" target="sharingPage">
                {t("subMenu.sharing")}
              </UncontrolledTooltip>
            </div>
          </Link>

          <div
            id="resetPlant"
            className="cursor-pointer d-flex align-items-center justify-content-center px-1"
          >
            <FontAwesomeIcon
              icon={faWindowRestore}
              style={{ height: "18px", width: "18px" }}
              color={currentTheme.primary}
              onClick={toggleModalResetPlant}
            />

            <UncontrolledTooltip placement="top" target="resetPlant">
              {t("subMenu.resetPlant")}
            </UncontrolledTooltip>
          </div>
        </>
      )}

      {isModalParamsOpen && (
        <PlantConfig
          open={isModalParamsOpen}
          toggle={toggleModalParams}
          projName={plant.name}
          projId={plant.id}
        />
      )}

      {isModalResetPlantOpen && (
        <ModalDeletePlantData
          open={isModalResetPlantOpen}
          toggle={toggleModalResetPlant}
          name={plant.name}
          id={plant.id}
        />
      )}
    </div>
  );
};

export default SubMenu;
