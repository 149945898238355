const htmlToImage = require('html-to-image');
const moment = require('moment');

function formatTimestamps(timestamps, range) {
  return timestamps.map((timestamp) => formatTimestamp(timestamp, range));
}
function formatTimestamp(timestamp, range) {
    const date = moment.unix(timestamp);
    switch (range) {
      case "days":
      return date.format("DD/MM - ddd");
      case "years":
      return date.format("YYYY");
      case "months":
      return date.format("MM/YYYY");
      case "minutes":
      return date.format("DD/MM - HH:mm");
      default:
      return date.format("DD/MM/YYYY HH:mm - ddd");
    }
  
}


function getStartEndDays(selectDate, model) {
  // NOTE: Start = days atrás do dia atual, End = dia atual
  const today = moment(selectDate);
  const start = today.clone().subtract(model === "day" ? 0 : 7, 'days').format('YYYY-MM-DD');
  const end = today.format('YYYY-MM-DD');

  return { start, end };
}


function getStartEndMonth(selectDate) {
  const date = selectDate ? moment(selectDate, 'YYYY-MM-DD') : moment();
  const start = date.clone().startOf('month');
  const end = date.clone().endOf('month');

  return { start, end };
}


function getStatusUc(active) {
  let status;
  switch (active) {
    case 0:
      status = "Inativo";
      break;
    case 1:
      status = "Pendente";
      break;
    case 2:
      status = "Ativo";
      break;
    default:
      status = "Sem status";
  }

  return status;
}

function resetValidateFields (path, validateFields, setValidateFields) {
  const resetValidateFields = validateFields.filter((item) => {  
    return item.path !== path
  });
  setValidateFields(resetValidateFields);
}

function getScreenshotImage(divId) {
  let renderImage = document.getElementById(divId);
  return htmlToImage.toPng(renderImage)
    .then(function (canvas) {
      renderImage.style.display = 'none';
      return canvas;
    });
}

function formatNumber(num) {
  if (num < 1000) return num.toString();

  const units = ["", "k", "M", "B", "T"];
  const order = Math.floor(Math.log10(num) / 3);
  const unit = units[order];
  const scaled = num / Math.pow(10, order * 3);

  return scaled.toFixed(1) + unit;
}

const formatEnergy = (value) => {
  if (value < 1000) return `${value.toFixed(2).replace(".", ",")} kWh`;
  if (value < 1000000)
    return `${(value / 1000).toFixed(2).replace(".", ",")} MWh`;
  if (value < 1000000000)
    return `${(value / 1000000).toFixed(2).replace(".", ",")} GWh`;
  if (value < 1000000000000)
    return `${(value / 1000000000).toFixed(2).replace(".", ",")} TWh`;
  return "";
};

const formatPower = (value) => {
  if (value < 1000) return `${value.toFixed(2).replace(".", ",")} kW`;
  if (value < 1000000)
    return `${(value / 1000).toFixed(2).replace(".", ",")} MW`;
  if (value < 1000000000)
    return `${(value / 1000000).toFixed(2).replace(".", ",")} GW`;
  if (value < 1000000000000)
    return `${(value / 1000000000).toFixed(2).replace(".", ",")} TW`;
  return "";
};

const formatIncome = (value) => {
  if (value.toString().length <= 3)
    return `R$ ${value.toFixed(2).replace(".", ",")}`;
  if (value.toString().length <= 6)
    return `R$ ${(value / 1000).toFixed(2).replace(".", ",")} m`;
  if (value.toString().length <= 9)
    return `R$ ${(value / 1000000).toFixed(2).replace(".", ",")} M`;
  if (value.toString().length <= 12)
    return `R$ ${(value / 1000000000).toFixed(2).replace(".", ",")} B`;
  return "";
};

const formatAvoidedCO2 = (value) => {
  if (value <= 999) return `${value.toFixed(1).replace(".", ",")} kg`;
  return `${(value / 1000).toFixed(1).replace(".", ",")} t`;
};

function getAuthor() {
  let host = window.location.host;
  let author;

  switch (host) {
    case 'alfacomp.igoaltecnologia.com':
      author = 'AlfaComp';
      break;
    case 'nor.igoaltecnologia.com':
      author = 'NOR Engineering';
      break;
    case 'engm.igoaltecnologia.com':
      author = 'ENGM IoT';
      break;
    case 'vetorlog.igoaltecnologia.com':
      author = 'VetorLog';
      break;
    case 'wattson.igoaltecnologia.com':
      author = 'Watts On';
      break;
    case 'monte.igoaltecnologia.com':
      author = 'Montê';
      break;
    default:
      author = "iGoal Tecnologia";
      break;
  }

  return author;
}

function getSupportEmail() {
  let host = window.location.host;
  let link;
  let email;

  switch (host) {
    case 'alfacomp.igoaltecnologia.com':
      email = 'alfacomp@alfacomp.ind.br';
      link = 'mailto:alfacomp@alfacomp.ind.br?Subject=Suporte%20IoT%20Eagle';
      break;
    case 'nor.igoaltecnologia.com':
      email = 'contato@norengineering.com.br';
      link = 'mailto:contato@norengineering.com.br?Subject=Suporte%20NOR%20Monitoring';
      break;
    case 'engm.igoaltecnologia.com':
      email = 'contato@engm.com.br';
      link = 'mailto:contato@engm.com.br?Subject=Suporte%20NOR%20Monitoring';
      break;
    case 'wattson.igoaltecnologia.com':
      email = 'contato@wattson.com.br';
      link = 'mailto:contato@wattson.com.br?Subject=Suporte%20WattsOn';
      break;
    case 'monte.igoaltecnologia.com':
      email = 'suporte1@monte.rs';
      link = 'mailto:suporte1@monte.rs?Subject=Suporte%20Montê';
      break;
    default:
      email = 'suporte@igoaltecnologia.com';
      link = 'mailto:suporte@igoaltecnologia.com?Subject=Suporte%20IoT%20iGoal';
      break;
  }

  return { link, email };
}


module.exports = {
  formatTimestamps,
  getStartEndDays,
  getStartEndMonth,
  getStatusUc,
  resetValidateFields,
  getScreenshotImage,
  formatNumber,
  formatEnergy,
  formatPower,
  formatIncome,
  formatAvoidedCO2,
  getAuthor,
  getSupportEmail,
  formatTimestamp
};