import {
  faCalendarAlt,
  // faClock,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CardHeader, Col, Row } from "reactstrap";

import CarouselCard from "./components/CarouselCard";
import WaterTools from "./components/Tools";
import UCList from "./components/UCList";
import { WaterModuleProvider, useWaterModuleAPI } from "./WaterProvider";
import ModuleHeader from "./components/Header";
import { HeaderCards } from "./components/HeaderCards";

export const WaterModuleWithProvider = ({ projectId, activeTab }) => {
  return (
    <WaterModuleProvider projectId={projectId} activeTab={activeTab}>
      <WaterModule projectId={projectId} />
    </WaterModuleProvider>
  );
};

const options = {
  timeZone: "America/Sao_Paulo",
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

const WaterModule = ({ projectId }) => {
  const { t } = useTranslation();
  const adminUser = localStorage.getItem("userType") === "1";
  const endUser = localStorage.getItem("userType") === "3";

  const { generalData, ucList, loadingUcList, loadingGeneralData } =
    useWaterModuleAPI();

  // const [lastCommStr, setLastCommStr] = useState('-');
  const [selectedUC, setSelectedUC] = useState("general");
  const [objectDataUc, setObjectDataUc] = useState(generalData);

  const handleSelectUc = (uc) => {
    if (!generalData.processedAt) return;

    setSelectedUC(uc);
    setObjectDataUc(
      uc === "general"
        ? generalData
        : generalData?.registers?.filter((data) => data.ucId === uc.id)
    );
  };

  const processedAt = generalData?.processedAt
    ? new Date(generalData?.processedAt * 1000)
    : new Date();
  const timestampInit = generalData?.timestampInit
    ? new Date(generalData?.timestampInit * 1000)
    : new Date();
  const timestampEnd = generalData?.timestampEnd
    ? new Date(generalData?.timestampEnd * 1000)
    : new Date();
    
  useEffect(() => {
    setObjectDataUc(generalData);
  }, [generalData]);

  return (
    <>
      <CardHeader className="d-flex flex-row m-0 p-0">
        <ModuleHeader
          icon={faDatabase}
          title={t("solar.processed")}
          value={processedAt.toLocaleDateString("pt-BR", options) ?? t("none")}
        />
        <ModuleHeader
          icon={faCalendarAlt}
          title={t("water.period")}
          value={`${timestampInit.toLocaleTimeString(
            "pt-BR",
            options
          )} - ${timestampEnd.toLocaleTimeString("pt-BR", options)}`}
        />
      </CardHeader>

      {/*NOTE: Opções */}
      <WaterTools 
      data={generalData} 
      projectId={projectId} 
      adminUser={adminUser} 
      />

      {/*NOTE: Cards de cima */}
      <HeaderCards data={generalData} />

      {/*NOTE: UCs e Gráficos */}
      <Row>

        {/*NOTE: UCs */}
        { !endUser &&
        <Col sm="3" xs="12">
          <UCList
            UCs={ucList}
            selectedUC={selectedUC}
            setSelectedUC={handleSelectUc}
            loading={loadingUcList}
          />
        </Col>
        }

        {/*NOTE: Gráficos */}
        <Col>
          <CarouselCard
            generalDataConsumption={objectDataUc}
            selectedUC={selectedUC}
            loading={loadingGeneralData}
          />
        </Col>
      </Row>
    </>
  );
};
