import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import {
    Form, FormGroup, CustomInput, Label,
  } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faVolumeMute,
    faVolumeUp
  } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

const SoundEnable = () => {
    const [soundOn, setSoundOn] = useState(Number(localStorage.getItem('soundOn') === '1'));
  
    function switchSound() {
      const newState = Number(!soundOn);
      axios.put('enableSound', JSON.stringify({
        soundOn: newState
      }))
        .then(response => {
          setSoundOn(newState);
          localStorage.setItem('soundOn', newState);
          toastr.success('Sucesso', response.data.message);
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
        });
    }
  
    return <Form className="mx-3">
      <FormGroup className="d-flex align-items-center justify-content-start">
        <CustomInput // switch de habilitar/desabilitar som
          bsSize="sm"
          type="switch"
          id={'soundEnable'}
          defaultChecked={(localStorage.getItem('soundOn') === '1')}
          onChange={switchSound}
        />
        <Label className="flex-grow-1">Notificações com som</Label>
        <FontAwesomeIcon
          icon={(soundOn)
            ? faVolumeUp
            : faVolumeMute
          }
        />
      </FormGroup>
    </Form>
}

export default SoundEnable;