import React from "react";
import { Row, Col } from "reactstrap";

import DataCard from "../DataCard";
import { useTranslation } from "react-i18next";
export const MeterInfo = ({ meterInfo }) => {
  const { t } = useTranslation();

  return (
    <div className="mx-5 my-3">
      <Row>
        <Col>
          <DataCard
            title={t("water.initialRead")}
            value={meterInfo?.leituraInicial ?? t("none")}
            unit="m³"
            dark
          />
        </Col>
        <Col>
          <DataCard
            title={t("water.previousValue")}
            value={
              meterInfo?.leituraAnterior[0]?.consumption * meterInfo.gain +
                meterInfo?.leituraInicial ?? t("none")
            }
            unit="m³"
            dark
            timestamp={meterInfo?.leituraAnterior[0].timestamp}
          />
        </Col>
        <Col>
          <DataCard
            title={t("water.currentValue")}
            value={
              (meterInfo?.leituraAtual?.consumption || meterInfo?.leituraAnterior[0]?.consumption) * meterInfo.gain +
                meterInfo?.leituraInicial ?? t("none")
            }
            unit="m³"
            dark
            timestamp={meterInfo?.leituraAtual.timestamp || meterInfo?.leituraAnterior[0]?.timestamp}
          />
        </Col>
      </Row>
    </div>
  );
};
