const ptBrDashboardTranslations = {
  dashboards: {
    anyDashboards: "Você não tem nenhuma dashboard.",
    myDashboards: "Meus Dashboards",
    homePage: "Página Inicial",
    moreDash: "Você pode adicionar novas dashboards, de acordo com a sua necessidade de visualização.",
    name: "Nome",
    description: "Descrição",
    decimalNumber: "Casas Decimais",
    actions: "Ações",
    editDashboards: "Editar Dashboards",
    deleteDash: "Deletar Dashboard",
    textDeleteDash: "Atenção! Essa ação é irreversível. Caso deseje continuar, por favor, digite 'DELETE' no campo abaixo:",
    delete: "Deletar",
    newDash: "Nova Dashboard",
    save: "Salvar",
    myCards: "Meus Cards",
    addCard: "Adicionar Cards",
    notCard: "Você não tem nenhum card.",
    editCard: "Editar Card",
    deleteCard: "Deletar Card",
    deleteMessageCard: "Atenção! Essa ação é irreversível. Caso deseje continuar, por favor, digite 'DELETE' no campo abaixo:",
    copyDashboard: "Copiar",
    copyDashboardModal: "Selecione usuários para copiar",
    companyName: "Empresa",
    edit: "Editar",
    cardPanel: "Painel de Cards",
    cardSelection: "Seleção de Cards",
    addCardType: "Você pode adicionar novos cards, de acordo com a sua necessidade de visualização.",

    barChart: "Gráfico de Barra",
    lineChart: "Gráfico de Linha",
    calendar: "Calendário",
    statistics: "Estatísticas",
    piechart2: "Gráfico de pizza",
    streetview: "StreetView",
    statisticsState: "Estado",
    customCard: "Personalizado",
    formulaCard: "Card de fórmula",
    thermometerCard: "Temperatura",
    batteryCard: "Bateria",
    gaugeCard: "Gauge",
    waterCard: "Água",
    mqttControl: "Controle MQTT",
    exec: "Executar",
    topic: "Tópico",
    commandReceived: "Camando Recebido",
    commandSuccess: "O comando de gravação foi bem-sucedido!",
    commandFailed: "Comando falhou! Erro nº 2",
    commandPending: "Comando enviado, porém não recebemos o retorno do comando",
    cardOptions: "Opções de Card",
    projects: "Projeto",
    devices: "Dispositivo",
    variables: "Variável",
    refreshTime: "Atualização",
    typeVariable: "Tipo de variável",
    integral: "Integral",
    differential: "Diferencial",
    period: "Período",
    cancel: "Cancelar",
    pleaseProject: "Por favor, selecione um projeto",
    noProject: "Não há projetos cadastrados para este usuário.",
    pleaseDevice: "Por favor, selecione um dispositivo",
    pleaseVariable: "Por favor, selecione uma variável",
    pleaseTypeVariable: "Por favor, selecione um tipo de valor para variável",
    pleasePeriod: "Por favor, selecione um intervalo de tempo",
    pleaseRefresh: "Por favor, selecione um periodo de atualização",
    seconds: "segundos",
    minutes: "minutos",

    state: "Estado",
    message: "Exibição",
    msgPlaceholder: "Digite o que será exibido no card",
    color: "Cor",
    image: "Imagem",
    imageText: "Para melhor resolução, envie uma imagem quadrada no formato .jpg",
    limitHead: "Limite entre Estados",
    limitAnd: "e",
    value: "Valor",
    includes: "Incluso em",
    newState: "Novo Estado",

    noDataCharts: "Não há dados para o período selecionado",
    offlineCharts: "O dispositivo está offline",
    noDataStats: "Não há dados",
    offlineStats: "Dispositivo offline",
    noComm: "Nunca comunicou",
    on: "LIGADO",
    off: "DESLIGADO",
    refresh: "Atualizar",

    // NOTE:DASHBOARDS TOOLTIP
    exportDashboardTooltip: "Exportar para URL",
    copyDashobardTooltip: "Copiar dashboard",
    editDashobardTooltip: "Editar dashboard",
    deleteDashobardTooltip: "Deletar dashboard",

    // NOTE:DASHBOARD CONFIRM TOASTR
    dashboardConfirmToastr: 'Alguns usuários ainda não têm acesso aos dados de alguns cards do dashboard selecionado. Deseja adicioná-los agora?',

    // NOTE:DASHBOARD EXPORT MODAL
    modalHeaderUrl: "Exportar para URL",
    urlName: "Nome da URL:",
    explanation: "A url não poderá ter símbolos, acentos, espaços e letras maiúsculas",
    alert: "Atenção!",
    deleteUrl: "Excluir URL",
    editUrl: "Editar URL",
    successToastTitle: "Sucesso",
    successToastMessage: "URL alterada com sucesso",
    warningToastTitle: "Falha",
    warningToastMessage: "Houve um erro ao editar URL",
    warningDeleteToastMessage: "Erro ao excluir URL",
    successDeleteToastTitle: "Sucesso",
    successDeleteToastMessage: "URL excluída com sucesso",
    symbolsWarningToastTitle: "Atenção",
    symbolsWarningToastMessage: "Você está usando simbolos ou letras maiúsculas",
    smallWarningToastMessage: "URL muito pequena. Deve haver pelo menos 4 caracteres.",
    warningDeleteToastUrl: "ATENÇÃO! Esta ação não pode ser desfeita. Tem certeza que deseja continuar?",
  },
}

export default ptBrDashboardTranslations;