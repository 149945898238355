import React, {useState} from "react";
import { UncontrolledTooltip } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBellSlash } from "@fortawesome/free-solid-svg-icons";
import { ModalOffAlarm } from "../../components/ModalOffAlarm"

export const AlarmTurnOffIcon = ({ alarm, tableReload }) => {

    const { t } = useTranslation();
  
    const [openOffAlarm, setOpenOffAlarm] = useState(false);
    // ativa/desativa modal
    function toggleOffAlarm() {
      setOpenOffAlarm(!openOffAlarm);
    }
  
    return (!alarm.dateFinished) // verifica se alarme ainda está ativo
      // desativar alarme
      ? <div
          className="text-center"
          id={"alarm"+alarm.id}
          onClick={()=>setOpenOffAlarm(true)}
        >
          <FontAwesomeIcon
            icon={faBell}
            fixedWidth
            className="align-middle cursor-pointer"
          />
          {(openOffAlarm) // modal de desligar alarme
            ? <ModalOffAlarm
                open={openOffAlarm}
                toggle={toggleOffAlarm}
                tableReload={tableReload}
                alarm={alarm}
              />
            : null
          }
          <UncontrolledTooltip // tooltip
            placement="top"
            target={"alarm"+alarm.id}
          >
            {t('alarms.turnOffAlarm')}
          </UncontrolledTooltip>
        </div>
      // alarme já está desligado
      : <div className="text-center" >
          <div id={"alarm"+alarm.id} >
            <FontAwesomeIcon
              icon={faBellSlash}
              fixedWidth
              className="align-middle"
              color="gray"
            />
            <UncontrolledTooltip // tooltip
              placement="top"
              target={"alarm"+alarm.id}
            >
              {t('alarms.alarmTurnedOff')}
            </UncontrolledTooltip>
          </div>
        </div>
};

