import React, { useState, useEffect } from "react";
import {
  Button,
  Form, FormGroup, Input, Label,
  InputGroup, InputGroupAddon,
  Modal, ModalHeader, ModalBody, ModalFooter, Spinner,
  Row, Col, Card, CardHeader, CardBody,
  UncontrolledTooltip, Tooltip
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPen,
  faPlus,
  faSearch,
  faSort,
  faSortUp,
  faSortDown,
  faTrash, 
  faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";
// import { Plus } from "react-feather";
import Resizer from "react-image-file-resizer";
import axios from 'axios';
import { toastr } from "react-redux-toastr";
import { useTranslation } from 'react-i18next';
import BootstrapTable from "react-bootstrap-table-next";
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';


import './css/modal-img-crop.css'


/** MODAL DE EDIÇÃO DE REGISTRO DE PROJETO */
const ModalEditProject = ({ open, toggle, proj, setLoadProjects }) => {
  const { t } = useTranslation();
  const [avatarPreview, setAvatarPreview] = useState(proj.image);

  const [fileList, setFileList] = useState((avatarPreview !== "null")
    ? [{
      uid: '1',
      name: 'image.JPEG',
      status: 'done',
      url: avatarPreview
    }]
    : []
  );
  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  /* Função redução da imagem e conversão para base64 */
  function resizeFile(file) {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        128,
        128,
        "JPEG",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  };



  const [isLoading, setLoading] = useState(true);
  const [comps, setComps] = useState([]);
  const [formComp, setFormComp] = useState(proj.companyId);
  const [formName, setFormName] = useState(proj.name);
  const [formDesc, setFormDesc] = useState(proj.description);
  const [formAddress, setFormAddress] = useState(proj.address);
  const [formNeighborhood, setFormNeighborhood] = useState(proj.neighborhood);
  const [formCity, setFormCity] = useState(proj.city);
  const [formState, setFormState] = useState(proj.state);
  const [formZipCode, setFormZipCode] = useState(proj.zipCode);

  const [validateFields, setValidateFields] = useState([{
    path: null, 
    msg: null
  }]);

  function resetValidateFields (path) {
    const resetValidateFields = validateFields.filter((item) => {  
      return item.path !== path
    })
    setValidateFields(resetValidateFields);
  }

  /* Função upload de imagem */
  async function handleChange(e) {

    try {
      // console.log(event);
      const fileList = e.file.originFileObj; // busca imagem
      // console.log(fileList);
      const image = await resizeFile(fileList); // reduz e converte para base64
      console.log(image);
      // console.log('image', image);
      setAvatarPreview(image); // atualiza preview da imagem
      setFileList([e.file]);
      //const file = e.target.files[0];
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    axios.get('/companySearch')
      .then(res => {
        setComps(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        // t() da problema com o useEffect
        toastr.error('Erro!', "Erro ao buscar empresas.");
      });
  }, []);

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      style={{ zIndex: 0 }}
    >
      <Form
        onSubmit={e => {
          e.preventDefault();
          /* Salvar dados do formulário no formato JSON */
          const jsonData = JSON.stringify({
            projId: proj.id,
            currCompId: proj.companyId,
            companyId: formComp,
            name: formName,
            description: formDesc,
            address: formAddress,
            neighborhood: formNeighborhood,
            city: formCity,
            state: formState,
            zipCode: formZipCode,
            image: (avatarPreview) ? avatarPreview : "",
          });

          /*** Envio de dados ***/
          axios.put('/v2/project/updateProject', jsonData)
            .then(response => {
              toastr.success(response.data.message);
              setLoadProjects(true); // Atualiza página
              toggle(); // Fecha o modal
            })
            .catch(error => {
              if (error.response) {
                if (error.response.status === 400) {
                  setValidateFields(error.response.data.errors);
                }
              }
            });
        }}
      >
        <ModalHeader toggle={toggle}>
          {t('projTable.editProject')} {proj.name}
        </ModalHeader>

        <ModalBody className="text-left mx-2 p-4">

          <Card>
            <CardHeader  
              style={{ fontSize: '20px'}} 
              className="font-weight-bold"
            >
              {t("projTable.detailsProject")}
            </CardHeader>

              <CardBody>
                {/*** CAMPO DE EMPRESA ***/}
            {(isLoading || !comps.length)
              ? <div className="text-center">
                <Spinner color='primary' />
              </div>
              : <FormGroup>
                <Label>{t('projTable.company')}</Label>
                <Input
                  type="select"
                  name="companyId"
                  id="companyId"
                  value={formComp}
                  onChange={e => setFormComp(e.target.value)}
                  onFocus={(e) => {
                    resetValidateFields("companyId");
                  }}
                >
                {comps.map((item, i) => {
                  return <option
                    value={item.id}
                    key={i}
                  >
                    {item.name}
                  </option>
                })}
                {validateFields.map((item, index) => {
                  return item.path === "companyId" ? (
                    <small key={index} className="text-danger d-block">
                      {item.msg}
                    </small>  
                  ) : null;
                })}
                </Input>
              </FormGroup>
            }

            <Row>
              {/* Dados de projeto */}
              <Col md={8}>
                {/*** CAMPO DE NOME ***/}
                <FormGroup>
                  <Label>{t('projTable.name')}</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    value={formName}
                    onChange={e => setFormName(e.target.value)}
                    onFocus={(e) => {
                      resetValidateFields("name");
                    }}
                  />
                {validateFields.map((item, index) => {
                  return item.path === "name" ? (
                    <small key={index} className="text-danger d-block">
                      {item.msg}
                    </small>  
                  ) : null;
                })}
                </FormGroup>

                {/*** CAMPO DE DESCRIÇÃO ***/}
                <FormGroup>
                  <Label>{t('projTable.description')}</Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    value={formDesc}
                    onChange={e => setFormDesc(e.target.value)}
                    rows="3"
                    onFocus={(e) => {
                      resetValidateFields("description");
                    }}
                  />
                  {validateFields.map((item, index) => {
                    return item.path === "description" ? (
                      <small key={index} className="text-danger d-block">
                        {item.msg}
                      </small>  
                    ) : null;
                  })}
                </FormGroup>
              </Col>
            </Row>
            {/* Imagem */}


            <Label>{t('projTable.image')}</Label>
            <Row>
              <Col>
                <ImgCrop rotate modalProps={{
                  zIndex: 10000
                }}>
                  <Upload
                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    fileList={fileList}
                    onChange={e => {
                      handleChange(e);
                    }}

                    onPreview={onPreview}
                    onRemove={() => setAvatarPreview([])}
                  >
                    {fileList.length < 2 && '+ Upload'}
                  </Upload>
                </ImgCrop>
              </Col>
              <Col>
                <div>
                  <small>
                    {t('settings.avatarText')}
                  </small>
                </div>
              </Col>

            </Row>

              </CardBody>
          
          </Card>

          <Card>

            <CardHeader  
                style={{ fontSize: '20px'}} 
                className="font-weight-bold"
              >
              {t("projTable.addressProject")}
            </CardHeader>

            <CardBody>
              {/*** ENDEREÇO ***/}
            <FormGroup className="mt-4">
              <Label>{t("projTable.address")}</Label>
                <Input
                  type="text"
                  name="address"
                  id="address"
                  value={formAddress}
                  maxLength={150}
                  onChange={(e) => setFormAddress(e.target.value)}
                  onFocus={(e) => {
                    resetValidateFields("address");
                  }}
                />
                {validateFields.map((item, index) => {
                  return item.path === "address" ? (
                    <small key={index} className="text-danger d-block">
                      {item.msg}
                    </small>  
                  ) : null;
                })}
            </FormGroup>

          <Row>
            <Col md={5}>
              {/*** BAIRRO ***/}
              <FormGroup>
                <Label>{t("projTable.neighborhood")}</Label>
                  <Input
                    type="text"
                    name="neighborhood"
                    id="neighborhood"
                    value={formNeighborhood}
                    maxLength={100}
                    onChange={(e) => setFormNeighborhood(e.target.value)}
                    onFocus={(e) => {
                      resetValidateFields("neighborhood");
                    }}
                  />
                  {validateFields.map((item, index) => {
                    return item.path === "neighborhood" ? (
                      <small key={index} className="text-danger d-block">
                        {item.msg}
                      </small>  
                    ) : null;
                  })}
              </FormGroup>
            </Col>

            <Col md={5}>
              {/*** CIDADE ***/}
              <FormGroup>
                <Label>{t("projTable.city")}</Label>
                  <Input
                    type="text"
                    name="city"
                    id="city"
                    value={formCity}
                    maxLength={100}
                    onChange={(e) => setFormCity(e.target.value)}
                    onFocus={(e) => {
                      resetValidateFields("city");
                    }}
                  />
                  {validateFields.map((item, index) => {
                    return item.path === "city" ? (
                      <small key={index} className="text-danger d-block">
                        {item.msg}
                      </small>  
                    ) : null;
                  })}
              </FormGroup>
            </Col>

            <Col md={2}>
              {/*** ESTADO ***/}
              <FormGroup>
                <Label>{t("projTable.state")}</Label>
                  <Input
                    type="text"
                    name="state"
                    id="state"
                    value={formState}
                    maxLength={2}
                    onChange={(e) => setFormState(e.target.value.toUpperCase())}
                    onFocus={(e) => {
                      resetValidateFields("state");
                    }}
                  />
                {validateFields.map((item, index) => {
                  return item.path === "state" ? (
                    <small key={index} className="text-danger d-block">
                      {item.msg}
                    </small>  
                  ) : null;
                })}
              </FormGroup>
            </Col>

          </Row>

          <Row>
            <Col md={6}>
              {/*** CEP ***/}
              <FormGroup>
                <Label>{t("projTable.zipCode")}</Label>
                  <Input
                    type="text"
                    name="zipCode"
                    id="zipCode"
                    value={formZipCode}
                    maxLength={15}
                    onChange={(e) => setFormZipCode(e.target.value)}
                    onFocus={(e) => {
                      resetValidateFields("zipCode");
                    }}
                  />
                  {validateFields.map((item, index) => {
                    return item.path === "zipCode" ? (
                      <small key={index} className="text-danger d-block">
                        {item.msg}
                      </small>  
                    ) : null;
                  })}
              </FormGroup>
            </Col>

          </Row>

            </CardBody>

          </Card>

        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
            * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button
            name="btnCancel"
            color='primary'
            className="mr-1 mb-1"
            onClick={toggle}
            outline
          >
            {t('projTable.cancel')}
          </Button>

          {/*** BOTÃO DE ENVIAR DADOS E FECHAR O MODAL ***/}
          <Button
            name="btnClose"
            type="submit"
            color="primary"
          >
            {t('projTable.saveChanges')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

/** MODAL DE EDIÇÃO DE REGISTRO DE PERIFÉRICO */
const ModalEditPP = ({toggle, dvc, setLoadDevices, deviceList}) => {
  const { t } = useTranslation();

  const [manufacturers, setManufacturers] = useState([]); // lista de fabricantes
  const [formManuf, setFormManuf] = useState(dvc.manufacturer); // fabricante selecionado
  const [models, setModels] = useState([]); // lista de modelos
  const [formModel, setFormModel] = useState(dvc.model); // modelo selecionado
  const [formDesc, setFormDesc] = useState(dvc.description); // descricao
  const [invalidManuf, setInvalidManuf] = useState(false); // estado de fabricante invalido
  const [invalidModel, setInvalidModel] = useState(false); // estado de modelo invalido
  const [invalidDesc, setInvalidDesc] = useState(false); // estado de descrição invalido
  const [formInterval, setFormInterval] = useState(dvc.commInterval); // tempo de atualização
  const [formLatitude, setFormLatitude] = useState(dvc.latitude); // latitude
  const [formLongitude, setFormLongitude] = useState(dvc.longitude); // longitude
  const [invalidInterval, setInvalidInterval] = useState(false); // estado de tempo invalido
  const [intUnit, setIntUnit] = useState(1); // unidade de tempo de indicação (s, min, h)
  const [formGW, setFormGW] = useState(dvc.gatewayId); // gateway do dispositivo
  // const [invalidGW, setInvalidGW] = useState(false); // estado de GW invalido

  const [tooltipLatitudeOpen, setTooltipLatitudeOpen] = useState(false);
  const [tooltipLongitudeOpen, setTooltipLongitudeOpen] = useState(false);
  const toggleTooltipLatitude = () => setTooltipLatitudeOpen(!tooltipLatitudeOpen);
  const toggleTooltipLongitude = () => setTooltipLongitudeOpen(!tooltipLongitudeOpen);

  const [loading, setLoading] = useState(false); // estado de carregamento envio de dados para api

  const gwList = deviceList.filter(item => item.category === 'gw');

  // busca lista de fabricantes
  useEffect(() => {
    axios.get('deviceManufacturerSearch/')
      .then(res => {
        setManufacturers(res.data);
      })
      .catch(err => {
        console.log(err);
        // t() da problema com o useEffect
        toastr.error('Erro!', "Erro ao buscar fabricantes.");
      });
  }, []);

  // busca lista de modelos do fabricante selecionado
  useEffect(() => {
    if (formManuf) {
      axios.get('/deviceModelSearch', {
        params: {
          name: formManuf
        }
      })
      .then(res => {
        // console.log(res, "RES");
        setModels(res.data);
      })
      .catch(err => {
        console.log(err);
        // t() da problema com o useEffect
        toastr.warning('Erro!', "Erro ao buscar modelos.");
      });
    }
  }, [formManuf]);

  function onSubmitForm() {
    if (!formManuf || !formModel || !formDesc || !formInterval) {
      setInvalidManuf((!formManuf));
      setInvalidModel((!formModel));
      setInvalidDesc((!formDesc));
      setInvalidInterval(!formInterval);
      toastr.warning(`${t('projTable.att')}!`, `${t('projTable.invalidText')}.`)
    }

    else {
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        deviceId: dvc.id,
        gatewayId: formGW,
        manufacturer: formManuf,
        model: formModel,
        description: formDesc,
        commInterval: formInterval * intUnit,
        latitude: formLatitude,
        longitude: formLongitude
      };

      setLoading(true);
      /*** Envio de dados ***/
      axios.put('editDevice/', JSON.stringify(jsonData))
      .then(res => {
        setLoading(false);
        toastr.success(res.data.messageHead, res.data.messageBody);
        setLoadDevices(true); // Atualiza tabela
        toggle(); // fechar o modal
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        console.log(err.response);

        if (err.response) {
          /* Notificação de erro de envio */
          toastr.warning(
            err.response.data.messageHead,
            err.response.data.messageBody
          );
        }
      });
    }
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        onSubmitForm();
      }}
    >
      <ModalBody className="text-left mx-6">
        <h5 className="text-center">Editar Periférico</h5>

        <hr />

        {/*** CAMPO DE FABRICANTE ***/}
        <FormGroup>
          <Label>{t('projTable.manufacturer')}</Label>
          <Input
            type="select"
            name="manufacturer"
            id="manufacturer"
            value={formManuf}
            onChange={e => {
              setFormManuf(e.target.value);
              setFormModel('');
              setInvalidManuf(false);
              setInvalidModel(false);
            }}
            // disabled={(!manufacturers.length)}
            invalid={invalidManuf}
            // disabled={!manufacturers.length || loading}
            disabled
          >
            <option value="" disabled>
              {t('projTable.select')}
            </option>
            {manufacturers.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
            <option value="myDrivers">
              Meus Drivers
            </option>

          </Input>
        </FormGroup>

        {/* CAMPO DE MODELO */}
        <FormGroup>
          <Label>{t('projTable.model')}</Label>
          <Input
            type="select"
            name="model"
            id="model"
            value={formModel}
            onChange={e => {
              setFormModel(e.target.value);
              setInvalidModel(false);
            }}
            // disabled={(!models.length)}
            invalid={invalidModel}
            // disabled={!models.length || loading}
            disabled
          >
            <option value="" disabled>
              {t('projTable.select')}
            </option>
            {models.map((item, i) => (
              <option value={item.model} key={i}>
                {item.model}
              </option>
            ))}
          </Input>
        </FormGroup>

        {/* CAMPO DE DESCRIÇÃO */}
        <FormGroup>
          <Label>{t('projTable.reference')}</Label>
          <Input
            type="text"
            name="description"
            id="description"
            value={formDesc}
            onChange={e => {
              setFormDesc(e.target.value);
              setInvalidDesc(false);
            }}
            invalid={invalidDesc}
            disabled={loading}
          />
        </FormGroup>

        {/* CAMPO DE GATEWAY DO DISPOSITIVO */}
        {(gwList.length)
          ? <FormGroup>
              <Label>{t('projTable.gatewayId')}</Label>
              <Input
                type="select"
                name="deviceGW"
                id="deviceGW"
                value={formGW}
                onChange={e => {
                  setFormGW(e.target.value);
                  // setInvalidGW(false);
                }}
                // invalid={invalidGW}
                // disabled={!gwList.length}
                disabled={loading}
              >
                <option value="" disabled>
                  {t('projTable.select')}
                </option>
                {gwList.map((item, i) => (
                  <option value={item.id} key={i}>
                    {item.description}
                  </option>
                ))}
              </Input>
            </FormGroup>
          : null
        }

        {/*** CAMPO DE TEMPO DE ATUALIZAÇÃO ***/}
        <FormGroup>
          <Label>{t('projTable.commInterval')}</Label>
          <Row>
            <Col md={4}>
              <Input
                type="number"
                name="commInterval"
                id="commInterval"
                value={formInterval}
                onChange={e => {
                  setFormInterval(e.target.value);
                  setInvalidInterval(false);
                }}
                invalid={invalidInterval}
                disabled={loading}
              />
            </Col>

            <Col md={4}>
              <Input
                className="pt-0"
                type="select"
                name="intUnit"
                id="intUnit"
                value={intUnit}
                onChange={e => setIntUnit(e.target.value)}
                disabled={loading}
              >
                <option value={1}>
                  {t('projTable.seconds')}
                </option>
                <option value={60}>
                  {t('projTable.minutes')}
                </option>
                <option value={3600}>
                  {t('projTable.hours')}
                </option>
              </Input>
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
              {/* LATITUDE */}
              <Col md={6}>
                <Label>
                  {t("projTable.latitude")}
                  <FontAwesomeIcon id="latitudeHelpIcon" fixedWidth icon={faQuestionCircle} />
                  <Tooltip isOpen={tooltipLatitudeOpen} target="latitudeHelpIcon" toggle={toggleTooltipLatitude}>
                    {t("projTable.tooltipLatitude")}
                  </Tooltip>
                </Label>
                <Input
                  type="text"
                  name="latitude"
                  value={formLatitude}
                  onChange={(e) => {
                    setFormLatitude(e.target.value);
                  }}
                  disabled={loading}
                />
              </Col>
              
              {/* LONGITUDE */}
              <Col md={6}>
                <Label>
                  {t("projTable.longitude")}
                  <FontAwesomeIcon id="longitudeHelpIcon" fixedWidth icon={faQuestionCircle} />
                  <Tooltip isOpen={tooltipLongitudeOpen} target="longitudeHelpIcon" toggle={toggleTooltipLongitude}>
                    {t("projTable.tooltipLongitude")}
                  </Tooltip>
                </Label>
                <Input
                  type="text"
                  name="longitude"
                  value={formLongitude}
                  onChange={(e) => {
                    setFormLongitude(e.target.value);
                  }}
                  disabled={loading}
                />
              </Col>
          </Row>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        {(loading)
          ? <Spinner color="primary" className="mr-3" />
          : <>
              {/** BOTÃO DE CANCELAR */}
              <Button
                type="button"
                color='primary'
                className="mr-1"
                onClick={toggle}
                outline
              >
                {t('projTable.cancel')}
              </Button>

              {/** BOTÃO DE ENVIAR DADOS */}
              <Button
                name="btnClose"
                type="submit"
                color="primary"
              >
                {t('projTable.saveChanges')}
              </Button>
            </>
        }
      </ModalFooter>
    </Form>
  );
};

/** MODAL DE EDIÇÃO DE REGISTRO DE GATEWAY */
const ModalEditGW = ({toggle, dvc, setLoadDevices}) => {
  const { t } = useTranslation();

  const [manufacturers, setManufacturers] = useState([]); // lista de fabricantes
  const [models, setModels] = useState([]); // lista de modelos
  
  const [formManuf, setFormManuf] = useState(dvc.manufacturer); // fabricante selecionado
  const [formModel, setFormModel] = useState(dvc.model); // modelo selecionado
  const [formSN, setFormSN] = useState(dvc.serialNumber); // serial number
  const [formDesc, setFormDesc] = useState(dvc.description); // descrição
  const [formInterval, setFormInterval] = useState(dvc.commInterval); // tempo de atualização
  const [formLatitude, setFormLatitude] = useState(dvc.latitude); // latitude
  const [formLongitude, setFormLongitude] = useState(dvc.longitude); // longitude
  const [intUnit, setIntUnit] = useState(1); // unidade de tempo de indicação (s, min, h)

  const [tooltipLatitudeOpen, setTooltipLatitudeOpen] = useState(false);
  const [tooltipLongitudeOpen, setTooltipLongitudeOpen] = useState(false);
  const toggleTooltipLatitude = () => setTooltipLatitudeOpen(!tooltipLatitudeOpen);
  const toggleTooltipLongitude = () => setTooltipLongitudeOpen(!tooltipLongitudeOpen);

  const [invalidManuf, setInvalidManuf] = useState(false); // estado de fabricante invalido
  const [invalidModel, setInvalidModel] = useState(false); // estado de modelo invalido
  const [invalidSN, setInvalidSN] = useState(false); // estado de serial number invalido
  const [invalidDesc, setInvalidDesc] = useState(false); // estado de descrição invalido
  const [invalidInterval, setInvalidInterval] = useState(false); // estado de tempo invalido

  const [loading, setLoading] = useState(false); // estado de carregamento envio de dados para api

  // busca lista de fabricantes de gateway
  useEffect(() => {
    axios.get('gwManufacturerSearch/')
    .then(res => {
      setManufacturers(res.data);
    })
    .catch(err => {
      console.log(err);
      // t() da problema com o useEffect
      toastr.error('Erro!', "Erro ao buscar fabricantes.");
    });
  }, []);

  // busca lista de modelos do fabricante selecionado
  useEffect(() => {
    if (formManuf) {
      axios.get('/gwModelSearch', {
        params: {
          name: formManuf
        }
      })
        .then(res => {
          setModels(res.data);
        })
        .catch(err => {
          console.log(err);
          // t() da problema com o useEffect
          toastr.warning('Erro!', "Erro ao buscar modelos.");
        });
    }
  }, [formManuf]);

  function onSubmitForm() {
    if (!formManuf || !formModel || !formSN || !formDesc || !formInterval) {
      setInvalidManuf((!formManuf));
      setInvalidModel((!formModel));
      setInvalidSN((!formSN));
      setInvalidDesc((!formDesc));
      setInvalidInterval(!formInterval);
      toastr.warning(`${t('projTable.att')}!`, `${t('projTable.invalidText')}.`)
    }

    else {
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        deviceId: dvc.id,
        manufacturer: formManuf,
        model: formModel,
        serialNumber: formSN,
        description: formDesc,
        commInterval: formInterval * intUnit,
        latitude: formLatitude,
        longitude: formLongitude
      };

      setLoading(true);
      /*** Envio de dados ***/
      axios.put('editGateway/', JSON.stringify(jsonData))
      .then(res => {
        setLoading(false);
        toastr.success(res.data.messageHead, res.data.messageBody);
        setLoadDevices(true); // Atualiza tabela
        toggle(); // ...fechar o modal
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        console.log(err.response);

        if (err.response) {
          /* Notificação de erro de envio */
          toastr.warning(
            err.response.data.messageHead,
            err.response.data.messageBody
          );
        }
      });
    }
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();

        onSubmitForm();
      }}
    >
      <ModalBody className="text-left mx-6">
        <h5 className="text-center">Editar Gateway</h5>

        <hr />

          {/*** CAMPO DE FABRICANTE ***/}
          <FormGroup>
            <Label>{t('projTable.manufacturer')}</Label>
            <Input
              type="select"
              name="manufacturer"
              id="manufacturer"
              value={formManuf}
              onChange={e => {
                setFormManuf(e.target.value);
                setFormModel('');
                setInvalidManuf(false);
                setInvalidModel(false);
              }}
              invalid={invalidManuf}
              // disabled={!manufacturers.length || loading}
              disabled
            >
              <option value="" disabled>
                {t('projTable.select')}
              </option>
              {manufacturers.map((item, i) => (
                <option value={item} key={i}>
                  {item}
                </option>
              ))}
              <option value="myDrivers">
                Meus Drivers
              </option>
            </Input>
          </FormGroup>

        {/* CAMPO DE MODELO */}
        <FormGroup>
          <Label>{t('projTable.model')}</Label>
          <Input
            type="select"
            name="model"
            id="model"
            // disabled={(!models.length)}
            value={formModel}
            onChange={e => {
              setFormModel(e.target.value)
              setInvalidModel(false);
            }}
            invalid={invalidModel}
            // disabled={!models.length || loading}
            disabled
          >
            <option value="" disabled>
              {t('projTable.select')}
            </option>
            {models.map((item, i) => (
              <option value={item.model} key={i}>
                {item.model}
              </option>
            ))}
          </Input>
        </FormGroup>

        {/* CAMPO DE SERIAL NUMBER */}
        <FormGroup>
          <Label>{t('projTable.serialNumber')}</Label>
          <Input
            type="text"
            name="serialNumber"
            id="serialNumber"
            value={formSN}
            onChange={e => {
              setFormSN(e.target.value);
              setInvalidSN(false);
            }}
            invalid={invalidSN}
            // disabled={loading}
            disabled
          />
        </FormGroup>

        {/* CAMPO DE DESCRIÇÃO/REFERÊNCIA */}
        <FormGroup>
          <Label>{t('projTable.reference')}</Label>
          <Input
            type="text"
            name="description"
            id="description"
            value={formDesc}
            onChange={e => {
              setFormDesc(e.target.value);
              setInvalidDesc(false);
            }}
            invalid={invalidDesc}
            disabled={loading}
          />
        </FormGroup>

        {/* CAMPO DE TEMPO DE ATUALIZAÇÃO */}
        <FormGroup>
          <Label>{t('projTable.commInterval')}</Label>
          <Row>
            <Col md={4}>
              <Input
                type="number"
                name="commInterval"
                id="commInterval"
                value={formInterval}
                onChange={e => {
                  setFormInterval(e.target.value);
                  setInvalidInterval(false);
                }}
                invalid={invalidInterval}
                disabled={loading}
              />
            </Col>

            <Col md={4}>
              <Input
                className="pt-0"
                type="select"
                name="intUnit"
                id="intUnit"
                value={intUnit}
                onChange={e => setIntUnit(e.target.value)}
                disabled={loading}
              >
                <option value={1}>
                  {t('projTable.seconds')}
                </option>
                <option value={60}>
                  {t('projTable.minutes')}
                </option>
                <option value={3600}>
                  {t('projTable.hours')}
                </option>
              </Input>
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
              {/* LATITUDE */}
              <Col md={6}>
                <Label>
                  {t("projTable.latitude")}
                  <FontAwesomeIcon id="latitudeHelpIcon" fixedWidth icon={faQuestionCircle} />
                  <Tooltip isOpen={tooltipLatitudeOpen} target="latitudeHelpIcon" toggle={toggleTooltipLatitude}>
                    {t("projTable.tooltipLatitude")}
                  </Tooltip>
                </Label>
                <Input
                  type="text"
                  name="latitude"
                  value={formLatitude}
                  onChange={(e) => {
                    setFormLatitude(e.target.value);
                  }}
                  disabled={loading}
                />
              </Col>
              
              {/* LONGITUDE */}
              <Col md={6}>
                <Label>
                  {t("projTable.longitude")}
                  <FontAwesomeIcon id="longitudeHelpIcon" fixedWidth icon={faQuestionCircle} />
                  <Tooltip isOpen={tooltipLongitudeOpen} target="longitudeHelpIcon" toggle={toggleTooltipLongitude}>
                    {t("projTable.tooltipLongitude")}
                  </Tooltip>
                </Label>
                <Input
                  type="text"
                  name="longitude"
                  value={formLongitude}
                  onChange={(e) => {
                    setFormLongitude(e.target.value);
                  }}
                  disabled={loading}
                />
              </Col>
          </Row>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
      {(loading)
        ? <Spinner color="primary" className="mr-3" />
        : <>
            {/** BOTÃO DE CANCELAR */}
            <Button
              type="button"
              color='primary'
              className="mr-1"
              onClick={toggle}
              outline
            >
              {t('projTable.cancel')}
            </Button>

            {/** BOTÃO DE ENVIAR DADOS */}
            <Button
              name="btnClose"
              type="submit"
              color="primary"
            >
              {t('projTable.saveChanges')}
            </Button>
          </>
      }
      </ModalFooter>
    </Form>
  );
};

/****** MODAL DE NOVO STANDALONE ******/
const ModalEditSD = ({toggle, dvc, setLoadDevices}) => {
  const { t } = useTranslation();

  const [manufacturers, setManufacturers] = useState([]); // lista de fabricantes
  const [models, setModels] = useState([]); // lista de modelos
  const [acList, setACList] = useState([]); // lista de aparelhos de a/c cadastrados

  const [formManuf, setFormManuf] = useState(dvc.manufacturer); // fabricante selecionado
  const [formModel, setFormModel] = useState(dvc.model); // modelo selecionado
  const [formIR, setFormIR] = useState(dvc.infraredId); // hash de IR
  const [formHash, setFormHash] = useState(dvc.hash); // hash de dispositivo
  const [formDesc, setFormDesc] = useState(dvc.description); // descrição
  const [formInterval, setFormInterval] = useState(dvc.commInterval); // tempo de atualização
  const [formLatitude, setFormLatitude] = useState(dvc.latitude); // latitude
  const [formLongitude, setFormLongitude] = useState(dvc.longitude); // longitude
  const [intUnit, setIntUnit] = useState(1); // unidade de tempo de indicação (s, min, h)

  const [tooltipLatitudeOpen, setTooltipLatitudeOpen] = useState(false);
  const [tooltipLongitudeOpen, setTooltipLongitudeOpen] = useState(false);
  const toggleTooltipLatitude = () => setTooltipLatitudeOpen(!tooltipLatitudeOpen);
  const toggleTooltipLongitude = () => setTooltipLongitudeOpen(!tooltipLongitudeOpen);

  const [climate, setClimate] = useState(false); // indicador de dispositivo de climatização
  const [loadClimate, setLoadClimate] = useState(false); // estado de carregamento lista de aparelhos de a/c cadastrados

  const [invalidManuf, setInvalidManuf] = useState(false); // estado de fabricante invalido
  const [invalidModel, setInvalidModel] = useState(false); // estado de modelo invalido
  const [invalidIR, setInvalidIR] = useState(false); // estado de hash de IR invalido
  const [invalidDesc, setInvalidDesc] = useState(false); // estado de descrição invalido
  const [invalidInterval, setInvalidInterval] = useState(false); // estado de tempo invalido

  const [loading, setLoading] = useState(false); // estado de carregamento envio de dados para api
  
  // busca lista de fabricantes de standalone
  useEffect(() => {
    axios.get('sdManufacturerSearch/')
    .then(res => {
      setManufacturers(res.data);
    })
    .catch(err => {
      console.log(err);
      // t() da problema com o useEffect
      toastr.error('Erro!', "Erro ao buscar fabricantes.");
    });
  }, []);

  // busca lista de modelos do fabricante selecionado
  useEffect(() => {
    if (formManuf) {
      axios.get('/sdModelSearch', {
        params: {
          name: formManuf
        }
      })
      .then(res => {
        setModels(res.data);
        if (res.data.find(item => item.model === dvc.model).types.includes("C")) {
          setClimate(true);
          setLoadClimate(true);
        }
      })
      .catch(err => {
        console.log(err);
        // t() da problema com o useEffect
        toastr.warning('Erro!', "Erro ao buscar modelos.");
      });
    }
  }, [formManuf, dvc]);

  // busca lista de dispositivos de ar condicionado
  useEffect(() => {
    if (loadClimate) {
      axios.get('/readACList', {
        params: {
          infraredId: formIR
        }
      })
      .then(res => {
        setACList(res.data.deviceList);
        setLoadClimate(false);
      })
      .catch(err => {
        console.log(err);
        // t() da problema com o useEffect
        toastr.warning('Erro!', "Erro ao buscar dispositivos.");
        setInvalidIR(true);
        setLoadClimate(false);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadClimate]);

  function onSubmitForm() {
    if (!formManuf || !formModel || !formDesc || (climate && !formIR) || (!climate && !formInterval)) {
      setInvalidManuf((!formManuf));
      setInvalidModel((!formModel));
      setInvalidDesc((!formDesc));
      setInvalidIR(climate && !formIR);
      setInvalidInterval(!climate && !formInterval);
      toastr.warning(`${t('projTable.att')}!`, `${t('projTable.invalidText')}.`)
    }

    else {
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        deviceId: dvc.id,
        climate: climate,
        manufacturer: formManuf,
        model: formModel,
        description: formDesc,
        deviceHash: formHash,
        infraredId: formIR,
        commInterval: formInterval * intUnit,
        latitude: formLatitude,
        longitude: formLongitude
      };

      setLoading(true);
      /*** Envio de dados ***/
      axios.put('editStandalone/', JSON.stringify(jsonData))
      .then(res => {
        setLoading(false);
        toastr.success(res.data.messageHead, res.data.messageBody);
        setLoadDevices(true); // Atualiza tabela
        toggle(); // ...fechar o modal
      })
      .catch(err => {
        setLoading(false);
        console.log(err);
        console.log(err.response);

        if (err.response) {
          /* Notificação de erro de envio */
          toastr.warning(
            err.response.data.messageHead,
            err.response.data.messageBody
          );
        }
      });
    }
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        onSubmitForm();
      }}
    >

      <ModalBody className="text-left mx-6">
        <h5 className="text-center">Editar Standalone</h5>

        <hr />

        {/*** CAMPO DE FABRICANTE ***/}
        <FormGroup>
          <Label>{t('projTable.manufacturer')}</Label>
          <Input
            type="select"
            name="manufacturer"
            id="manufacturer"
            value={formManuf}
            onChange={e => {
              setFormManuf(e.target.value);
              setFormModel('');
              setClimate(false);
              setInvalidManuf(false);
              setInvalidModel(false);
              setInvalidDesc(false);
              setInvalidIR(false);
              setInvalidInterval(false);
            }}
            invalid={invalidManuf}
            // disabled={!manufacturers.length || loading}
            disabled
          >
            <option value="" disabled>
              {t('projTable.select')}
            </option>
            {manufacturers.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
            <option value="myDrivers">
              Meus Drivers
            </option>
          </Input>
        </FormGroup>

        {/* CAMPO DE MODELO */}
        <FormGroup>
          <Label>{t('projTable.model')}</Label>
          <Input
            type="select"
            name="model"
            id="model"
            value={formModel}
            onChange={e => {
              setFormModel(e.target.value);
              setInvalidModel(false);
              setInvalidDesc(false);
              setInvalidIR(false);
              setInvalidInterval(false);

              if (models.find(item => item.model === e.target.value).types.includes("C")) {
                setClimate(true);
                setFormInterval(60);
                setIntUnit(60);
              }
              else {
                setClimate(false);
                setFormHash('');
                setFormIR('');
              };
            }}
            // disabled={(!models.length)}
            invalid={invalidModel}
            // disabled={!models.length || loading}
            disabled
          >
            <option value="" disabled>
              {t('projTable.select')}
            </option>
            {models.map((item, i) => (
              <option value={item.model} key={i}>
                {item.model}
              </option>
            ))}
          </Input>
        </FormGroup>

        {/* CAMPO DE IR HASH */}

        <FormGroup>
          <Label>{t('projTable.hashIR')}</Label>
          <InputGroup className="mb-3" size="sm">
            <Input
              type="text"
              name="irHash"
              id="irHash"
              value={formIR}
              disabled={loadClimate || loading}
              onChange={e => {
                setFormIR(e.target.value);
                setFormDesc('');
                setInvalidIR(false);
                setACList([]);
              }}
              invalid={invalidIR}
            />
            <InputGroupAddon addonType="append">
              <Button
                color="primary"
                disabled={(loadClimate)}
                onClick={() => {
                  if (formIR === '') {
                    setInvalidIR(true);
                    toastr.warning(`${t('projTable.att')}!`, `${t('projTable.insertIRHash')}.`)
                  }
                  else {
                    setLoadClimate(true);
                    setInvalidDesc(false);
                  }
                }}>
                {(loadClimate)
                  ? <div className="text-center"><Spinner color="white" size='sm' /></div>
                  : <FontAwesomeIcon fixedWidth icon={faSearch} />
                }
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>

        {/* CAMPO DE DESCRIÇÃO */}

        <FormGroup>
          <Label>{t('projTable.reference')}</Label>
          <Input
            type="select"
            name="description"
            id="description"
            value={formDesc}
            onChange={e => {
              setFormDesc(e.target.value);
              setFormHash(acList.find(item => item.deviceName === e.target.value).deviceHash);
              setInvalidDesc(false);
            }}
            disabled={!acList.length || loadClimate || loading}
            invalid={invalidDesc}
          >
            <option value="" disabled>
              {formDesc}
            </option>
            {(acList.length)
              ? acList.map((item, i) => (
                <option value={item.deviceName} key={i}>
                  {item.deviceName}
                </option>
              ))
              : null
            }
          </Input>
        </FormGroup>
        : <FormGroup>
          <Label>{t('projTable.reference')}</Label>
          <Input
            type="text"
            name="description"
            id="description"
            value={formDesc}
            onChange={e => {
              setFormDesc(e.target.value);
              setInvalidDesc(false);
            }}
            invalid={invalidDesc}
            disabled={loading}
          />
        </FormGroup>


        {/*** CAMPO DE TEMPO DE ATUALIZAÇÃO ***/}
        <FormGroup>
          <Label>{t('projTable.commInterval')}</Label>
          <Row>
            <Col md={4}>
              <Input
                type="number"
                name="commInterval"
                id="commInterval"
                value={formInterval}
                onChange={e => {
                  setFormInterval(e.target.value);
                  setInvalidInterval(false);
                }}
                invalid={invalidInterval}
                disabled={loading}
              />
            </Col>

            <Col md={4}>
              <Input
                className="pt-0"
                type="select"
                name="intUnit"
                id="intUnit"
                value={intUnit}
                onChange={e => setIntUnit(e.target.value)}
                disabled={loading}
              >
                <option value={1}>
                  {t('projTable.seconds')}
                </option>
                <option value={60}>
                  {t('projTable.minutes')}
                </option>
                <option value={3600}>
                  {t('projTable.hours')}
                </option>
              </Input>
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Row>
              {/* LATITUDE */}
              <Col md={6}>
                <Label>
                  {t("projTable.latitude")}
                  <FontAwesomeIcon id="latitudeHelpIcon" fixedWidth icon={faQuestionCircle} />
                  <Tooltip isOpen={tooltipLatitudeOpen} target="latitudeHelpIcon" toggle={toggleTooltipLatitude}>
                    {t("projTable.tooltipLatitude")}
                  </Tooltip>
                </Label>
                <Input
                  type="text"
                  name="latitude"
                  value={formLatitude}
                  onChange={(e) => {
                    setFormLatitude(e.target.value);
                  }}
                  disabled={loading}
                />
              </Col>
              
              {/* LONGITUDE */}
              <Col md={6}>
                <Label>
                  {t("projTable.longitude")}
                  <FontAwesomeIcon id="longitudeHelpIcon" fixedWidth icon={faQuestionCircle} />
                  <Tooltip isOpen={tooltipLongitudeOpen} target="longitudeHelpIcon" toggle={toggleTooltipLongitude}>
                    {t("projTable.tooltipLongitude")}
                  </Tooltip>
                </Label>
                <Input
                  type="text"
                  name="longitude"
                  value={formLongitude}
                  onChange={(e) => {
                    setFormLongitude(e.target.value);
                  }}
                  disabled={loading}
                />
              </Col>
          </Row>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        {(loading)
          ? <Spinner color="primary" className="mr-3" />
          : <>
            {/** BOTÃO DE CANCELAR */}
            <Button
              type="button"
              color='primary'
              className="mr-1"
              onClick={toggle}
              outline
            >
              {t('projTable.cancel')}
            </Button>

            {/** BOTÃO DE ENVIAR DADOS */}
            <Button
              name="btnClose"
              type="submit"
              color="primary"
            >
              {t('projTable.saveChanges')}
            </Button>
          </>
        }
      </ModalFooter>
    </Form>
  );
};

/****** MODAL DE NOVO LORAWAN ******/
const ModalEditLW = ({ toggle, dvc, setLoadDevices }) => {
  const { t } = useTranslation();

  const [hash, setHash] = useState(dvc.hash);
  const [networkHash, setNetworkHash] = useState(dvc.accessSecret);
  const [deviceAddress, setDeviceAddress] = useState(dvc.accessKey);

  const [manufacturers, setManufacturers] = useState([]); // lista de fabricantes
  const [models, setModels] = useState([]); // lista de modelos
  const [acList, setACList] = useState([]); // lista de aparelhos de a/c cadastrados

  const [formManuf, setFormManuf] = useState(dvc.manufacturer); // fabricante selecionado
  const [formModel, setFormModel] = useState(dvc.model); // modelo selecionado
  const [formIR, setFormIR] = useState(dvc.hash); // hash de IR
  const [formHash, setFormHash] = useState(dvc.hash); // hash de dispositivo
  const [formDesc, setFormDesc] = useState(dvc.description); // descrição
  const [formInterval, setFormInterval] = useState(dvc.commInterval); // tempo de atualização
  const [formLatitude, setFormLatitude] = useState(dvc.latitude); // latitude
  const [formLongitude, setFormLongitude] = useState(dvc.longitude); // longitude
  const [intUnit, setIntUnit] = useState(1); // unidade de tempo de indicação (s, min, h)

  const [tooltipLatitudeOpen, setTooltipLatitudeOpen] = useState(false);
  const [tooltipLongitudeOpen, setTooltipLongitudeOpen] = useState(false);
  const toggleTooltipLatitude = () => setTooltipLatitudeOpen(!tooltipLatitudeOpen);
  const toggleTooltipLongitude = () => setTooltipLongitudeOpen(!tooltipLongitudeOpen);

  const [climate, setClimate] = useState(false); // indicador de dispositivo de climatização
  const [loadClimate, setLoadClimate] = useState(false); // estado de carregamento lista de aparelhos de a/c cadastrados

  const [invalidManuf, setInvalidManuf] = useState(false); // estado de fabricante invalido
  const [invalidModel, setInvalidModel] = useState(false); // estado de modelo invalido
  const [invalidIR, setInvalidIR] = useState(false); // estado de hash de IR invalido
  const [invalidDesc, setInvalidDesc] = useState(false); // estado de descrição invalido
  const [invalidInterval, setInvalidInterval] = useState(false); // estado de tempo invalido

  const [loading, setLoading] = useState(false); // estado de carregamento envio de dados para api

  // busca lista de fabricantes de standalone
  useEffect(() => {
    axios.get('lwManufacturerSearch/')
      .then(res => {
        setManufacturers(res.data);
      })
      .catch(err => {
        console.log(err);
        // t() da problema com o useEffect
        toastr.error('Erro!', "Erro ao buscar fabricantes.");
      });
  }, []);

  // busca lista de modelos do fabricante selecionado
  useEffect(() => {
    if (formManuf) {
      axios.get('/lwModelSearch', {
        params: {
          name: formManuf
        }
      })
        .then(res => {
          setModels(res.data);
          if (res.data.find(item => item.model === dvc.model).types.includes("C")) {
            setClimate(true);
            setLoadClimate(true);
          }
        })
        .catch(err => {
          console.log(err);
          // t() da problema com o useEffect
          toastr.warning('Erro!', "Erro ao buscar modelos.");
        });
    }
  }, [formManuf, dvc]);

  function onSubmitForm() {
    if (!formManuf || !formModel || !formDesc) {
      setInvalidManuf((!formManuf));
      setInvalidModel((!formModel));
      setInvalidDesc((!formDesc));
      toastr.warning(`${t('projTable.att')}!`, `${t('projTable.invalidText')}.`)
    }

    else {
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        deviceId: dvc.id,
        manufacturer: formManuf,
        model: formModel,
        description: formDesc,
        commInterval: formInterval * intUnit,
        latitude: formLatitude,
        longitude: formLongitude,
        hash: hash,
        accessSecret: networkHash,
        accessKey: deviceAddress
      };

      setLoading(true);
      /*** Envio de dados ***/
      axios.put('editLoraDevice/', JSON.stringify(jsonData))
        .then(res => {
          setLoading(false);
          toastr.success(res.data.messageHead, res.data.messageBody);
          setLoadDevices(true); // Atualiza tabela
          toggle(); // ...fechar o modal
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
          console.log(err.response);

          if (err.response) {
            /* Notificação de erro de envio */
            toastr.warning(
              err.response.data.messageHead,
              err.response.data.messageBody
            );
          }
        });
    }
  }

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        onSubmitForm();
      }}
    >

      <ModalBody className="text-left mx-6">
        <h5 className="text-center">Editar LoRaWAN</h5>

        <hr />

        {/*** CAMPO DE FABRICANTE ***/}
        <FormGroup>
          <Label>{t('projTable.manufacturer')}</Label>
          <Input
            type="select"
            name="manufacturer"
            id="manufacturer"
            value={formManuf}
            onChange={e => {
              setFormManuf(e.target.value);
              setFormModel('');
              setClimate(false);
              setInvalidManuf(false);
              setInvalidModel(false);
              setInvalidDesc(false);
              setInvalidIR(false);
              setInvalidInterval(false);
            }}
            invalid={invalidManuf}
            disabled={!manufacturers.length || loading}
          >
            <option value="" disabled>
              {t('projTable.select')}
            </option>
            {manufacturers.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
            <option value="myDrivers">
              Meus Drivers
            </option>
          </Input>
        </FormGroup>

        {/* CAMPO DE MODELO */}
        <FormGroup>
          <Label>{t('projTable.model')}</Label>
          <Input
            type="select"
            name="model"
            id="model"
            value={formModel}
            onChange={e => {
              setFormModel(e.target.value);
              setInvalidModel(false);
              setInvalidDesc(false);
              setInvalidIR(false);
              setInvalidInterval(false);

              if (models.find(item => item.model === e.target.value).types.includes("C")) {
                setClimate(true);
                setFormInterval(60);
                setIntUnit(60);
              }
              else {
                setClimate(false);
              };
            }}
            // disabled={(!models.length)}
            invalid={invalidModel}
            disabled={!models.length || loading}
          >
            <option value="" disabled>
              {t('projTable.select')}
            </option>
            {models.map((item, i) => (
              <option value={item.model} key={i}>
                {item.model}
              </option>
            ))}
          </Input>
        </FormGroup>

        {/* CAMPO DE DESCRIÇÃO */}
        <FormGroup>
          <Label>{t('projTable.reference')}</Label>
          <Input
            type="text"
            name="description"
            id="description"
            value={formDesc}
            onChange={e => {
              setFormDesc(e.target.value);
              setInvalidDesc(false);
            }}
            invalid={invalidDesc}
            disabled={loading}
          />
        </FormGroup>

        {/* HASH */}
        <FormGroup>
          <Label>Hash</Label>
          <Input
            type="text"
            name="hash"
            id="hash"
            value={hash}
            onChange={(e) => setHash(e.target.value)}

          />
        </FormGroup>

        {/* NETWORK DEVICE */}
        <FormGroup>
          <Label>Network Hash</Label>
          <Input
            type="text"
            name="networdHash"
            id="networdHash"
            value={networkHash}
            onChange={(e) => setNetworkHash(e.target.value)}

          />
        </FormGroup>

        {/* DEVICE ADDRESS */}
        <FormGroup>
          <Label>Device Address</Label>
          <Input
            type="text"
            name="deviceAddress"
            id="deviceAddress"
            value={deviceAddress}
            onChange={(e) => setDeviceAddress(e.target.value)}

          />
        </FormGroup>

        {/*** CAMPO DE TEMPO DE ATUALIZAÇÃO ***/}
        <FormGroup>
            <Label>{t('projTable.commInterval')}</Label>
            <Row>
              <Col md={4}>
                <Input
                  type="number"
                  name="commInterval"
                  id="commInterval"
                  value={formInterval}
                  onChange={e => {
                    setFormInterval(e.target.value);
                    setInvalidInterval(false);
                  }}
                  invalid={invalidInterval}
                  disabled={loading}
                />
              </Col>

              <Col md={4}>
                <Input
                  className="pt-0"
                  type="select"
                  name="intUnit"
                  id="intUnit"
                  value={intUnit}
                  onChange={e => setIntUnit(e.target.value)}
                  disabled={loading}
                >
                  <option value={1}>
                    {t('projTable.seconds')}
                  </option>
                  <option value={60}>
                    {t('projTable.minutes')}
                  </option>
                  <option value={3600}>
                    {t('projTable.hours')}
                  </option>
                </Input>
              </Col>
            </Row>
        </FormGroup>

        <FormGroup>
          <Row>
              {/* LATITUDE */}
              <Col md={6}>
                <Label>
                  {t("projTable.latitude")}
                  <FontAwesomeIcon id="latitudeHelpIcon" fixedWidth icon={faQuestionCircle} />
                  <Tooltip isOpen={tooltipLatitudeOpen} target="latitudeHelpIcon" toggle={toggleTooltipLatitude}>
                    {t("projTable.tooltipLatitude")}
                  </Tooltip>
                </Label>
                <Input
                  type="text"
                  name="latitude"
                  value={formLatitude}
                  onChange={(e) => {
                    setFormLatitude(e.target.value);
                  }}
                  disabled={loading}
                />
              </Col>
              
              {/* LONGITUDE */}
              <Col md={6}>
                <Label>
                  {t("projTable.longitude")}
                  <FontAwesomeIcon id="longitudeHelpIcon" fixedWidth icon={faQuestionCircle} />
                  <Tooltip isOpen={tooltipLongitudeOpen} target="longitudeHelpIcon" toggle={toggleTooltipLongitude}>
                    {t("projTable.tooltipLongitude")}
                  </Tooltip>
                </Label>
                <Input
                  type="text"
                  name="longitude"
                  value={formLongitude}
                  onChange={(e) => {
                    setFormLongitude(e.target.value);
                  }}
                  disabled={loading}
                />
              </Col>
          </Row>
        </FormGroup>

      </ModalBody>

      <ModalFooter>
        {(loading)
          ? <Spinner color="primary" className="mr-3" />
          : <>
            {/** BOTÃO DE CANCELAR */}
            <Button
              type="button"
              color='primary'
              className="mr-1"
              onClick={toggle}
              outline
            >
              {t('projTable.cancel')}
            </Button>

            {/** BOTÃO DE ENVIAR DADOS */}
            <Button
              name="btnClose"
              type="submit"
              color="primary"
            >
              {t('projTable.saveChanges')}
            </Button>
          </>
        }
      </ModalFooter>
    </Form>
  );
};

/** MODAL DE EDIÇÃO DE REGISTRO DE DISPOSITIVO */
const ModalEditDevice = ({open, toggle, dvc, setLoadDevices, deviceList}) => {
  const { t } = useTranslation();
  console.log(dvc);
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        {t('projTable.editDevice')} {dvc.description}
      </ModalHeader>

      {(dvc.category === 'pp')
        ? <ModalEditPP 
            toggle={toggle}
            dvc={dvc} 
            setLoadDevices={setLoadDevices} 
            deviceList={deviceList}
          />
        : (dvc.category === 'gw')
          ? <ModalEditGW 
              toggle={toggle}
              dvc={dvc} 
              setLoadDevices={setLoadDevices} 
            />
          : (dvc.category === 'lw')
            ? <ModalEditLW
              toggle={toggle}
              dvc={dvc}
              setLoadDevices={setLoadDevices}
            />
          : <ModalEditSD
              toggle={toggle} 
              dvc={dvc} 
              setLoadDevices={setLoadDevices} 
            />
      }

    </Modal>
  );
};

/** MODAL DE GERENCIAMENTO DE FATORES DE MULTIPLICAÇÃO */
const ModalDeviceGains = ({ open, toggle, dvc }) => {
  const { t } = useTranslation();

  const [loadVarList, setLoadVarList] = useState(true);
  const [loading, setLoading] = useState(true);
  const [varList, setVarList] = useState([]);
  const [gainList, setGainList] = useState([]);
  const [formTag, setFormTag] = useState("");
  const [formGain, setFormGain] = useState("");
  const [formUnit, setFormUnit] = useState("");
  const [invalidTag, setInvalidTag] = useState(false);
  const [invalidGain, setInvalidGain] = useState(false);
  const [gainToEdit, setGainToEdit] = useState();

  useEffect(() => {
    axios.get('readVariableGains/', {
      params: {
        deviceId: dvc.id
      }
    })
      .then(response => {
        setVarList(response.data.varList);
        setGainList(response.data.gainList);
      })
      .catch(err => {
        console.log(err);
        // t() da problema com o useEffect
        toastr.warning('Erro!', "Erro ao buscar fatores de multiplicação.");
      })
      .finally(() => {
        setLoadVarList(false);
        setLoading(false);
      });
  }, [dvc]);

  function onAddGain() {
    if (!formTag || !formGain) {
      setInvalidTag(!formTag);
      setInvalidGain(!formGain);

      toastr.warning(`${t('projTable.att')}!`, `${t('projTable.addGainWarning')}.`);
    }

    else {
      const jsonData = {
        deviceId: dvc.id,
        dvcModel: dvc.model,
        varName: varList.find(item => item.tag === formTag).varName,
        varTag: formTag,
        gain: formGain,
        unit: formUnit
      };

      // console.log(jsonData);
      // return

      setLoading(true);
      axios.post('addGain/', JSON.stringify(jsonData))
        .then(res => {
          setFormTag("");
          setFormGain("");
          let arr = gainList;
          arr.push(res.data.newGain);
          setGainList(arr);

          toastr.success(`${res.data.messageHead}!`, `${res.data.messageBody}.`);
        })
        .catch(err => {
          console.log(err);
          toastr.warning(`${t('projTable.error')}!`, `${t('projTable.addGainError')}.`);
        })
        .finally(() => setLoading(false));
    }
  };

  function onEditGain() {
    if (!formGain) {
      setInvalidGain(!formGain);

      toastr.warning(`${t('projTable.att')}!`, `${t('projTable.editGainWarning')}.`);
    }

    else {
      const jsonData = {
        gainId: gainToEdit.id,
        dvcModel: dvc.model,
        varName: varList.find(item => item.tag === formTag).varName,
        varTag: formTag,
        gain: formGain,
        unit: formUnit
      };

      setLoading(true);
      axios.put('editGain/', JSON.stringify(jsonData))
        .then(res => {
          setFormTag("");
          setFormGain("");
          setFormUnit("");
          const arr = gainList.map(item => item.id === gainToEdit.id
            ? res.data.editedGain
            : item
          );
          setGainList(arr);
          setGainToEdit();

          toastr.success(`${res.data.messageHead}!`, `${res.data.messageBody}.`);
        })
        .catch(err => {
          console.log(err);
          toastr.warning(`${t('projTable.error')}!`, `${t('projTable.editGainError')}.`);
        })
        .finally(() => setLoading(false));
    }
  }

  function onRemoveGain(gainId) {
    setLoading(true);
    axios.delete('removeGain/', { data: { gainId: gainId } })
      .then(res => {
        setGainList(gainList.filter(x => x.id !== gainId));
        toastr.success(`${res.data.messageHead}!`, `${res.data.messageBody}.`);
      })
      .catch(err => {
        console.log(err);
        toastr.warning(`${t('projTable.error')}!`, `${t('projTable.removeGainError')}.`);
      })
      .finally(() => setLoading(false));
  };

  const gainCols = [
    { // ID (oculto)
      dataField: "id",
      text: t('projTable.id'),
      sort: true,
      hidden: true,
      isKey: true
    },
    { // nome da variável
      dataField: "varName",
      text: t('projTable.variable'),
      sort: true
    },
    { // fator de multiplicação
      dataField: "gain",
      text: t('projTable.value'),
      headerStyle: { width: "21%" },
      sort: true,
      formatter: cell => String(+cell).replace(".", ",")
    },
    { // unidade
      dataField: "unit",
      text: "Unidade",
      headerStyle: { width: "21%" },
      // sort: true,
      formatter: cell => (cell) ? cell : '-'
    },
    { // botões de ação
      dataField: "actions",
      text: t('projTable.actions'),
      headerStyle: { width: "11%" },
      formatter: (_, row) => (
        <div className="d-flex justify-content-left">
          <div // editar
            id={"editGain" + row.id}
            className="align-middle cursor-pointer"
            onClick={() => {
              setGainToEdit(gainList.find(item => item.id === row.id));
              setFormTag(gainList.find(item => item.id === row.id).varTag);
              setFormGain(gainList.find(item => item.id === row.id).gain);
              setFormUnit(gainList.find(item => item.id === row.id).unit);
            }}
          >
            <FontAwesomeIcon icon={faPen} fixedWidth />
            {/* Tooltip */}
            <UncontrolledTooltip
              placement="top"
              target={"editGain" + row.id}
            >
              {t('projTable.edit')}
            </UncontrolledTooltip>
          </div>

          <div // remover
            id={"removeGain" + row.id}
            className="align-middle cursor-pointer"
            onClick={() => onRemoveGain(row.id)}
          >
            <FontAwesomeIcon icon={faTrash} fixedWidth />
            {/* Tooltip */}
            <UncontrolledTooltip
              placement="top"
              target={"removeGain" + row.id}
            >
              {t('projTable.delete')}
            </UncontrolledTooltip>
          </div>
        </div>
      )
    }
  ];

  /* Ícones de ordenação */
  const sortIcon = {
    sortCaret: (order) => (!order)
      ? <FontAwesomeIcon
        className={'fa-xs ml-1'}
        icon={faSort}
      />
      : (order === 'asc')
        ? <FontAwesomeIcon
          className={'fa-xs ml-1'}
          icon={faSortUp}
        />
        : <FontAwesomeIcon
          className={'fa-xs ml-1'}
          icon={faSortDown}
        />
  };

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        {t('projTable.manageGainsHead')} {dvc.description}
      </ModalHeader>

      <ModalBody className="text-left mx-3">
        <Row>
          {/*** CAMPO DE VARIÁVEL ***/}
          <Col className="pr-0" md="5" xs="12">
            <FormGroup className="mb-0">
              <Label className="ml-1">{t('projTable.variable')}</Label>
              <Input
                type="select"
                name="varTag"
                id="varTag"
                value={formTag}
                onChange={e => {
                  setFormTag(e.target.value);
                  setInvalidTag(false);

                  // let unit = varList.find(item => item.tag === e.target.value).unit;
                  setFormUnit(varList.find(item => item.tag === e.target.value).unit);
                }}
                disabled={(loading)}
                invalid={invalidTag}
              >
                <option value="" disabled>
                  {t('projTable.select')}
                </option>
                {varList.map((element, index) => (
                  (!gainList.find(item => item.varTag === element.tag)
                    || (gainToEdit && element.tag === gainToEdit.varTag)
                  )
                    ? <option value={element.tag} key={index}>
                      {element.varName}
                    </option>
                    : null
                ))}
              </Input>
            </FormGroup>
          </Col>

          {/*** CAMPO DE GANHO ***/}
          <Col className="pr-0" md="3" sm="6" xs="5">
            <FormGroup className="mb-0">
              <Label className="ml-1">
                {t('projTable.value')}
              </Label>
              <Input
                type="number"
                name="varGain"
                id="varGain"
                value={formGain}
                onChange={e => {
                  setFormGain(e.target.value);
                  setInvalidGain(false);
                }}
                disabled={(loading)}
                invalid={invalidGain}
              />
            </FormGroup>
          </Col>

          {/*** CAMPO DE UNIDADE ***/}
          <Col md="3" sm="5" xs="5">
            <FormGroup className="mb-0">
              <Label className="ml-1">
                Unidade
              </Label>
              <Input
                type="text"
                name="varUnit"
                id="varUnit"
                value={(formUnit === null) ? "" : formUnit}
                onChange={e => setFormUnit(e.target.value)}
                disabled={(loading)}
              />
            </FormGroup>
          </Col>

          {/* BOTÃO [+] */}
          <Col sm="1" xs="2" className="d-flex align-items-end justify-content-end">
            <Button
              size="sm"
              color="primary"
              onClick={() => (gainToEdit) ? onEditGain() : onAddGain()}
              disabled={(loading)}
            >
              {(loading)
                ? <Spinner size="sm" />
                : (gainToEdit)
                  ? <FontAwesomeIcon fixedWidth icon={faCheck} />
                  : <FontAwesomeIcon fixedWidth icon={faPlus} />
              }
            </Button>
          </Col>
        </Row>

        <hr />

        {(loadVarList)
          ? <div className="text-center">
            <Spinner color="primary" />
          </div>
          : (!gainList.length)
            ? <p>
              {t('projTable.noGains')}
            </p>
            : <BootstrapTable
              bootstrap4
              keyField="id"
              data={gainList}
              columns={gainCols}
              condensed
              bordered={false}
              striped={true}
              sort={sortIcon}
            />
        }
      </ModalBody>

      <ModalFooter>
        {/*** BOTÃO DE FECHAR O MODAL ***/}
        <Button
          name="btnClose"
          type="button"
          color="primary"
          outline
          onClick={toggle}
        >
          {t('projTable.close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export { ModalEditProject, ModalEditDevice, ModalDeviceGains };