import { Badge } from "reactstrap";
import { useTranslation } from 'react-i18next';

export const PriorityBadge = ({ priority }) => {
    const { t } = useTranslation();
  
    return (priority === 1)
      ? <Badge color="danger">{t('alarms.high')}</Badge>
      : (priority === 2) 
        ? <Badge color="warning">{t('alarms.medium')}</Badge>
        : <Badge color="primary">{t('alarms.low')}</Badge>
};
