import moment from "moment";
import React, { useRef, useState } from "react";
import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ArrowLeftCircle, ArrowRightCircle } from "react-feather";
import { useTranslation } from "react-i18next";

const ENUM_ENTRY_RANGE = {
  DAYS: "days",
  MONTHS: "months",
  YEARS: "years",
  MINUTES: "minutes",
};

/**
 * DateRangeSelector component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @param {boolean} props.loading - Indicates if the component is in a loading state.
 * @param {Date} props.initDate - The initial date for the date range.
 * @param {function} props.setEntrieStartDate - The function to set the start date of the date range.
 * @param {function} props.setEntrieEndDate - The function to set the end date of the date range.
 * @param {function} props.setEntrieRange - The function to set the range of the date entries.
 * @param {Array<string>} [props.notShowFilters=[]] - The filters to exclude from the component.
 * @returns {JSX.Element} The DateRangeSelector component.
 */
export const DateRangeSelector = ({
  children,
  loading,
  initDate,
  setEntrieStartDate,
  setEntrieEndDate,
  setEntrieRange,
  notShowFilters = [],
}) => {
  const { t } = useTranslation();

  const currentDate = moment().endOf("day").toDate();
  const [endDate, setEndDate] = useState(
    moment(initDate).endOf("day").toDate()
  );
  const [startDate, setStartDate] = useState(
    moment(initDate).startOf("month").toDate()
  );
  const [selectedRange, setSelectedRange] = useState("fullMonth");
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [modalToogle, setModalToggle] = useState(false);

  const datePickerRef = useRef(null);
  const datePickerModalInitRef = useRef(null);
  const datePickerModalEndRef = useRef(null);

  const applyDateRange = (range) => {
    let newStartDate = null;
    let newEndDate = null;
       
    let entryRange = ENUM_ENTRY_RANGE.DAYS;

    switch (range) {
      case "today":
        newStartDate = moment().startOf("day").toDate();
        newEndDate = moment().endOf("day").toDate();
        entryRange = ENUM_ENTRY_RANGE.MINUTES;
        break;
      case "24Hours":
        newEndDate = moment(endDate).endOf("day").toDate();
        newStartDate = moment(endDate).startOf("day").toDate();
        entryRange = ENUM_ENTRY_RANGE.MINUTES;
        break;
      case "last24Hours":
        newEndDate = moment().toDate();
        newStartDate = moment().subtract(24, "hours").toDate();
        entryRange = ENUM_ENTRY_RANGE.MINUTES;
        break;
      case "7D":
        newStartDate = moment(endDate)
          .subtract(7, "days")
          .startOf("day")
          .toDate();
        break;
      case "last7Days":
        newEndDate = moment().endOf("day").toDate();
        newStartDate = moment().subtract(7, "days").startOf("day").toDate();
        break;
      case "last30Days":
        newEndDate = moment().endOf("day").toDate();
        newStartDate = moment().subtract(30, "days").startOf("day").toDate();
        break;
      case "fullMonth":
        newStartDate = moment(endDate).startOf("month").toDate();
        break;
      case "last12Months":
        newEndDate = moment().endOf("day").toDate();
        newStartDate = moment()
          .subtract(11, "months")
          .startOf("month")
          .toDate();
        entryRange = ENUM_ENTRY_RANGE.MONTHS;
        break;
      case "fullYear":
        entryRange = ENUM_ENTRY_RANGE.MONTHS;
        newStartDate = moment(endDate).startOf("year").toDate();
        break;
      default:
        newStartDate = moment(endDate)
          .subtract(7, "days")
          .startOf("day")
          .toDate();
    }

    if (newStartDate) {
      setStartDate(newStartDate);
      setEntrieStartDate(newStartDate);
    }

    if (newEndDate) {
      newEndDate = moment(newEndDate).isAfter(currentDate)
        ? currentDate
        : newEndDate;
      setEndDate(newEndDate);
      setEntrieEndDate(newEndDate);
    }

    setSelectedRange(range);
    setEntrieRange(entryRange);
  };

  const handleApplyCustomDate = () => {
    if (customStartDate && customEndDate) {
      const start = moment(customStartDate).startOf("minute").toDate();
      const end = moment(customEndDate).endOf("minute").toDate();

      const validEndDate = moment(end).isAfter(currentDate) ? currentDate : end;

      setStartDate(start);
      setEntrieStartDate(start);
      setEndDate(validEndDate);
      setEntrieEndDate(validEndDate);
      setSelectedRange("custom");
      setModalToggle(false);
    }
  };

  const handleChangeRange = (direction) => {
    let newStartDate = moment(startDate);
    let newEndDate = moment(endDate);

    switch (selectedRange) {
      case "custom":
        const rangeDuration = newEndDate.diff(newStartDate);

        newStartDate =
          direction === "next"
            ? newStartDate.add(rangeDuration, "milliseconds")
            : newStartDate.subtract(rangeDuration, "milliseconds");

        newEndDate =
          direction === "next"
            ? newEndDate.add(rangeDuration, "milliseconds")
            : newEndDate.subtract(rangeDuration, "milliseconds");

        break;
      case "today":
        newStartDate =
          direction === "next"
            ? moment(newEndDate).add(1, "day").startOf("day")
            : moment(newEndDate).subtract(1, "day").startOf("day");

        newEndDate =
          direction === "next"
            ? moment(newStartDate).endOf("day")
            : moment(newStartDate).endOf("day");

        break;
      case "24Hours":
        newEndDate =
          direction === "next"
            ? moment(newEndDate).add(24, "hours")
            : moment(newEndDate).subtract(24, "hours");

        newStartDate =
          direction === "next" ? moment(newEndDate).startOf("day") : moment(newEndDate).startOf("day");

        break;
      case "last24Hours":
        newEndDate =
          direction === "next"
            ? moment(newEndDate).add(24, "hours")
            : moment(newEndDate).subtract(24, "hours");

        newStartDate =
          direction === "next"
            ? moment(newEndDate).subtract(24, "hours")
            : moment(newEndDate).subtract(24, "hours");

        break;
      case "7D":
        newEndDate =
          direction === "next"
            ? moment(newEndDate).add(7, "days")
            : moment(newEndDate).subtract(7, "days");

        newStartDate =
          direction === "next"
            ? moment(newEndDate).subtract(7, "days")
            : moment(newEndDate).subtract(7, "days");

        break;
      case "last7Days":
        newEndDate =
          direction === "next"
            ? moment(newEndDate).add(7, "days")
            : moment(newEndDate).subtract(7, "days");

        newStartDate =
          direction === "next"
            ? moment(newEndDate).subtract(7, "days")
            : moment(newEndDate).subtract(7, "days");

        break;
      case "last30Days":
        newEndDate =
          direction === "next"
            ? moment(newEndDate).add(30, "days")
            : moment(newEndDate).subtract(30, "days");

        newStartDate =
          direction === "next"
            ? moment(newEndDate).subtract(30, "days")
            : moment(newEndDate).subtract(30, "days");

        break;
      case "fullMonth":
        newEndDate =
          direction === "next"
            ? moment(newEndDate).add(1, "month").endOf("month")
            : moment(newEndDate).subtract(1, "month").endOf("month");

        newStartDate =
          direction === "next"
            ? moment(newEndDate).startOf("month")
            : moment(newEndDate).startOf("month");

        break;
      case "last12Months":
        newEndDate =
          direction === "next"
            ? moment(newEndDate).add(12, "months")
            : moment(newEndDate).subtract(12, "months");

        newStartDate =
          direction === "next"
            ? moment(newEndDate).subtract(12, "months").startOf("month")
            : moment(newEndDate).subtract(12, "months").startOf("month");
        

        break;
      case "fullYear":
        newEndDate =
          direction === "next"
            ? moment(newEndDate).add(1, "year").endOf("year")
            : moment(newEndDate).subtract(1, "year").endOf("year");
        
        newStartDate =
          direction === "next"
            ? moment(newEndDate).startOf("year")
            : moment(newEndDate).startOf("year");

        break;
      default:
        break;
    }

    newStartDate = moment(newStartDate).isAfter(currentDate)
      ? currentDate
      : newStartDate.toDate();
    newEndDate = moment(newEndDate).isAfter(currentDate)
      ? currentDate
      : newEndDate.toDate();

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setEntrieStartDate(newStartDate);
    setEntrieEndDate(newEndDate);
  };

  const formattedStartDate = moment(startDate).format("DD/MM/YYYY - ddd");
  const formattedEndDate = moment(endDate).format("DD/MM/YYYY - ddd");

  return (
    <Container style={{
      display: "flex",
      flexDirection: "column",
      flex: 1,
    }}>
      <Row>
        <Col className="button-group" style={{ width: "fit-content" }}>
          <Row className="m-0">
            {[
              "today",
              "last24Hours",
              "last7Days",
              "last30Days",
              "last12Months",
              "24Hours",
              "7D",
              "fullMonth",
              "fullYear",
            ]
            .filter((e) => !notShowFilters.includes(e))
            .map((e) => (
              <Button
                style={
                  selectedRange === e
                    ? {
                        color: "white",
                        backgroundColor: "#3b7ddd",
                        border: "none",
                      }
                    : {
                        color: "black",
                        backgroundColor: "transparent",
                        border: "none",
                      }
                }
                onClick={() => applyDateRange(e)}
              >
                {t(`dateRangeSelector.${e}`)}
              </Button>
            ))}
            <Button
              style={
                selectedRange === "custom"
                  ? {
                      color: "white",
                      backgroundColor: "#3b7ddd",
                      border: "none",
                    }
                  : {
                      color: "black",
                      backgroundColor: "transparent",
                      border: "none",
                    }
              }
              onClick={() => setModalToggle(true)}
            >
              {t("dateRangeSelector.custom")}
            </Button>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            gap: "2px",
          }}
        >
          <ArrowLeftCircle onClick={() => handleChangeRange("prev")} style={{cursor: "pointer"}}/>
          <div>
            <label
              // onClick={() => datePickerRef.current.setOpen(true)}
              className="date-label"
              style={{
                // cursor: "pointer",
                fontSize: "16px",
                color: "#007bff",
                padding: "4px",
                borderRadius: "4px",
                display: "inline-block",
              }}
            >
              {`${formattedStartDate} → ${formattedEndDate}`}
            </label>
            <DatePicker
              ref={datePickerRef}
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              dateFormat="dd/MM/yyyy HH:mm"
              showTimeSelect
              timeFormat="HH:mm"
              placeholderText="Selecione a data final"
              className="d-none"
            />
          </div>
          <ArrowRightCircle onClick={() => handleChangeRange("next")} style={{cursor: "pointer"}}/>
        </Col>
      </Row>

      {loading ? (
        <Row
          className="d-flex align-items-center justify-content-center h-25"
          style={{ minHeight: "120px" }}
        >
          <Spinner />
        </Row>
      ) : (
        children
      )}

      <Modal show={modalToogle} onHide={() => setModalToggle(false)}>
        <Modal.Header>
          <Modal.Title>{t("dateRangeSelector.custom")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DatePicker
            ref={datePickerModalInitRef}
            selected={customStartDate}
            onChange={(date) => setCustomStartDate(date)}
            dateFormat="yyyy/MM/dd"
            showTimeSelect
            timeFormat="HH:mm"
            placeholderText="Selecione a data inicial"
            className="d-none"
          />

          <DatePicker
            ref={datePickerModalEndRef}
            selected={customEndDate}
            onChange={(date) => setCustomEndDate(date)}
            dateFormat="dd/MM/yyyy HH:mm"
            showTimeSelect
            timeFormat="HH:mm"
            placeholderText="Selecione a data final"
            className="d-none"
          />
          <Row className="m-0 d-flex justify-content-between">
            <label
              onClick={() => datePickerModalInitRef.current.setOpen(true)}
              className="date-label"
              style={{
                cursor: "pointer",
                fontSize: "16px",
                color: "#007bff",
                padding: "4px",
                borderRadius: "4px",
                display: "inline-block",
              }}
            >
              {customStartDate
                ? moment(customStartDate).format("DD/MM/YYYY hh:mm - dddd")
                : t("dateRangeSelector.selectStartDate")}
            </label>
            <span
              className="date-label"
              style={{
                cursor: "pointer",
                fontSize: "16px",
                color: "#007bff",
                padding: "4px",
                borderRadius: "4px",
                display: "inline-block",
              }}
            >
              →
            </span>
            <label
              onClick={() => datePickerModalEndRef.current.setOpen(true)}
              className="date-label"
              style={{
                cursor: "pointer",
                fontSize: "16px",
                color: "#007bff",
                padding: "4px",
                borderRadius: "4px",
                display: "inline-block",
              }}
            >
              {customEndDate
                ? moment(customEndDate).format("DD/MM/YYYY hh:mm - dddd")
                : t("dateRangeSelector.selectEndDate")}
            </label>
            <Button onClick={handleApplyCustomDate}>
              {t("dateRangeSelector.apply")}
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
