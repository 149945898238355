import React from "react";
import { useSelector } from "react-redux";

const Content = ({ children }) => {
  const currentAside = useSelector((store) => store.sidebar);
  const innerWidth = window.innerWidth;

  return (
    <div
      className="content"
      style={{
        transition: "all 0.3s",
        margin: "8px 0",
        marginLeft: innerWidth <= 768 ? "0px" : currentAside.isOpen ? "240px" : "48px" ,
      }}
    >
      {children}
    </div>
  );
};

export default Content;
