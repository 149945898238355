import React from "react";
import { useTranslation } from "react-i18next";
import { CardBody, CardHeader, CardTitle, Spinner } from "reactstrap";

import {
  Bar,
  BarChart,
  CartesianAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatEnergy } from "../../../../../utils/functions";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import useChartHeight from "../hook";

const EnergyChart = ({ hours, energy }) => {
  const { t } = useTranslation();

  // Define os dados
  const data = hours.map((hour, index) => ({
    time: hour.toString().padStart(2, "0"),
    energy: energy[index] || 0,
  }));
  const height = useChartHeight()


  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3"  />
        <XAxis dataKey="time" />
        <YAxis tickFormatter={formatEnergy} width={80} />
        <Tooltip formatter={formatEnergy} />
        <Legend />
        <Bar
          type="monotone"
          dataKey="energy"
          name={`${t("solar.energyLabel")}`}
          stroke="#8884d8"
          fill="#8884d8"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

const CurrentDayPower = ({ projectId }) => {
  const { t } = useTranslation();
  const { data, status } = useQuery({
    queryKey: ["readSolarEnergyDay", { projId: projectId }],
    queryFn: () =>
      axios
        .get("/readSolarEnergyDay", {
          params: {
            projId: projectId,
          },
        })
        .then((res) => res.data),
    staleTime: 1000 * 60 * 60, // 1 hour
    refetchInterval: 1000 * 60 * 60, // 1 hour
  });
  return (
    <>
      <CardHeader className="d-flex align-items-center justify-content-between mr-4">
        <CardTitle tag="h5">{`${t("solar.currentDayEnergy")}`}</CardTitle>
      </CardHeader>

      <CardBody>
        {status === "loading" ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <EnergyChart
            hours={data.dailyDataEnergy.times}
            energy={data.dailyDataEnergy.energy}
            consumption={data.dailyDataEnergy.consumption}
          />
        )}
      </CardBody>
    </>
  );
};

export default CurrentDayPower;
