import React from "react";
import { useTranslation } from "react-i18next";
import { 
  Card, CardHeader, CardTitle, 
  Row, Col, CardFooter,
} from "reactstrap";

const DataCard = ({ title, value, unit, reverse, dark, ifNullValue, timestamp }) => {
  const {t} = useTranslation();
  return (
    <Card  style={{minHeight:"106px"}} className={dark ? "bg-light text-center" : null}>
      <CardHeader style={{height: "67px"}}>
        <Row>
          <Col>
            <CardTitle tag="h5" className="mb-0">
              {title}
            </CardTitle>
          </Col>
        </Row>
        { timestamp &&
          <Row className="d-block text-center">
            {timestamp}
          </Row>
        } 
      </CardHeader>

      <CardFooter className="text-center m-auto" style={{height: "80%"}}>
        {(value || value === 0)
          ? (typeof value === 'number')
            ? 
              <h4 style={{ fontSize: "18px", fontWeight: "inherit" }}>
                {(!reverse)
                  ? value.toFixed(3).replace('.', ',') + " " + unit
                  : unit + " " + value.toFixed(2).replace('.', ',')
                }
              </h4>
            : 
              <h4 style={{ fontSize: ifNullValue }}>
                {value}
              </h4>
          : <p>{t('water.noData')}</p>
        }
      </CardFooter>
    </Card>
  );
};

export default DataCard;