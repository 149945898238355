//NOTE: importações não utilizadas vão ser utilizadas na próxima refatoração

import {
  faBuilding, 
  faCog,
  faFilePdf,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";

import enGB from "date-fns/locale/en-GB";
import ptBR from "date-fns/locale/pt-BR";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { useWaterModuleAPI } from "../WaterProvider";
import ModalWaterReport from "./modals/ModalWaterReport";
import ModalWaterSettings from "./modals/ModalWaterSettings";
import ModalWaterUCs from "./modals/ModalWaterUCs";

const WaterTools = ({ data, projectId, loading, setLoading, adminUser }) => {
  const { t, i18n } = useTranslation();
  const { selectedMonthRef, handleChangeDate, searchReport, setSearchReport } =
    useWaterModuleAPI();

  const [openModalReport, setOpenModalReport] = useState(false);
  const [openModalUCs, setOpenModalUCs] = useState(false);
  const [openModalSettings, setOpenModalSettings] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  function toggleModalReport() {
    setOpenModalReport(!openModalReport);
  }

  function toggleModalUCs() {
    setOpenModalUCs(!openModalUCs);
  }

  function toggleModalSettings() {
    setOpenModalSettings(!openModalSettings);
  }

  const iconColor =
    localStorage.getItem("defaultTheme") === "light" ? "153d77" : "e9ecef";

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ float: "right", marginTop: "-100px" }}
    >
      <DatePicker
        className="inputs form-control cursor-pointer"
        selected={selectedMonthRef}
        onChange={(date) => {
          handleChangeDate(date);
        }}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        showFullMonthYearPicker
        placeholderText={t("solar.selectMonth")}
        locale={i18n.language === "br" ? ptBR : enGB}
        maxDate={new Date()}
        style={{
          backgroundImage: "none !important",
        }}
      />

      {adminUser &&
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle style={{ background: "transparent", border: "none" }}>
            <div id="searchByReport" className="cursor-pointer ml-2">
              <FontAwesomeIcon
                icon={faFilter}
                style={{ height: "18px", width: "18px", color: iconColor }}
                // onClick={() => setSearchReport(!searchReport)}
              />
              <UncontrolledTooltip placement="top" target="searchByReport">
                {t("devices.filter")}
              </UncontrolledTooltip>
            </div>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              style={{
                backgroundColor: (searchReport ? "lightgreen" : "transparent"),
              }}
              onClick={() => setSearchReport(!searchReport)}
            >
                {t('water.searchByDataConfig')}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      }
      
      <div id="waterreport" className="cursor-pointer ml-2">
        <FontAwesomeIcon
          icon={faFilePdf}
          style={{ height: "18px", width: "18px", color: iconColor }}
          onClick={toggleModalReport}
        />
        {openModalReport ? (
          <ModalWaterReport
            data={data}
            open={openModalReport}
            toggle={toggleModalReport}
          />
        ) : null}
        <UncontrolledTooltip placement="top" target="waterreport">
          {t("water.report")}
        </UncontrolledTooltip>
      </div>

      {adminUser && (
      <div id="waterUCs" className="cursor-pointer ml-2">
        <FontAwesomeIcon
          icon={faBuilding}
          style={{ height: "18px", width: "18px", color: iconColor }}
          onClick={toggleModalUCs}
        />
        {openModalUCs ? (
          <ModalWaterUCs open={openModalUCs} toggle={toggleModalUCs} />
        ) : null}
        <UncontrolledTooltip placement="top" target="waterUCs">
          {t("water.consumptionUnits")}
        </UncontrolledTooltip>
      </div>
      )}

      {adminUser && (
        <div id="waterconfig" className="cursor-pointer ml-2">
          <FontAwesomeIcon
            icon={faCog}
            style={{ height: "18px", width: "18px", color: iconColor }}
            onClick={toggleModalSettings}
          />
          <UncontrolledTooltip placement="top" target="waterconfig">
            {t("water.settings")}
          </UncontrolledTooltip>

          {openModalSettings ? (
            <ModalWaterSettings
              open={openModalSettings}
              toggle={toggleModalSettings}
              waterData={data}
              projectId={projectId}
              setLoading={setLoading}
            />
          ) : loading ? (
            <div className="text-center">
              <Spinner color="primary" />
            </div>
          ) : null}
        </div>
      )}

    </div>
  );
};

export default WaterTools;
