import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import '../../igCards';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card, CardBody, CardHeader, CardTitle,
  Form, FormGroup, Input, Label,
  Row, Col, Spinner
} from "reactstrap";
import axios from "axios";
import { toastr } from "react-redux-toastr";
// import ThermometerChart from "../cards/Thermometer";
import ThermometerChartPreview from "../cards/ThermometerPreview";
import DashboardGaugePreview from "../cards/DashboardGaugePreview";

const NewDashboardGauge = ({ cardType, cardProps }) => {
  const { t } = useTranslation();
// console.log(cardProps);
  const [isLoading, setLoading] = useState(true); // carregando projetos
  const [isEditLoading, setEditLoading] = useState(false); // salvando edição
  const [projList, setProjList] = useState([]); // lista de projetos
  const [dvcList, setDvcList] = useState([]); // lista de devices do projeto selecionado
  const [varList, setVarList] = useState([]); // lista de variaveis do device selecionado
  const [name, setName] = useState((cardProps?.id)? cardProps?.name : ""); // campo de nome
  const [description, setDescription] = useState((cardProps?.id)? cardProps?.description : "") // campo de descricao
  const [deviceId, setDeviceId] = useState((cardProps?.id)? cardProps?.devices : ""); // device selecionado
  const [variable, setVariable] = useState((cardProps?.id)? cardProps?.variables : ""); // variavel selecionada
  // const [variableListIfExists, setVariableListIfExists] = useState([]); // variavel selecionada
  const [reloadTime, setReloadTime] = useState((cardProps?.id)? cardProps?.reloadTime : ""); // periodo selecionado para reload
  const [minValue, setMinValue] = useState((cardProps?.id) ? cardProps?.minValueProp : 0);
  const [maxValue, setMaxValue] = useState((cardProps?.id) ? cardProps?.maxValueProp : 0);
  const [disableDecimalNumber, setDisableDecimalNumber] = useState();

  //AO EDITAR ESSES ESTADOS SÃO CARREGADOS
  const [editName, setEditName] = useState(cardProps?.name); // campo de nome
  const [editDescription, setEditDescription] = useState(cardProps?.description) // campo de descricao
  const [editDeviceId, setEditDeviceId] = useState(cardProps?.devices); // device selecionado
  const [editVariable, setEditVariable] = useState(cardProps?.variables); // variavel selecionada
  const [editReloadTime, setEditReloadTime] = useState(cardProps?.reloadTime); // periodo selecionado para reload
  const [editDecimalNumber, setEditDecimalNumber] = useState(cardProps?.decimalNumber);
  const [cardDataToEdit, setCardDataToEdit] = useState()
  let history = useHistory();
  // console.log(cardDataToEdit.description);
  // console.log(varList);
  //CHAMADAS PARA TRAZER CAMPOS PREENCHIDOS AO EDITAR

  useEffect(() => {
    if (cardProps?.id) {
      axios.get('/readDataOnEditCard', {
        params: {
          dvcId: cardProps.devices
        }
      })
        .then(res => {
          // console.log(res);
          setCardDataToEdit(res.data)
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar projetos.");  // t() da problema com o useEffect
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    axios.get('/projectUserSearch')
      .then(response => {
        setProjList(response.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        toastr.error('Erro!', "Erro ao buscar projetos.");  // t() da problema com o useEffect
      });
  }, []);

  function retrieveDataFromPreviewCard(data) {
    setDisableDecimalNumber(data)
  }

  function onProjectChange(projId) {
    setDvcList([]); // limpa lista de dispositivos
    setVarList([]); // limpa lista de variaveis
    setDeviceId(""); // limpa dispositivo selecionado
    setVariable(""); // limpa variavel selecionada

    setLoading(true);
    axios.get('/deviceSearch', {
      params: {
        projId: projId
      }
    })
      .then(response => {
        setDvcList(response.data) // atualiza lista de dispositivos
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        toastr.error('Erro!', "Erro ao buscar dispositivos.");  // t() da problema com o useEffect
        setLoading(false);
      })
  };

  //AO EDITAR CARD
  function onEditProjectChange(projId) {
    setDvcList([]); // limpa lista de dispositivos
    setVarList([]); // limpa lista de variaveis
    setEditDeviceId(""); // limpa dispositivo selecionado
    setEditVariable(""); // limpa variavel selecionada

    setLoading(true);
    axios.get('/deviceSearch', {
      params: {
        projId: projId
      }
    })
      .then(response => {
        setDvcList(response.data) // atualiza lista de dispositivos
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        toastr.error('Erro!', "Erro ao buscar dispositivos.");  // t() da problema com o useEffect
        setLoading(false);
      })
  };

  function onDeviceChange(dvcId) {
    setVarList([]); // limpa lista de variaveis
    setVariable(""); // limpa variavel selecionada

    setDeviceId(dvcId); // atualiza dispositivo selecionado
    setLoading(true);
    axios.get('/readVariables', {
      params: {
        id: dvcId
      }
    })
      .then(response => {
        // console.log(response);
        setVarList(response.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        toastr.error('Erro!', "Erro ao buscar variáveis.");  // t() da problema com o useEffect
        setLoading(false);
      });
  };

  //AO EDITAR CARD
  function onEditDeviceChange(dvcId) {
    setVarList([]); // limpa lista de variaveis
    setEditVariable(""); // limpa variavel selecionada

    setEditDeviceId(dvcId); // atualiza dispositivo selecionado
    setLoading(true);
    axios.get('/readVariables', {
      params: {
        id: dvcId
      }
    })
      .then(response => {
        setVarList(response.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        toastr.error('Erro!', "Erro ao buscar variáveis.");  // t() da problema com o useEffect
        setLoading(false);
      });
  };

  // function handAddNewVariable(selectedVariable) {
  //   // setVariable("")
  //   setVariableListIfExists([...variableListIfExists, JSON.parse(selectedVariable)])
  // }

  // function handleDeleteVariable(index) {
  //   setVariableListIfExists(variableListIfExists.filter((item, i) => index !== i))
  // }

  function onSubmitForm() {
    setEditLoading(true);
    setTimeout(() => {
      setEditLoading(false)
    }, 7000)
    if (name.length >= 50) {
      return toastr.warning("Cuidado!", "Título muito extenso.")
    }

    if (!deviceId || !variable) {
      toastr.warning('Erro!', 'Não foi selecionada variável.');
    }

    else {
      const whArr = [3, 6];
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        dashId: localStorage.getItem('dashId'),
        // idCard: (cardProps?.id) ? cardId : null,
        cardType: cardType,
        name: name,
        description: description,
        devices: deviceId,
        // variables: (variableListIfExists.length) ? JSON.stringify(variableListIfExists) : variable,
        variables: variable,
        period: null,
        reloadTime: reloadTime,
        w: whArr[0],
        h: whArr[1],
        rangeStructure: null,
        formula: null,
        decimalNumber: null,
        minValueProp: Number(minValue),
        maxValueProp: Number(maxValue)
      };
      // return console.log(jsonData)
      /*** Envio de dados ***/
      axios.post('/newCard', JSON.stringify(jsonData))
        .then(result => {
          toastr.success(result.data.messageHead, result.data.messageBody);
          history.push("../dashboardView")
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
          if (err.response.data) {
            /* Notificação de erro de envio */
            toastr.warning(
              "Teste",
              "Corpo teste"
            );
          }
        });
    }
  };

  // FUNÇÃO PARA SALVAR DADOS EDITADOS DO CARD
  async function handleUpdateCard(cardId) {
    setEditLoading(true);
    setTimeout(() => {
      setEditLoading(false)
    }, 7000)

    if (editName.length >= 50) {
      return toastr.warning("Cuidado!", "Título muito extenso.")
    }

    if (!deviceId || !variable) {
      toastr.warning('Erro!', 'Não foi selecionada variável.');
    }

    else {
      const whArr = [3, 6];
      /* Salvar dados do formulário no formato JSON */
      const jsonData = {
        dashId: localStorage.getItem('dashId'),
        idCard: cardId,
        cardType: cardType,
        name: name,
        description: description,
        devices: deviceId,
        // variables: (variableListIfExists.length) ? JSON.stringify(variableListIfExists) : variable,
        variables: variable,
        period: null,
        reloadTime: reloadTime,
        w: whArr[0],
        h: whArr[1],
        rangeStructure: null,
        formula: null,
        decimalNumber: null,
        minValueProp: Number(minValue),
        maxValueProp: Number(maxValue)
      };
      
      /*** Envio de dados ***/
      await axios.put('editCardV2/', JSON.stringify(jsonData))
        .then(result => {
          toastr.success(result.data.messageHead, result.data.messageBody);
          setEditLoading(false)
          history.push("../../dashboardView")
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
          setEditLoading(false);
          if (err.response.data) {

            /* Notificação de erro de envio */
            toastr.warning(
              err.response.data.messageHead,
              err.response.data.messageBody,
            );
            // console.log("if", isEditLoading);
          }
        });
    }
  };

  useEffect(() => {
    if (cardProps?.id && cardDataToEdit) {
      onEditProjectChange(cardDataToEdit.projId);
      onEditDeviceChange(cardProps?.devices)
    }
    // eslint-disable-next-line
  }, [cardDataToEdit])

  return <Row xs={1} xl={2}>
    <Col>
      <Card>
        <CardHeader>
          <CardTitle tag="h5">{t('dashboards.cardOptions')}</CardTitle>
        </CardHeader>

        <CardBody>
          <Form
            onSubmit={e => {
              e.preventDefault();
              onSubmitForm();
            }}
          >
            {(cardProps?.id) ?
              <>
                {/* Editar Nome */}
                <FormGroup>
                  <Label>
                    {t('dashboards.name')}
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={t('dashboards.name')}
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </FormGroup>

                {/* Editar Descrição */}
                <FormGroup>
                  <Label>
                    {t('dashboards.description')}
                  </Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    placeholder={t('dashboards.description')}
                    rows="3"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  />
                </FormGroup>

                {/* Editar Seleção de projeto */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.projects')}
                  </Label>
                  <Col lg={12} xl={9}>
                    {(cardDataToEdit) ?
                      <Input
                        type="select"
                        id="project"
                        required
                        defaultValue={(cardDataToEdit?.projId) ? cardDataToEdit.projId : ""}
                        onChange={e => onEditProjectChange(e.target.value)}
                        disabled={isLoading}
                      >
                        <option hidden value={(cardDataToEdit?.projId) ? cardDataToEdit.projId : ""}>{(cardDataToEdit) ? cardDataToEdit?.name : ""}</option>
                        {(projList.length)
                          ? projList.map(item => (
                            <option value={item.id} key={item.id}>{item.name}</option>
                          ))
                          : <option disabled>{t('dashboards.noProject')}</option>
                        }
                      </Input>
                      : null}
                  </Col>
                </FormGroup>

                {/* Editar Seleção de dispositivo */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.devices')}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      id="device"
                      required
                      value={deviceId}
                      onChange={e => onDeviceChange(e.target.value)}
                      disabled={!dvcList.length || isLoading}
                    >
                      <option hidden value="">{t('dashboards.pleaseDevice')}</option>
                      {dvcList.map(item => (
                        <option value={item.id} key={item.id}>
                          {item.description}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
                
                {/* Editar Seleção de variável */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.variables')}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      id="variablesSelect"
                      required
                      value={variable}
                      onChange={e => setVariable(e.target.value)}
                      disabled={!varList?.length || isLoading}
                    >
                      <option hidden value="">{t('dashboards.pleaseVariable')}</option>
                      {varList?.map(item => (
                        <option value={JSON.stringify(item)} key={item.tag}>
                          {item.varName}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>

                {/* Seleção de valor mínimo */}
                <FormGroup row className="mb-3 align-items-center">
                  <Label lg={12} xl={3}>
                    {t("Mínimo")}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="number"
                      value={minValue}
                      onChange={e => setMinValue(e.target.value)}
                    // disabled={(typeof (disableDecimalNumber) !== "number" ? true : false)}
                    >
                      {/* <option value="" disabled>Selecione</option>
                      <option value="7">Todas</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option> */}
                    </Input>
                  </Col>
                </FormGroup>
                
                {/* Editar Seleção de valor máximo */}
                <FormGroup row className="mb-3 align-items-center">
                  <Label lg={12} xl={3}>
                    {t("Máximo")}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="number"
                      value={maxValue}
                      required
                      onChange={e => setMaxValue(e.target.value)}
                      placeholder="Apenas Números"
                    // disabled={(typeof (disableDecimalNumber) !== "number" ? true : false)}
                    >
                      {/* <option value="" disabled>Selecione</option>
                      <option value="7">Todas</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option> */}
                    </Input>
                  </Col>
                </FormGroup>

                {/* Editar Seleção tempo Reload */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.refreshTime')}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      id="reloadTimeSelect"
                      required
                      value={reloadTime}
                      onChange={e => setReloadTime(e.target.value)}
                    >
                      <option hidden value="">{t('dashboards.pleaseRefresh')}</option>
                      <option value="30000">30 segundos</option>
                      <option value="60000">1 minuto</option>
                      <option value="120000">2 minutos</option>
                      <option value="180000">3 minutos</option>
                      <option value="240000">4 minutos</option>
                      <option value="300000">5 minutos</option>
                      <option value="900000">15 minutos</option>
                      <option value="1800000">30 minutos</option>

                    </Input>
                  </Col>
                </FormGroup>
              </>
              :
              <>
                {/* Nome */}
                <FormGroup>
                  <Label>
                    {t('dashboards.name')}
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder={t('dashboards.name')}
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </FormGroup>

                {/* Descrição */}
                <FormGroup>
                  <Label>
                    {t('dashboards.description')}
                  </Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    placeholder={t('dashboards.description')}
                    rows="3"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  />
                </FormGroup>

                {/* Seleção de projeto */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.projects')}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      id="project"
                      required
                      onChange={e => onProjectChange(e.target.value)}
                      disabled={isLoading}
                    >
                      <option hidden value="">{t('dashboards.pleaseProject')}</option>
                      {(projList.length)
                        ? projList.map(item => (
                          <option value={item.id} key={item.id}>{item.name}</option>
                        ))
                        : <option disabled>{t('dashboards.noProject')}</option>
                      }
                    </Input>
                  </Col>
                </FormGroup>

                {/* Seleção de dispositivo */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.devices')}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      id="device"
                      required
                      value={deviceId}
                      onChange={e => onDeviceChange(e.target.value)}
                      disabled={!dvcList.length || isLoading}
                    >
                      <option hidden value="">{t('dashboards.pleaseDevice')}</option>
                      {dvcList.map(item => (
                        <option value={item.id} key={item.id}>
                          {item.description}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
                
                {/* Seleção de variável */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.variables')}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      id="variablesSelect"
                      required
                      value={variable}
                      onChange={e => setVariable(e.target.value)}
                      disabled={!varList?.length || isLoading}
                    >
                      <option hidden value="">{t('dashboards.pleaseVariable')}</option>
                      {varList?.map(item => (
                        <option value={JSON.stringify(item)} key={item.tag}>
                          {item.varName}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  {/* <Col>
                    <Button onClick={() => handAddNewVariable(variable)} color="primary" type="button">Adicionar</Button>
                  </Col> */}
                </FormGroup>
                {/* {variableListIfExists?.map((item, index) => (
                  <FormGroup key={index} row>
                    {console.log('Esse EH O ITEM', item)}
                    <Label lg={12} xl={3}>
                      {t('dashboards.variables')} {++index}
                    </Label>
                    <Col lg={12} xl={6}>
                      <Input
                        type="select"
                        // id="variablesSelect"
                        // required={(variableListIfExists.length > 0) ? true : false}
                        value={item}
                        // onChange={e => setVariable(e.target.value)}
                        disabled
                      >
                        <option hidden value="">{t('dashboards.pleaseVariable')}</option>
                        <option key={index} value={item.varName}>
                          {item.varName}
                        </option>
                      </Input>
                    </Col>
                    <Col>
                      <Button onClick={() => handleDeleteVariable(index)} style={{ width: 83 }} color="danger" type="button">Remover</Button>
                    </Col>
                  </FormGroup>
                ))} */}

                {/* Seleção de valor mínimo */}
                <FormGroup row className="mb-3 align-items-center">
                  <Label lg={12} xl={3}>
                    {t("Mínimo")}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="number"
                      value={minValue}
                      onChange={e => setMinValue(e.target.value)}
                    // disabled={(typeof (disableDecimalNumber) !== "number" ? true : false)}
                    >
                      {/* <option value="" disabled>Selecione</option>
                      <option value="7">Todas</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option> */}
                    </Input>
                  </Col>
                </FormGroup>
                
                {/* Seleção de valor máximo */}
                <FormGroup row className="mb-3 align-items-center">
                  <Label lg={12} xl={3}>
                    {t("Máximo")}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="number"
                      value={maxValue}
                      onChange={e => setMaxValue(e.target.value)}
                    // disabled={(typeof (disableDecimalNumber) !== "number" ? true : false)}
                    >
                      {/* <option value="" disabled>Selecione</option>
                      <option value="7">Todas</option>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option> */}
                    </Input>
                  </Col>
                </FormGroup>

                {/* Seleção tempo Reload */}
                <FormGroup row>
                  <Label lg={12} xl={3}>
                    {t('dashboards.refreshTime')}
                  </Label>
                  <Col lg={12} xl={9}>
                    <Input
                      type="select"
                      id="reloadTimeSelect"
                      required
                      value={reloadTime}
                      onChange={e => setReloadTime(e.target.value)}
                    >
                      <option hidden value="">{t('dashboards.pleaseRefresh')}</option>
                      <option value="30000">30 segundos</option>
                      <option value="60000">1 minuto</option>
                      <option value="120000">2 minutos</option>
                      <option value="180000">3 minutos</option>
                      <option value="240000">4 minutos</option>
                      <option value="300000">5 minutos</option>
                      <option value="900000">15 minutos</option>
                      <option value="1800000">30 minutos</option>

                    </Input>
                  </Col>
                </FormGroup>
              </>
            }

            {/* Botão de salvar */}
            <div className="text-right">
              <Link to="/dashboardView">
                <Button
                  name="btnCancel"
                  color='primary'
                  className="mr-2"
                  outline
                >
                  {t('dashboards.cancel')}
                </Button>
              </Link>
              {(cardProps?.id) ?
                <Button
                  color="primary"
                  type="button"
                  disabled={isEditLoading}
                  style={{ minWidth: 80 }}
                  onClick={() => handleUpdateCard(cardProps.id)}
                >
                  {(isEditLoading === true) ? <Spinner size={"sm"} /> : t("Atualizar")}
                </Button>
                : <Button
                  disabled={isEditLoading}
                  style={{ minWidth: 80 }}
                  color="primary"
                  type="submit"
                >
                  {(isEditLoading === true) ? <Spinner size={"sm"} /> : t('dashboards.save')}
                </Button>
              }
            </div>
          </Form>
        </CardBody>
      </Card>
    </Col>

    <Col>
      <Card>
        {(cardType === "gaugeCard" ||
          cardProps?.type === "gaugeCard")
          ? <DashboardGaugePreview
            editName={editName}
            editDescription={editDescription}
            editDeviceId={editDeviceId}
            editVariable={editVariable}
            editReloadTime={editReloadTime}
            editDecimalNumber={editDecimalNumber}
            retrieveDataFromPreviewCard={retrieveDataFromPreviewCard}
            cardDataToEdit={cardDataToEdit}
            // decimalNumber={decimalNumber}
            cardType={cardType}
            cardProps={cardProps}
            name={name}
            description={description}
            variables={variable}
            deviceId={deviceId}
            setMinValue={setMinValue}
            minValue={minValue}
            maxValue={maxValue}
            preview={true}
          />
          : null
        }
      </Card>
    </Col>
  </Row>
}

export default NewDashboardGauge;