const enUsPlantTranslations = {
  subMenu: {
    selectMonth: "Select a Month",
    generateReport: "Generate Reports",
    configParams: "Configure Parameters",
    sharing: "Sharing",
    resetPlant: "Delete data",
    alarmHistory: "Alarms History",
    preferenceView: "Display on screen",
    deletePlant: "Delete all data of plant"
  },
  cards: {
    monthlyConsumption: "Active Consumption",
    monthlyConsumptionRe: "Reactive Consumption",
    monthlyDemandRe: "Reactive Demand",
    maxDemand: "Maximum Demand",
    powerFactor: "Power Factor",
    chargeFactor: "Charge Factor",
    monthlyBill: "Monthly Bill",
    distributor: "Distributor",
    contract: "Contract",
    peak: "Peak",
    offPeak: "Off Peak",
    avgInductive: "Average Inductive",
    avgCapacitive: "Average Capacitive",
    totalBill: "Total",
    distributorName: "Name",
    flag: "Flag",
    peakContractedDemand: "Peak Demand",
    offPeakContractedDemand: "Off Peak Demand",
    systemUsageDist: "System Usage Distribution",
    tusdConsumption: "TUSD Consumption",
    teConsumption: "TE Consumption",
  },
  calculationBase: {
    title: "Base for Calculation",
    monthlyBill: "Monthly Bill",
    peakConsumption: "Peak Consumption",
    offPeakConsumption: "Off-Peak Consumption",
    systemUsageDistOffPeak: "System Usage Distribution Peak",
    systemUsageDistPeak: "System Usage Distribution Off-Peak",
    peakContractedDemand: "Peak Contracted Demand",
    offPeakContractedDemand: "Off-Peak Contracted Demand",
    tusdPeakConsumption: "TUSD Peak Consumption",
    tusdOffPeakConsumption: "TUSD Off-Peak Consumption",
    tePeakConsumption: "TE Peak Consumption",
    teOffPeakConsumption: "TE Off-Peak Consumption",
  },
  charts: {
    chartConsumption: "Consumption (kWh)",
    chartReactiveConsumed: "Reactive Consumed (kVAr)",
    chartDemand: "Demand (kW)",
    chartPowerFactor: "Power Factor",
    chartMonthlyConsumption: "Monthly Consumption (%)",
    peak: "Peak",
    offPeak: "Off Peak",
    contractPeak: "Contracted Peak (line)",
    contractOffPeak: "Contracted Off Peak (line)",
    inductive: "Inductive",
    capacitive: "Capacitive",
    month: "Month",
    lastDays: "Last 7 days",
    today: "Today",
    noData: "No data available",
    total: "Total",
  },
  consumerUnit: {
    cancel: "Cancel",
    save: "Save",
    edit: "Edit",
    ucName: "Name",
    status: "Status",
    noData: "No data available for the selected month"
  },
  params: {
    register: "Register plant parameters",
    editPlant: "Edit plant parameters",
    editRefMeter: "Edit Reference Meter parameters",
    editUc: "Edit Consumption Unit parameters",
    market: "Market",
    distributor: "Distributor",
    tariffModality: "Tariff Type",
    subgroup: "Subgroup",
    refMeter: "Reference Meter",
    select: "Select",
    refName: "Reference Name",
    noDevices: "No registered devices",
    contractPeak: "Peak Contract Demand (kW)",
    contractOffPeak: "Off-Peak Contract Demand (kW)",
    taxRateIcms: "ICMS Tax Rate (%)",
    taxRatePisPasep: "PIS/PASEP Tax Rate (%)",
    taxRateCofins: "COFINS Tax Rate (%)",
    save: "Save",
    create: "Create",
    cancel: "Cancel",
    edit: "Edit",
    add: "Add",
    delete: "Delete",
    meters: "Other Meters",
    preferences: "Preferences",
    unitConsumers: "Consumption Units",
    tariff: "Tariffs",
    contract: "Contract",
    devices: "Devices",
    actions: "Actions",
    model: "Model",
    structure: "Structure",
    variables: "Variables",
    peakOffPeak: "Peak and Off-Peak",
    kWhActiveConsumed: "Active Consumption",
    kWhReativeConsumed: "Reactive Consumption",
    kWhReativeConsumedExtended: "Extended Reactive Consumption",
    demandAt: "Active Demand",
    demandRe: "Reactive Demand",
    postoReat: "Reactive Post",
    fpCap: "Capacitive Power Factor",
    fpInd: "Inductive Power Factor",
    selectDevice: "Select the device",
    selectVariable: "Select a variable",
    selectUc: "Select Consumption Unit",
    selectRefMeter: "Select Reference Meter"
  },
  preferences: {
    consumption: "Consumption",
    carbonEmission: "Carbon Emission",
    demand: "Demand",
    devicesInfo: "Device Status/Map",
    listMeters: "Meter List",
    save: "Save screen preferences",
    title: "Overview",
    powerFactor: "Power Factor",
  },
  reportsEnergy: {
    title: "Reports - Energy Module",
    sendEmail: "Send by Email",
    sendEmailText: "Enter the desired email and click the + button",
    sendEmailEditText: "Edit the email and click the Confirm button",
    inputPlaceholderEmail: "example@email.com",
    actions: "Actions",
    closeButton: "Close",
    sendButton: "Send",
    edit: "Edit",
    delete: "Remove",
    bill: "Monthly Bill",
    errors: {
      invalidEmail: {
        title: "Attention",
        msg: "Invalid email format",
      },
      emailAdded: {
        title: "Attention",
        msg: "Email already added",
      },
      emptyEmail: {
        title: "Attention",
        msg: "No emails to be sent",
      },
    },
  },
  reportBill: {
    title: "Monthly Bill - Energy Module",
    ucNumber: "UC Number",
    customerTitle: "Customer (Consumption Unit)",
    customerAddress: "Address",
    customerNeighborhood: "Neighborhood",
    customerZipCode: "ZIP Code",
    tariffType: "Tariff Type",
    subgroup: "Subgroup",
    peakContractedDemand: "Contracted Peak Demand (kW)",
    offPeakContractedDemand: "Contracted Off Peak Demand (kW)",
    emission: "Emission",
    billedDays: "Number of Billed Days",
    device: "Device",
    maxPeakDemand: "Max Peak Demand (kW)",
    maxOffPeakDemand: "Max Off-Peak Demand (kW)",
    exceededPeakDemand: "Exceeded Peak Demand (Hour/Day)",
    exceededOffPeakDemand: "Exceeded Off-Peak Demand (Hour/Day)",
    product: "Product",
    const: "Const.",
    previousReading: "Previous Reading",
    actualReading: "Actual Reading",
    quantity: "Quantity",
    peakDemand: "Peak Demand",
    offPeakDemand: "Off-Peak Demand",
    peakConsumption: "Peak Consumption",
    offPeakConsumption: "Off-Peak Consumption",
    systemUsageDistOffPeak: "System Usage Dist. Off-Peak",
    systemUsageDistPeak: "System Usage Dist. Peak",
    dataReading: "Reading Data",
    description: "Description",
    price: "Price",
    value: "Value",
    tusdPeakConsumption: "TUSD ENERGY PEAK (kWh)",
    tusdOffPeakConsumption: "TUSD ENERGY OFF-PEAK (kWh)",
    tePeakConsumption: "TE ENERGY PEAK (kWh)",
    teOffPeakConsumption: "TE ENERGY OFF-PEAK (kWh)",
    systemUsageDistPeakKw: "SYSTEM USAGE DIST. PEAK (kW)",
    systemUsageDistOffPeakkw: "SYSTEM USAGE DIST. OFF-PEAK (kW)",
    subtotal: "SUBTOTAL",
    barChartDemand: "Demand History (kW)",
    barChartConsumption: "Consumption History (kWh)",
    base: "Calculation Base",
    taxRate: "Tax Rate (%)",
    taxRateIcms: "ICMS",
    taxRatePisPasep: "PIS/PASEP",
    taxRateCofins: "COFINS",
    refMonthData: "Ref. Month",
    total: "Total",
    date: "Electricity Bill for the month of",
    generate: "Generated by IoT Igoal",
    noData: "No Data",
    totalBill: "Amount to pay",
    localeString: "en-us",
    currency: "$",
    sendReportBillSendError: {
      title: "Monthly Bill",
      msg: "Error sending",
    },
    sendReportBillSendSuccess: {
      title: "Monthly Bill",
      msg: "Sent successfully",
    },
  },
  homePlant: {
    id: "#",
    description: "Description",
    totalPeakConsumption: "Total peak consumption",
    totalOffPeakConsumption: "Total off peak consumption",
    totalConsumption: "Total",
    device: "Device",
    refMeter: "Reference meter",
    refName: "Reference name",
    status: "Status",
    actions: "Actions",
    none: "No Data",
    comeBack: "Voltar",
    search: "Search by name",
    deviceId: "Device",
    active: "Status",
    deviceWithAlarm: "With Alarm",
    deviceWithoutAlarm: "Without Alarm",
    deviceNeverCommunicated: "Without communication",
    connectivity: "Connectivity",   
    valueByRefMeter: "Value by reference meter",
    valueByConsumerUnit: "Value by consumer unit",
    meterTableList: "Meters List",
    view: "View",
    addMeter: "Add Meter",
    deleteMeter: "Delete meter",
    attention: "Attention",
    captchaLabel: "This action is irreversible. If you wish to proceed, please type 'DELETE' in the field below:",
    cancel: "Cancel",
    newMeter: "Add new meter",
    save: "Save",
    ucTableList: "Consumer Unit List",
    totalCarbonEmission: "Total Carbon Emissions",
    compensation: "Compensation",
    carbonEmission: "Carbon Emissions",
    treesNeededForOffset: "Trees Needed for Offset",
    mapUcs: "Map of Meters",
    statusDevice: "Device Status",
  },
  noParamsPlant: {
    msg: 'There are no registered data on the selected date',
    register: 'Register'
  },
  noParamsRefMeter: {
    msg: 'No parameters registered',
    register: 'Register'
  },
  headerPlant: {
    overview: 'Overview',
    refMeter: 'Reference Meter',
    consumerUnit: 'Consumer Unit',
    consumptionSharing: 'Consumption Sharing'
  }
};  

export default enUsPlantTranslations;
