import React, {useState, useEffect} from "react";
import { Card, CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useTranslation } from 'react-i18next';
import {listAlarmsHistory} from "../AlarmService";

import { PriorityBadge } from "./components/PriorityBadge";
import { SortIcon } from "./components/SortIcon";
import { PaginationOptions } from "./components/PaginationOptions";
import { AlarmTurnOffIcon } from "./components/AlarmTurnOffIcon";

export const ModalAlarmHistory = ({open, toggle, devices}) => {

  const { t } = useTranslation();

  const [alarms, setAlarms] = useState([]);

  const getAlarms = async (devices) => {
    let response = await listAlarmsHistory(devices, alarms.length, 30);
    setAlarms(response);
  };

  useEffect(() => {
    if(devices) {
      getAlarms(devices);
    }
  }, [devices]);

   /* NOTE: Colunas da tabela */
   const alarmCols = [
    { // NOTE: ID (oculto)
      dataField: "id",
      text: `ID`,
      sort: true,
      hidden: true,
      isKey: true
    },
    { // NOTE: Nome do alarme
      dataField: "alarmName",
      text: t('alarms.alarm'),
      sort: true,
    },
    { // NOTE: Nome do dispositivo
      dataField: "deviceName",
      text: t('alarms.deviceName'),
      sort: true,
    },
    { // NOTE: Variável
      dataField: "varName",
      text: t('alarms.varName'),
      formatter: cell => (cell === null) 
        ? '-' 
        : (cell.length > 20)
          ? cell.slice(0,17) + "..."
          : cell
    },
    { // NOTE: tipo
      dataField: "alarmType",
      text: t('alarms.type'),
      formatter: (cell) => (cell) 
        ? 'D' 
        : 'A'
    },
    { // NOTE: valor/unidade
      dataField: "value",
      text: t('alarms.value'),
      headerStyle: { width: "60px" },
      formatter: (cell, row) => cell + ((row.varUnit) ? ' ' + row.varUnit : null)
    },
    { // NOTE: prioridade
      dataField: "priority",
      text: t('alarms.priority'),
      formatter: cell => <PriorityBadge priority={cell} />
    },
    { // NOTE: data de ocorrencia
      dataField: "dateOccurred",
      text: t('alarms.dateOcc'),
      sort: true,
      formatter: cell => {
        if (cell) {
          var date = new Date(cell*1000);

          return ("0"+date.getDate()).slice(-2)
            + '/' + ("0"+(date.getMonth()+1)).slice(-2)
            + '/' + date.getFullYear()
            + ' ' + ("0"+date.getHours()).slice(-2)
            + ':' + ("0"+date.getMinutes()).slice(-2)
            + ':' + ("0"+date.getSeconds()).slice(-2);
        } else return '-'
      }
    },
    { // NOTE: data de finalização
      dataField: "dateFinished",
      text: t('alarms.dateOff'),
      sort: true,
      formatter: cell => {
        if (cell) {
          var date = new Date(cell*1000);

          return ("0"+date.getDate()).slice(-2)
            + '/' + ("0"+(date.getMonth()+1)).slice(-2)
            + '/' + date.getFullYear()
            + ' ' + ("0"+date.getHours()).slice(-2)
            + ':' + ("0"+date.getMinutes()).slice(-2)
            + ':' + ("0"+date.getSeconds()).slice(-2);
        } else return '-'
      }
    },
    { // NOTE: mensagem ON
      dataField: "messageOn",
      text: t('alarms.onMsg'),
      formatter: cell => (cell === null) 
        ? '-' 
        : (cell.length > 20)
          ? cell.slice(0,17) + "..."
          : cell
    },
    { // NOTE: mensagem OFF
      dataField: "messageOff",
      text: t('alarms.offMsg'),
      formatter: (cell, row) => (cell === null || !row.dateFinished) 
        ? '-' 
        : (cell.length > 20)
          ? cell.slice(0,17) + "..."
          : cell
    },
    { // NOTE: desligar alarme
      dataField: "actions",
      text: t('alarms.actions'),
      headerStyle: { width: "60px" },
      formatter: (_, row) => (
        <AlarmTurnOffIcon
          className="text-center"
          alarm={row}
        />
      )
    }
  ];

    
    return (

      <Modal isOpen={open} toggle={toggle} size='xl'>
        <ModalHeader toggle={toggle}>
          {t('subMenu.alarmHistory')}
        </ModalHeader>
        <ModalBody className="overflow-auto">
          <Card className="m-2 card-img-hover-translateY">
            <CardBody>
              <div className="table-responsive">
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  columns={alarmCols}
                  condensed
                  data={alarms}
                  bordered={false}
                  striped={true}
                  sort={SortIcon}
                  pagination={paginationFactory(PaginationOptions)}
                />
              </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    
    );
};
