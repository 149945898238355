import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarehouse } from "@fortawesome/free-solid-svg-icons";

const InstalledCapacity = ({ capacity, loading, loadingModal }) => {
  const { t } = useTranslation();
            
  return (
    <Row style={{minWidth: "147px"}}>
      <Col sm="12" style={{paddingRight:"0px"}}>
        <Card style={{maxHeight:"117px", height: "117px"}}>
          <CardBody style={{padding: "15px"}}>
            <Row>
              <Col className="mt-0">
                <CardTitle tag="h5">{t('solar.powerCard')}<br></br>{t('solar.capacity')}</CardTitle>
              </Col>
              <Col className="col-auto" style={{paddingLeft:"0px"}}>
                <div style={{width:"26px",height:"26px"}}>
                  <div className="avatar-title rounded-circle bg-primary-dark">
                  <FontAwesomeIcon 
                      icon={faWarehouse} 
                      style={{fontSize:"12px"}}
                      />
                  </div>
                </div>
              </Col>
            </Row>
            {(loadingModal)
              ? <div className="text-center mb-4"><Spinner /></div>
              : (loading)
                ? <div className="text-center mb-4"> <Spinner color="primary"/></div>
                : (capacity)
                  ? <h1 className="display-5 text-center" style={{fontSize:"17px", fontWeight:"500"}}>{capacity} kWp</h1>
                  : <p className="text-center">Não há dados</p>
            }
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}   

export default InstalledCapacity;