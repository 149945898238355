import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';

export const WaterTable = ({ data }) => {

  const {t} = useTranslation();
  const columns = [
    {
      dataField: 'waterucs_description',
      text: t('water.consumptionUnit'),
    },
    {
      dataField: 'registerNo',
      text: t('water.meter'),
    },
    {
      dataField: 'combinedReadings',
      text: t('water.previousValue'),
      formatter: combinePrevReadingsFormatter,
    },
    {
      dataField: 'leituraAtual.consumption',
      text: t('water.currentValue'),
      formatter: combineReadingsFormatter
    },
  ];

  // Função para combinar leitura anterior e leitura inicial
  function combinePrevReadingsFormatter(cell, row) {
    const leituraAnterior = row.leituraAnterior[0]?.consumption || 0;
    const leituraInicial = row.leituraInicial || 0;
    const gain = row.gain || 1;
    
    return (
      <div>
        <b>{row.leituraAnterior[0].timestamp}</b>
        <br />
        {((leituraAnterior * gain) + leituraInicial).toFixed(2)}
      </div>
    ); 

   }

  function combineReadingsFormatter(cell, row) {
    const leituraAnterior = row.leituraAtual?.consumption || row.leituraAnterior[0]?.consumption || 0;
    const leituraInicial = row.leituraInicial || 0;
    const gain = row.gain || 1;

    return (
      <div>
        <b>{row.leituraAtual?.timestamp || row.leituraAnterior[0].timestamp}</b>
        <br />
        {((leituraAnterior * gain) + leituraInicial).toFixed(2)}
      </div>
    );
  }

  return (
    <BootstrapTable
      keyField="ucId"
      data={data}
      columns={columns}
    />
  );
};
