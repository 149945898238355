const ptBrHomeTranslations = {
    homePage: {
        title: "Página Inicial",
        waterVision: "Visão Geral do Módulo Água",
        water: "Água",
        pvWater: "Planta de Água",
        totalConsumption: "Consumo Total",
        peak: "Consumo Ponta",
        offPeak: "Consumo Fora Ponta",
        status: "Status",
        tooltipOff: "Falha de comunicação em todos os dispositivos",
        tooltipOn: "Todos os dispositivos estão comunicando",
        tooltipWarning: "Falha de comunicação em um ou mais dispositivos",
        noDataPlants: "Não há plantas do tipo Água",
    },
    menuCard: {
        myAccount: "Minha conta",
        sharing: "Rateio de Consumo"
    },
    public: {
        expand: "Expandir",
        lastComm: "Última comunicação",
        deviceModel: "Modelo",
        deviceStatus: "Status",
    },
}

export default ptBrHomeTranslations;