import React, { useEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const ReportChart = ({
  projectId,
  opacity,
  monthlyReportUsage,
  monthlyDataUsage
}) => {


  useEffect(() => {
    if (monthlyReportUsage) {
      let root = am5.Root.new("chartdiv" + projectId);

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          layout: root.verticalLayout
        }));

      // Create Y-axis
      let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: "monthRef",
        renderer: am5xy.AxisRendererY.new(root, {
          cellStartLocation: 0.1,
          cellEndLocation: 0.9
        })
      }));

      yAxis.data.setAll(monthlyReportUsage)

      // Create X-Axis
      let xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererX.new(root, {}),
          min: 0,
          strictMinMax: true

        }));
      xAxis.data.setAll(monthlyReportUsage)

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      function createSeries(field, name) {
        var series = chart.series.push(am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: field,
          categoryYField: "monthRef",
          sequencedInterpolation: true,
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "[bold]{name}[/]\n{categoryY}: {valueX}"
          })

        }));
        series.columns.template.setAll({
          height: am5.p100
        });

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationX: 1,
            locationY: 0.5,
            sprite: am5.Label.new(root, {
              centerX: am5.p100,
              centerY: am5.p50,
              text: "{valueX} kWh",
              fill: am5.color(0xffffff),
              populateText: true
            })
          });
        });

        series.data.setAll(monthlyReportUsage);
        series.appear();

        return series;
      }
      createSeries("projectedValue", "Mensal Projetada")
      createSeries("monthlyEnergy", "Mensal Gerada")

      let legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.percent(30),
        x: am5.percent(50),
      }));
      legend.data.setAll(chart.series.values);

      root._logo.dispose()

      return () => {
        root.dispose();
      };
    }

    // eslint-disable-next-line
  }, [monthlyDataUsage, monthlyReportUsage]);

  return <div id={"chartdiv" + projectId} style={{
    width: "100%",
    height: "90%",
    position: "absolute",
    opacity: opacity,
  }}></div>

}

export default ReportChart;