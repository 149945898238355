import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ModuleHeader = ({icon, title, value}) => {
  return (
    <div className="d-flex flex-row align-items-center text-white">
      <FontAwesomeIcon
        icon={icon}
        fixedWidth
      />
      <h5 className="ml-1 mr-4 my-0 py-0 text-white">{title}: {value}</h5>
    </div>
  )

};

export default ModuleHeader;