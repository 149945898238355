const ptBrPlantTranslations = {
    subMenu: {
        selectMonth: "Selecione um mês",
        generateReport: "Gerar Relatórios",
        configParams: "Configurar parâmetros",
        resetPlant: "Deletar dados",
        sharing: "Rateio",
        alarmHistory: "Histórico de Alarmes",
        preferenceView: "Exibir em tela",
        deletePlant: "Deletar os dados da planta"
    },
    cards: {
        monthlyConsumption: "Consumo Ativo",
        monthlyConsumptionRe: "Consumo Reativo",
        monthlyDemandRe: "Demanda Reativa",
        maxDemand: "Demanda Máxima",
        powerFactor: "Fator de Potência",
        chargeFactor: "Fator de Carga",
        monthlyBill: "Fatura Mensal",
        distributor: "Distribuidora",
        contract: "Contrato",
        peak: "Ponta",
        offPeak: "Fora Ponta",
        avgInductive: "Média Indutivo",
        avgCapacitive: "Média Capacitivo",
        totalBill: "Total",
        distributorName: "Nome",
        flag: "Bandeira",
        peakContractedDemand: "Demanda Ponta",
        offPeakContractedDemand: "Demanda Fora Ponta",
        systemUsageDist: "Uso do Sistema Distribuído",
        tusdConsumption: "TUSD Energia",
        teConsumption: "TE Energia"
    },
    calculationBase: {
        title: "Base para Cálculo",
        monthlyBill: "Fatura Mensal",
        peakConsumption: "Consumo Ponta",
        offPeakConsumption: "Consumo Fora Ponta",
        systemUsageDistOffPeak: "Uso Sist. Dist. Fora Ponta",
        systemUsageDistPeak: "Uso Sist. Dist. Ponta",
        peakContractedDemand: "Demanda Contratada Ponta",
        offPeakContractedDemand: "Demanda Contratada Fora Ponta",
        tusdPeakConsumption: "TUSD Energia Ponta",
        tusdOffPeakConsumption: "TUSD Energia Fora Ponta",
        tePeakConsumption: "TE Energia Ponta",
        teOffPeakConsumption: "TE Energia Fora Ponta",
    },
    charts: {
        chartConsumption: "Consumo (kWh)",
        chartReactiveConsumed: "Consumo Reativo (kVAr)",
        chartDemand: "Demanda (kW)",
        chartPowerFactor: "Fator de Potência",
        chartMonthlyConsumption: "Consumo Mensal (%)",
        peak: "Ponta",
        offPeak: "Fora Ponta",
        contractPeak: "Contratada Ponta (Linha)",
        contractOffPeak: "Contratada Fora Ponta (Linha)",
        inductive: "Indutivo",
        capacitive: "Capacitivo",
        month: "Mês",
        lastDays: "Últimos 7 dias",
        today: "Hoje",
        noData: "Não há dados",
        total: "Total",
    },
    consumerUnit: {
        cancel: "Cancelar",
        save: "Salvar",
        edit: "Editar",
        ucName: "Nome",
        status: "Status",
        noData: "Sem dados para o mês selecionado"
    },
    params: {
        register: "Cadastrar parâmetros da planta",
        editPlant: "Editar parâmetros da planta",
        editRefMeter: "Editar parâmetros do Medidor de Referência",
        editUc: "Editar parâmetros da Unidade de Consumo",
        market: "Mercado",
        distributor: "Distribuidora",
        tariffModality: "Tipo de Tarifa",
        subgroup: "Subgrupo",
        refMeter: "Medidor de Referência",
        select: "Selecione",
        refName: "Nome de Referência",
        noDevices: "Não há dispositivos cadastrados",
        contractPeak: "Demanda Contratada Ponta (kW)",
        contractOffPeak: "Demanda Contratada Fora Ponta (kW)",
        taxRateIcms: "Alíquota ICMS (%)",
        taxRatePisPasep: "Alíquota PIS/PASEP (%)",
        taxRateCofins: "Alíquota COFINS (%)",
        save: "Salvar",
        create: "Cadastrar",
        cancel: "Cancelar",
        edit: "Editar",
        add: "Adicionar",
        delete: "Deletar",
        meters: "Outros Medidores",
        preferences: "Preferências",
        unitConsumers: "Unidades de Consumo",
        tariff: "Tarifas",
        contract: "Contrato",
        devices: "Dispositivos",
        actions: "Ações",
        model: "Modelo",
        structure: "Estrutura",
        variables: "Variáveis",
        peakOffPeak: "Ponta e Fora ponta",
        kWhActiveConsumed: "Energia Ativa Consumida",
        kWhReativeConsumed: "Posto Reativo Quadrante 4",
        kWhReativeConsumedExtended: "Energia Reativa - Quadrante 4",
        demandAt: "Demanda Ativa",
        demandRe: "Demanda Reativa",
        postoReat: "Posto Reativo",
        kVA_Re_Q4: "Energia Reativa - Quadrante 4",
        kWhActiveGenerate: "Energia Ativa Gerada",
        powerFactor: "Fator de Potência",
        pAtC: "Pulsos de Energia Ativa",
        fpCap: "Pulsos Capacitivos",
        fpInd: "Pulsos Indutivos",
        selectDevice: "Selecione o dispositivo",
        selectVariable: "Não utilizado",
        selectUc: "Selecionar Unidade de Consumo",
        selectRefMeter: "Selecionar Medidor de Referência"
    },
    preferences: {
        consumption: "Consumo",
        carbonEmission: "Emissão de Carbono",
        demand: "Demanda",
        devicesInfo: "Status/Mapa dos dispositivos",
        listMeters: "Lista de medidores",
        save: "Salvar preferências de tela",
        title: "Visão Geral",
        powerFactor: "Fator de Potência",
    },
    reportsEnergy: {
        title: "Relatórios - Módulo Energia",
        sendEmail: "Enviar por e-mail",
        sendEmailText: "Digite o e-mail desejado e clique no botão +",
        sendEmailEditText: "Edite o e-mail e clique no botão Confirmar",
        inputPlaceholderEmail: "exemplo@email.com",
        actions: "Ações",
        closeButton: "Fechar",
        sendButton: "Enviar",
        edit: "Editar",
        delete: "Remover",
        bill: "Fatura Mensal",
        errors: {
            invalidEmail: {
              title: "Atenção",
              msg: "Formato de e-mail inválido"
            },
            emailAdded: {
              title: "Atenção",
              msg: "E-mail já adicionado"
            },
            emptyEmail: {
              title: "Atenção",
              msg: "Não há e-mails para ser enviado"
            },
          }
    },
    reportBill: {
        title: "Fatura Mensal - Módulo de Energia",
        ucNumber: "Número da UC",
        customerTitle: "Cliente (Unidade Consumidora)",
        customerAddress: "Endereço",
        customerNeighborhood: "Bairro",
        customerZipCode: "CEP",
        tariffType: "Tipo de Tarifa",
        subgroup: "Subgrupo",
        peakContractedDemand: "Demanda Contratada Ponta (kW)",
        offPeakContractedDemand: "Demanda Contratada Fora ponta (kW)",
        emission: "Emissão",
        billedDays: "Número de Dias faturado",
        device: "Equipamento",
        maxPeakDemand: "Demanda Máx. Ponta (kW)",
        maxOffPeakDemand: "Demanda Máx. Fora Ponta (kW)",
        exceededPeakDemand: "Demanda Ultrap. Ponta (Hora/Dia)",
        exceededOffPeakDemand: "Demanda Ultrap. Fora Ponta (Hora/Dia)",
        product: "Produto",
        const: "Const.",
        previousReading: "Leit. Ant.",
        actualReading: "Leit. Atual",
        quantity: "Quantidade",
        peakDemand: "Demanda Pta",
        offPeakDemand: "Demanda F.Pta",
        peakConsumption: "Consumo Ponta",
        offPeakConsumption: "Consumo Fora Ponta",
        systemUsageDistOffPeak: "Uso do Sistema Dist. Pta",
        systemUsageDistPeak: "Uso do Sistema Dist. F. Pta",
        dataReading: "Dados Leitura",
        description: "Descrição",
        price: "Preço",
        value: "Valor",
        tusdPeakConsumption: "TUSD ENERGIA PONTA (kWh)",
        tusdOffPeakConsumption: "TUSD ENERGIA F. PONTA (kWh)",
        tePeakConsumption: "TE ENERGIA PONTA (kWh)",
        teOffPeakConsumption: "TE ENERGIA F. PONTA (kWh)",
        systemUsageDistPeakKw: "USO SISTEMA DISTR. PTA (kW)",
        systemUsageDistOffPeakkw: "USO SISTEMA DISTR. F. PTA (kW)",
        subtotal: "SUBTOTAL",
        barChartDemand: "Histórico de Demanda (kW)",
        barChartConsumption: "Histórico de Consumo (kWh)",
        base: "Base de Cálculo",
        taxRate: "Aliquota (%)",
        taxRateIcms: "ICMS",
        taxRatePisPasep: "PIS/PASEP",
        taxRateCofins: "COFINS",
        refMonthData: "Mês Ref.",
        total: "Total",
        date: "Fatura de Energia Elétrica do mês de",
        generate: "Solução desenvolvida por IoT Igoal",
        noData: "Não informado",
        totalBill: "Valor a pagar",
        localeString: "pt-br",
        currency: "R$",
        sendReportBillSendError: {
            title: "Fatura Mensal",
            msg: "Erro ao enviar"
        },
        sendReportBillSendSuccess: {
            title: "Fatura Mensal",
            msg: "Enviado com sucesso"
        } 
    },
    homePlant: {
        id: '#',
        description: "Descrição",
        totalPeakConsumption: "Consumo Total Ponta",
        totalOffPeakConsumption: "Consumo Total Fora Ponta",
        totalConsumption: "Consumo Total",
        device: "Dispositivo",
        refMeter: "Referência",
        status: "Status",
        actions: "Ações",
        none: "Sem dados",
        comeBack: "Voltar",
        search: "Pesquisar por nome",
        deviceId: "Device",
        active: "Status",
        deviceWithAlarm: "Em Alarme",
        deviceWithoutAlarm: "Normal",
        deviceNeverCommunicated: "Sem Comunicação",
        connectivity: "Conectividade",
        valueByRefMeter: "Valor do medidor de referência",
        valueByConsumerUnit: "Valor da unidade de consumo",
        meterTableList: "Lista de medidores",
        view: "Visualizar",
        addMeter: "Adicionar Medidor",
        deleteMeter: "Deletar medidor",
        attention: "Atenção",
        captchaLabel: "Essa ação é irreversível. Caso deseje continuar, por favor, digite 'DELETE' no campo abaixo:",
        cancel: "Cancelar",
        newMeter: "Adicionar novo medidor",
        save: "Salvar",
        ucTableList: "Lista de unidades consumidoras",
        totalCarbonEmission: "Emissão total de Carbono",
        compensation: "Compensação",
        carbonEmission: "Emissão de Carbono",
        treesNeededForOffset: "Árvores Necessárias a Compensação",
        mapUcs: "Mapa dos medidores",
        statusDevice: "Status do Dispositivo",
    },
    noParamsPlant: {
        msg: 'Não há parâmetros do tipo Energia.',
        register: 'Cadastrar'
    },
    noParamsRefMeter: {
        msg: 'Nenhum parâmetro cadastrado',
        register: 'Cadastrar'
    },
    headerPlant: {
        overview: 'Visão geral da planta',
        refMeter: 'Medidor de Referência',
        consumerUnit: 'Unidade de consumo',
        consumptionSharing: 'Rateio de Consumo'
      }
}

export default ptBrPlantTranslations;