const ptBrNocTranslations = {
  noc: {
    header: {
      title: "Network Operations Center",
      cardTitle: "Registros de Autorizações de Serviços",
      createModalTitle: "Cadastrar Autorizações de Serviços",
      editModalTitle: "Editar Autorizações de Serviços",
      updateModalTitle: "Atualizar Autorizações de Serviços",
      commercialManagement: "Gerenciamento Comercial",
      titleSA: "Autorizações de Serviços",
    },
    main: {
      pageView: "Visualizar página com visão de:",
      headerCards: {
        connect: "Conectividade",
        devices: "Dispositivos",
        projects: "Projetos",
        modules: "Módulos",
        alarms: "Alarmes",
        ucs: "Unidades Consumidoras",
      },
      accordion: {
        healthyPlants: "Plantas saudáveis",
        unhealthyPlants: "Plantas em atenção",
        neverCommunicated: "Nunca comunicados",
      },
    },
    labels: {
      online: "Online",
      offline: "Inativo",
      noCommunication: "Sem Comunicação",
      solar: "Solar",
      power: "Enegia",
      water: "Água",
      gas: "Gás",
      climate: "Clima",
      noActiveModules: "Sem Módulos Ativos",
      highPriority: "Prioridade Alta",
      mediumPriority: "Prioridade Média",
      lowPriority: "Prioridade Baixa",
      overview: "Visão Geral",
    },
    modal: {
      processAt: "Processado em",
      generalConsumptionsUcs: "Consumos Gerais das UCs",
      monthRef: "Mês de Referência",
      solar: {
        dailyEnergy: "Energia Diária",
        monthlyEnergy: "Energia Mensal",
        lifetimeEnergy: "Energia Acumulada",
        currentPower: "Potência Atual",
        installedPower: "Potência Instalada",
        monthlyEconomy: "Economia Mensal",
        avoidedEmissions: "Emissões Evitadas",
      },
      water: {
        meteringConsumption: "Consumo Medido",
        commonAreaConsumption: "Consumo Área Comum",
        dealerConsumption: "Consumo Concessionária",
        consumption: "Consumo",
      },
      power: {
        totalOffPeakDemandReactive: "Demanda Reativa Total Fora Ponta",
        totalPeakDemandReactive: "Demanda Reativa Total Ponta",
      },
      graphic: {
        noData: "Não há dados para exibir no gráfico",
      },
    },
    form: {
      contract: "Contrato",
      company: "Empresa",
      personResponsible: "Pessoa Responsável",
      projectName: "Nome do Projeto",
      descriptionProject: "Descrição do Projeto",
      typeContract: "Tipo de Contrato",
      deadlineForExecution: "Prazo para Execução",
      numberOfDevices: "Número de Dispositivos",
      contractDuration: "Duração do Contrato",
      totalDuration: "Duração Total",
      monthValue: "Valor mensal",
      address: "Endereço",
      neighborhood: "Bairro",
      city: "Cidade",
      state: "Estado",
      zipCode: "CEP",
      submit: "Enviar",
      addFiles: "Adicionar Arquivos",
      update: "Atualizar",
      selectCompany: "Selecione a empresa",
      modules: "Módulos",
      editCard: "Editar Card",
      commercialResponsible: "Responsável comercial",
    },
    error: {
      required: "Campo obrigatório!",
      errorOccurred: "Ocorreu um erro!",
      tryAgain: "Tente novamente",
    },
    placeholder: {
      contract: "Selecione um contrato",
      company: "Selecione uma empresa",
      personResponsible: "Selecione uma pessoa responsável",
      projectName: "Digite o nome do projeto",
      descriptionProject: "Digite a descrição do projeto",
      typeContract: "Selecione um tipo de contrato",
      deadlineForExecution: "Digite o prazo para execução",
      numberOfDevices: "Quantidade de dispositivos",
      contractDuration: "Meses: 0",
      totalDuration: "Digite a duração total",
      monthValue: "Digite o valor mensal",
      address: "Digite o endereço",
      neighborhood: "Digite o bairro",
      city: "Digite a cidade",
      state: "Digite o estado",
      zipCode: "Digite o CEP",
      commercialResponsible: "Selecione o responsável comercial",
    },
    steps: {
      step1: "Project",
      step2: "Modulos",
      step3: "Contract",
      step4: "Endereço",
      next: "Próximo",
      back: "Voltar",
      cancel: "Cancelar",
    },
    axiosMsg: {
      success: "Sucesso",
      error: "Erro",
      contractRegistered: "Contrato registrado com sucesso",
      errorRegisteringContract: "Erro ao registrar contrato",
      errorReadCardValues: "Erro ao ler valores",
      contractEdited: "Contrato editado com sucesso",
      errorEditingContract: "Erro ao editar contrato",
    },
    card: {
      month: "Meses(s)",
      as: "Contrato",
      diagnostico: "Diagnóstico",
      cadastro: "Cadastro",
      instalacao: "Instalação",
      monitoramento: "Monitoramento",
      manutencao: "Manutenção",
      movedBy: "Movido por",
      createdBy: "Criado por",
    },
    commercialScreen: {
      projectQuantities: "Quantidades de projetos",
      financialValues: "Valores Financeiros",
      monthlyAvarageContracts: "Média de contratos mensal",
      monthlyDevices: "Dispositivos mensal",
      generalContractData: "Dados Gerais de Contratos",
      futureMonthlyPayments: "Prestações mensais futuras",
      averageContractsPerMonth: "Média de contratos por mês",
    },
    tooltip: {
      totalValueDemocontracts: "Valor total de contratos Demo",
      totalContractValueCurrentMonth:
        "Valor total de contratos referente ao mês atual",
      averageValueDemoContracts: "Valor médio de contratos Demo",
      averageContractValue: "Valor médio de contratos",
    },
  },
};

export default ptBrNocTranslations;
