import React, { useState } from "react";
import {Button} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBellSlash,
    faArrowAltCircleRight
  } from "@fortawesome/free-solid-svg-icons";

import { ModalOffAlarm } from "../../components/ModalOffAlarm";


const MyCustomComponent = ({ data }) => {
    const [openOffAlarm, setOpenOffAlarm] = useState(false);
    function toggleOffAlarm() {
      setOpenOffAlarm(!openOffAlarm);
    };
    let alarm = data[0]
    function handleClick() {
      setOpenOffAlarm(true);
  
    }
    return (<>
      <Button color="primary"> <a style={{ textDecoration: 'none', color: 'inherit' }} href="/alarm">
        <FontAwesomeIcon
          icon={faArrowAltCircleRight}
          fixedWidth
          className=" align-middle"
        /> </a>
      </Button>
      <Button
        className="ml-1"
        onClick={handleClick}>
        <FontAwesomeIcon
          icon={faBellSlash}
          fixedWidth
          className="align-middle"
        />
        <ModalOffAlarm
          open={openOffAlarm}
          toggle={toggleOffAlarm}
          alarm={alarm}
  
        />
  
      </Button>
    </>
    )
}

export default MyCustomComponent;