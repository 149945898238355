import { ChevronLeft, ChevronRight, Loader } from "react-feather";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Row,
  Spinner,
} from "reactstrap";

export const CardHeaderNoc = ({
  IconComponent,
  color,
  numberSelected,
  total,
  status,
  next,
  prev,
  loading,
  error,
  handleReload,
  title,
  subtitle,
}) => {
  const { t } = useTranslation();

  // if (loading) {
  //   return <Skeleton height={190} width={275} />;
  // }

  if (error) {
    return (
      <Card>
        <CardHeader>
          <CardTitle tag="h5">{title}</CardTitle>
        </CardHeader>
        <CardBody>
          <CardText>{t("Erro ao carregar os dados")}</CardText>
          <Button onClick={handleReload}>{t("Tentar novamente")}</Button>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card style={{ maxHeight: 225, marginBottom: "8px" }}>
      <CardHeader style={{ display: "flex", justifyContent: "space-between" }}>
        <CardTitle
          tag="h5"
          style={{ paddingBottom: "0px", marginBottom: "0px" }}
        >
          {title}
        </CardTitle>
        {loading ? (
          <Spinner
            style={{
              background: "#fff",
              width: "32px",
              height: "32px",
              padding: "10px",
              borderRadius: "50%",
              opacity: ".3",
              position: "absolute",
              right: "20px",
            }}
          />
        ) : (
          <IconComponent
            color={color}
            style={{
              background: "#fff",
              width: "52px",
              height: "52px",
              padding: "10px",
              borderRadius: "50%",
              opacity: ".3",
              position: "absolute",
              right: "10px",
            }}
          />
        )}
      </CardHeader>
      <CardBody className="d-flex align-items-center justify-content-center flex-column p-0">
        <Row>
          <CardText>{subtitle}</CardText>
        </Row>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <ChevronLeft onClick={prev} />
          <Row className="m-0 p-0 d-flex flex-column w-100">
            <CardText
              style={{
                color: color,
                fontSize: "72px",
                marginBottom: "0",
                lineHeight: "72px",
                textAlign: "center",
              }}
            >
              {numberSelected || "0"}
            </CardText>
            <CardText
              style={{
                fontSize: "32px",
                marginBottom: "0",
                textAlign: "center",
              }}
            >
              {total}
            </CardText>
          </Row>
          <ChevronRight onClick={next} />
        </div>

        <Row className="m-0 p-0">
          <CardText
            style={{
              color: color,
              fontSize: "16px",
              filter: `drop-shadow(0px 0px 5px #fff)`,
            }}
          >
            {status}
          </CardText>
        </Row>
      </CardBody>
    </Card>
  );
};
