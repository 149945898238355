import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {
  Button, Breadcrumb, BreadcrumbItem,
  Card, CardBody, CardHeader,
  Container,
  Spinner,
  UncontrolledTooltip
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faPen,
  faTrash,
  faFileExport
} from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { toastr } from "react-redux-toastr";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

import ModalNewDash from "./ModalNewDash";
import ModalEditDash from "./modalEditDash";
import ModalDeleteDash from "./modalDeleteDash";
import ModalCopyDash from "./ModalCopyDash";
import ModalExportDash from "./ModalExportDash";

/*** BOTÕES DE AÇÃO ***/
const ActionButtonsDash = ({ dash, tableReload }) => {
  const { t } = useTranslation()

  const [openExportDashboard, setExportDashboard] = useState(false);
  const [openCopyDash, setOpenCopyDash] = useState(false);
  const [openEditDash, setOpenEditDash] = useState(false);
  const [openDeleteDash, setOpenDeleteDash] = useState(false);

  // ativa/desativa modais
  function toggleExportDashboard() {
    setExportDashboard(!openExportDashboard);
  };
  function toggleCopyDash() {
    setOpenCopyDash(!openCopyDash);
  };
  function toggleEditDash() {
    setOpenEditDash(!openEditDash);
  };
  function toggleDeleteDash() {
    setOpenDeleteDash(!openDeleteDash);
  };

  return (
    <div className="d-flex justify-content-left">
      {/* ================== Copy =================== */}
      <div
        id={"exportDash" + dash.dashId}
        className="align-middle mr-1 cursor-pointer"
      >
        <FontAwesomeIcon // botão de editar
          icon={faFileExport}
          className="align-middle cursor-pointer"
          fixedWidth
          onClick={toggleExportDashboard}
        />
        {/* ** MODAL DE EDITAR DASHBOARD  ** */}
        {(openExportDashboard)
          ? <ModalExportDash
            open={openExportDashboard}
            toggle={toggleExportDashboard}
            dash={dash}
            tableReload={tableReload}
          />
          : null
        }
        <UncontrolledTooltip
          placement="top"
          target={"exportDash" + dash.dashId}
        >
          {t('dashboards.exportDashboardTooltip')}
        </UncontrolledTooltip>
      </div>
      <div
        id={"copyDash" + dash.dashId}
        className="align-middle mr-1 cursor-pointer"
      >
        <FontAwesomeIcon // botão de editar
          icon={faCopy}
          className="align-middle cursor-pointer"
          fixedWidth
          onClick={toggleCopyDash}
        />
        {/*** MODAL DE EDITAR DASHBOARD  ***/}
        {(openCopyDash)
          ? <ModalCopyDash
            open={openCopyDash}
            toggle={toggleCopyDash}
            dash={dash}
            tableReload={tableReload}
          />
          : null
        }
        <UncontrolledTooltip
          placement="top"
          target={"copyDash" + dash.dashId}
        >
          {t('dashboards.copyDashobardTooltip')}
        </UncontrolledTooltip>
      </div>
      {/* ================== Edit =================== */}
      <div
        id={"editDash" + dash.dashId}
        className="align-middle mr-1 cursor-pointer"
      >
        <FontAwesomeIcon // botão de editar
          icon={faPen}
          className="align-middle cursor-pointer"
          fixedWidth
          onClick={toggleEditDash}
        />
        {(openEditDash)
          ? <ModalEditDash
            open={openEditDash}
            toggle={toggleEditDash}
            dash={dash}
            tableReload={tableReload}
          />
          : null
        }
        <UncontrolledTooltip
          placement="top"
          target={"editDash" + dash.dashId}
        >
          {t('dashboards.editDashobardTooltip')}
        </UncontrolledTooltip>
      </div>
      {/* ================== Delete =================== */}
      <div
        id={"deleteDash" + dash.dashId}
        className="align-middle mr-1 cursor-pointer"
      >
        <FontAwesomeIcon // botão de remover
          icon={faTrash}
          className="align-middle cursor-pointer"
          fixedWidth
          onClick={toggleDeleteDash}
        />
        {/* ** MODAL DE REMOVER DASHBOARD  ** */}
        {(openDeleteDash)
          ? <ModalDeleteDash
            open={openDeleteDash}
            toggle={toggleDeleteDash}
            dash={dash}
            tableReload={tableReload}
          />
          : null
        }
        <UncontrolledTooltip
          placement="top"
          target={"deleteDash" + dash.dashId}
        >
          {t('dashboards.deleteDashobardTooltip')}
        </UncontrolledTooltip>
      </div>
    </div>
  )
};

const DashboardsTable = () => {
  const { t } = useTranslation();

  const [openModals, setOpenModals] = useState();
  const [isLoading, setLoading] = useState(true);
  const [dashs, setDashs] = useState([]);

  function toggle() {
    // Toggle selected element
    setOpenModals(!openModals);
  };

  const tableColumns = [
    {
      dataField: "name",
      text: t('dashboards.name'),
      sort: true,
      headerStyle: { width: "50%" },
      formatter: (_, row) => {
        return (
          <Link to='/dashboardView' onClick={() => localStorage.setItem('dashId', row.dashId)}>
            {row.name}
          </Link>
        )
      }
    },
    {
      dataField: "description",
      text: t('dashboards.description'),
      headerStyle: { width: "100%" },
      sort: true
    },
    {
      dataField: "acoes",
      text: t('dashboards.actions'),
      // sort: true,
      headerStyle: { width: "20%" },
      formatter: (_, row) => {
        return (
          <ActionButtonsDash
            className="text-center"
            dash={row}
            tableReload={() => setLoading(true)}
          />
        )
      }
    },
  ];

  useEffect(() => {
    if (isLoading) {
      axios.get('/dashboardSearch')
        .then(response => {
          setDashs(response.data);
          setLoading(false);
        })
        .catch(err => {
          console.log(err);
          toastr.warning('Erro!', "Erro ao buscar dashboards.");
        });

    }
  }, [isLoading]);

  return (
    <Container fluid>
      <Header>
        <HeaderTitle> {t('dashboards.myDashboards')}</HeaderTitle>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">{t('dashboards.homePage')}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t('dashboards.myDashboards')}</BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Card className="h-100%" style={{ marginTop: "-26px" }}>
        <CardHeader>

        </CardHeader>

        <CardBody className="text-center" >
          <p>
            {t('dashboards.moreDash')}
          </p>

          <Button
            color="primary"
            onClick={toggle}
            className="mr-1"
          >
            {t('dashboards.newDash')}
          </Button>
          <ModalNewDash
            open={openModals}
            toggle={toggle}
            tableReload={() => setLoading(true)}
          />
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          {(dashs.length === 0)
            ? (isLoading)
              ? <div className="text-center"><Spinner color="primary" /></div>
              : <div className="text-center">
                <p className="mt-4">{t('dashboards.anyDashboards')} </p>
              </div>
            : <div className="m-2">
              <BootstrapTable
                bootstrap4
                keyField="name"
                data={dashs}
                columns={tableColumns}
                bordered={false}
                striped={true}
              />
            </div>
          }
        </CardBody>
      </Card>
    </Container>
  )
};

export default DashboardsTable;