import React, { useEffect, useState } from "react";
import {
  Button,
  Form, FormGroup, Input, Label,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Row, Col
} from "reactstrap";
import { TreeSelect } from 'antd';
import BootstrapTable from "react-bootstrap-table-next";
import axios from 'axios';
import { toastr } from "react-redux-toastr";
import { useTranslation } from 'react-i18next';
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/************ COMPONENTE DE ALARMES MULTIPLOS *************/
function ConditionalComponent(props) {
  const { t } = useTranslation();

  const [deviceList, setDeviceList] = useState([]);
  const [variableList, setVariableList] = useState([]);

  function deleteAlarmOption(index) {
    props.setMultAlarmSet(props.multAlarmSet.filter((element, i) => (i !== index)));
    console.log(props.multAlarmSet);
  }
  useEffect(() => {

    if (props.data.formProj.projId) {
      // axios.post('deviceSearch/', JSON.stringify({
      //   projId: formProj
      // }))
      axios.get('/deviceSearch', {
        params: {
          projId: props.data.formProj.projId
        }
      })
        .then(res => {
          setDeviceList(res.data)
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar dispositivos.");  // t() da problema com o useEffect
        });
    }
  }, [props.data.formProj]);

  useEffect(() => {
    if (props.data.formDev.deviceId) {

      axios.get('/readVariables', {
        params: {
          id: props.data.formDev.deviceId
        }

      })
        .then(res => {
          setVariableList(res.data)
          console.log('dados', res.data.filter(devices => devices));
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar variáveis.");  // t() da problema com o useEffect
        })
    }
  }, [props.data.formDev]);

  console.log(props.data);

  return (
    <>
      <hr />
      {/* Projeto/device/varName */}
      <Row lg="3" xs="1">
        {/* Seleção de projeto */}
        <Col>
          <FormGroup>
            <Label>{t('alarms.project')}</Label>
            <Input
              type="select"
              value={JSON.stringify(props.data.formProj)}
              onChange={(e) => {

                props.setMultAlarmSet(props.multAlarmSet.map((data, i) => {

                  return (i === props.index)
                    ? {
                      ...data,
                      formProj: JSON.parse(e.target.value), // atualiza projeto para selecionado
                      formDev: { deviceId: "", deviceName: "" }, // atualiza device para vazio
                      formVar: { varName: "", varTag: "", varUnit: "" } // atualiza variavel para vazio
                    }
                    : data;
                }))
              }}
            >
              <option key={0} value={JSON.stringify({ projId: "", projName: '' })} disabled>
                {t('alarms.projectSelect')}...
              </option>
              {(props.projectList.length)
                ? props.projectList.map(item => (
                  <option key={item.id} value={JSON.stringify({
                    projId: item.id,
                    projName: item.name
                  })}>
                    {item.name}
                  </option>
                ))
                : null
              }
            </Input>
          </FormGroup>
        </Col>

        {/* Seleção de device */}
        <Col>
          <FormGroup>
            <Label>{t('alarms.device')}</Label>
            <Input
              required={true}
              type="select"
              value={JSON.stringify(props.data.formDev)}
              onChange={(e) => {

                props.setMultAlarmSet(props.multAlarmSet.map((data, i) => {

                  return (i === props.index)
                    ? {
                      ...data,
                      formDev: JSON.parse(e.target.value), // atualiza dispositivo para selecionado
                      formVar: { varName: "", varTag: "", varUnit: "" } // atualiza variavel para vazio
                    }
                    : data;
                }))
              }}
            >
              <option key={0} value={JSON.stringify({ deviceId: "", deviceName: "" })} disabled>
                {t('alarms.deviceSelect')}...
              </option>
              {(deviceList.length)
                ? deviceList.map(item => (
                  <option key={item.id} value={JSON.stringify({
                    deviceId: item.id,
                    deviceName: item.description

                  })}>
                    {item.description}

                  </option>
                ))
                : null
              }
            </Input>
          </FormGroup>
        </Col>

        {/* Seleção de variavel */}

        <Col>
          <FormGroup>
            <Label>{t('alarms.variable')}</Label>
            <Input
              required={true}
              type="select"
              value={JSON.stringify(props.data.formVar)}
              onChange={(e) => {
                console.log(e.target.value);
                props.setMultAlarmSet(props.multAlarmSet.map((data, i) => {

                  return (i === props.index)
                    ? { ...data, formVar: JSON.parse(e.target.value) }
                    : data;
                }))
              }}
            >
              <option key={0} value={JSON.stringify({ varName: "", varTag: "", varUnit: "" })} disabled>
                {t('alarms.variableSelect')}...
              </option>
              {(variableList.length)
                ? variableList.map((item, i) => (
                  <option key={i} value={JSON.stringify({
                    varName: item.varName,
                    varTag: item.tag,
                    varUnit: item.unit
                  })}>
                    {item.varName}
                  </option>
                ))
                : null
              }
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row key={props.index} style={{ alignItems: "center" }}>
        <Col>
          <FormGroup>
            {'Tipo de comparaçao'}
            <Input
              style={{ paddingBottom: "0" }}
              type="select"
              value={props.data.comparisonType}
              onChange={(e) =>
                props.setMultAlarmSet(props.multAlarmSet.map((data, i) => {

                  return (i === props.index)
                    ? { ...data, comparisonType: e.target.value }
                    : data;
                }))
              }
            >
              <option value={'0'}>
                {t('alarms.analogic')}
              </option>
              <option value={'1'}>
                {t('alarms.digital')}
              </option>

            </Input>
          </FormGroup>
        </Col>
        <Col>
          {/* Limites/Estado */}
          {(props.data.comparisonType === '0')
            /** Analógico */
            ? <div className="d-flex">
              {/* Limite Inferior */}
              <Col >
                <FormGroup>

                  <Label>{t('alarms.lowLim')}:</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      className="ml-1"
                      style={{

                        // borderColor: (invalidThr) ? 'orange' : '#ced4da'
                      }}
                      value={props.data.formThrLo}
                      onChange={(e) =>
                        props.setMultAlarmSet(props.multAlarmSet.map((data, i) => {
                          e.preventDefault()
                          return (i === props.index)
                            ? { ...data, formThrLo: e.target.value }
                            : data;
                        }))
                      }
                    />
                    <Label className="ml-1">
                      {(props.data.formVar)
                        ? props.data.formVar.varUnit
                        : ""
                      }
                    </Label>
                  </div>

                </FormGroup>
              </Col>

              {/* Limite Superior */}
              <Col>
                <FormGroup>

                  <Label>{t('alarms.highLim')}:</Label>
                  <div className="d-flex">
                    <Input
                      type="text"
                      className="ml-1"
                      style={{

                        // borderColor: (invalidThr) ? 'orange' : '#ced4da'
                      }}
                      value={props.data.formThrHi}
                      onChange={(e) =>
                        props.setMultAlarmSet(props.multAlarmSet.map((data, i) => {
                          e.preventDefault()
                          return (i === props.index)
                            ? { ...data, formThrHi: e.target.value }
                            : data;

                        }))

                      }
                    />
                    <Label className="ml-1">
                      {(props.data.formVar)
                        ? props.data.formVar.varUnit
                        : ""
                      }
                    </Label>
                  </div>
                </FormGroup>
              </Col>
            </div>
            /** Digital */
            : <>
              {/* Estado de Ativação */}
              <Col style={{ marginTop: "1rem" }}>
                <FormGroup style={{ margin: "0" }}>
                  <div className="d-flex align-items-center">
                    <Label>{t('alarms.activeState')}:</Label>
                    <Input
                      type="select"
                      className="ml-1"
                      style={{ width: "55%" }}
                      value={props.data.formActive}
                      onChange={(e) =>
                        props.setMultAlarmSet(props.multAlarmSet.map((data, i) => {

                          return (i === props.index)
                            ? { ...data, formActive: e.target.value }
                            : data;

                        }))

                      }
                    >
                      <option value={0}>{t('alarms.off')}</option>
                      <option value={1}>{t('alarms.on')}</option>
                    </Input>
                  </div>
                </FormGroup>
              </Col>
              <Col lg="4" sm="6" xs="0"></Col>
            </>}
        </Col>
        <Col style={{ textAlign: "end" }}>
          {<Button value={props.multAlarmSet} color="danger" type="button" onClick={() => deleteAlarmOption(props.index)} size={'sm'}><FontAwesomeIcon icon={faTrash} fixedWidth /></Button>}
        </Col>
      </Row>
    </>
  )
};

/****** MODAL DE REGISTRO DE ALARME ******/
const ModalAddAlarm = ({ open, toggle, tableReload }) => {
  const { t } = useTranslation();

  const [projectList, setProjectList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [variableList, setVariableList] = useState([]);

  const [formProj, setFormProj] = useState('');
  const [formDev, setFormDev] = useState('');
  const [formVar, setFormVar] = useState('');
  const [invalidProj, setInvalidProj] = useState(false);
  const [invalidDev, setInvalidDev] = useState(false);
  const [invalidVar, setInvalidVar] = useState(false);

  const [formDesc, setFormDesc] = useState('');
  const [formPrior, setFormPrior] = useState('1');
  const [formContact, setFormContact] = useState([]);
  const [invalidDesc, setInvalidDesc] = useState(false);
  // const [invalidCont, setInvalidCont] = useState(null);

  const [formType, setFormType] = useState('0');

  const [formActive, setFormActive] = useState('0');
  const [formThrLo, setFormThrLo] = useState('');
  const [formThrHi, setFormThrHi] = useState('');
  const [invalidThr, setInvalidThr] = useState(false);

  const [formMsgOn, setFormMsgOn] = useState('');
  const [formMsgOff, setFormMsgOff] = useState('');
  const [formMsgOnLo, setFormMsgOnLo] = useState('');
  const [formMsgOnHi, setFormMsgOnHi] = useState('');
  const [formMsgOffLo, setFormMsgOffLo] = useState('');
  const [formMsgOffHi, setFormMsgOffHi] = useState('');

  const [invalidMsgOn, setInvalidMsgOn] = useState(false);
  const [invalidMsgOff, setInvalidMsgOff] = useState(false);
  const [invalidMsgOnLo, setInvalidMsgOnLo] = useState(false);
  const [invalidMsgOnHi, setInvalidMsgOnHi] = useState(false);
  const [invalidMsgOffLo, setInvalidMsgOffLo] = useState(false);
  const [invalidMsgOffHi, setInvalidMsgOffHi] = useState(false);

  const [comparisonType, setComparisonType] = useState('0')
  const [multAlarmSet, setMultAlarmSet] = useState([{
    id: 0,
    comparisonType: "0",
    formActive: "0",
    formDev: {
      deviceId: "",
      deviceName: ""
    },
    formProj: {
      projId: "",
      projName: ''
    },
    formThrHi: "",
    formThrLo: "",
    formVar: {
      varName: "",
      varTag: "",
      varUnit: ""
    }
  }])

  useEffect(() => {
    // axios.post('projectUserSearch/', JSON.stringify({
    //   userId: localStorage.getItem('userId')
    // }))
    // axios.get('/projectUserSearch', {params: {
    //   userId: localStorage.getItem('userId')
    // }})
    axios.get('/projectUserSearch')
      .then(res => {
        setProjectList(res.data)
      })
      .catch(err => {
        console.log(err);
        toastr.error('Erro!', "Erro ao buscar projetos.");  // t() da problema com o useEffect
      });
  }, []);

  useEffect(() => {
    if (formProj) {
      // axios.post('deviceSearch/', JSON.stringify({
      //   projId: formProj
      // }))
      axios.get('/deviceSearch', {
        params: {
          projId: formProj
        }
      })
        .then(res => {
          setDeviceList(res.data)
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar dispositivos.");  // t() da problema com o useEffect
        });
    }
  }, [formProj]);

  useEffect(() => {
    if (formDev) {

      axios.get('/readVariables', {
        params: {
          id: formDev
        }
      })
        .then(res => {
          setVariableList(res.data)
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar variáveis.");  // t() da problema com o useEffect
        })
    }
  }, [formDev]);

  const contactOptions = [
    {
      title: 'E-mail',
      key: 'email',
      value: 'email',
      isLeaf: true,
      checkable: true,
      id: 'email'
    },
    {
      title: t('alarms.phone'),
      key: 'phone',
      value: 'phone',
      isLeaf: true,
      checkable: true,
      id: 'phone'
    },
    {
      title: 'WhatsApp',
      key: 'whatsapp',
      value: 'whatsapp',
      isLeaf: true,
      checkable: true,
      id: 'whatsapp'
    }
  ];

  function onSubmitForm(btn) {
    if (formType <= '1') { // alarme analógico ou digital
      /** verifica se o usuário inseriu campos obrigatórios */
      if (!formProj || !formDev || !formVar || !formDesc) {
        setInvalidProj(!formProj);
        setInvalidDev(!formDev);
        setInvalidVar(!formVar);
        setInvalidDesc(!formDesc);

        toastr.warning(`${t('alarms.att')}!`, `${'alarms.invalid'}.`);
      }

      /** verifica se o usuário configurou algum limite se o alarme for do tipo analógico */
      else if (formType === '0' && !formThrLo && !formThrHi) {
        setInvalidThr(true);
        toastr.warning(`${t('alarms.error')}!`, `${t('alarms.noLimit')}.`)
      }

      /**  verifica se o limite superior é maior que o inferior, se ambos existem */
      else if (formThrLo && formThrHi && Number(formThrLo) >= Number(formThrHi)) {
        setInvalidThr(true);
        toastr.warning(`${t('alarms.error')}!`, `${t('alarms.lowerHigh')}.`)
      }

      /** verifica se o usuário inseriu mensagens para cada limite definido */
      else if ((formThrLo && (!formMsgOnLo || !formMsgOffLo)) || (formThrHi && (!formMsgOnHi || !formMsgOffHi))) {
        setInvalidMsgOnLo(Boolean(formThrLo && !formMsgOnLo));
        setInvalidMsgOnHi(Boolean(formThrHi && !formMsgOnHi));
        setInvalidMsgOffLo(Boolean(formThrLo && !formMsgOffLo));
        setInvalidMsgOffHi(Boolean(formThrHi && !formMsgOffHi));
        toastr.warning(`${t('alarms.error')}!`, `${t('alarms.noMessages')}.`)
      }

      /** verifica se foram inseridas mensagens, se alarme for do tipo digital */
      else if (formType === '1' && (!formMsgOn || !formMsgOff)) {
        setInvalidMsgOn(!formMsgOn);
        setInvalidMsgOff(!formMsgOff);
        toastr.warning(`${t('alarms.error')}!`, `${t('alarms.noMessages')}.`)
      }

      else {
        const jsonData = {
          deviceId: formDev,
          description: formDesc,
          varName: JSON.parse(formVar).varName,
          varTag: JSON.parse(formVar).varTag,
          varUnit: JSON.parse(formVar).varUnit,
          type: formType,
          priority: formPrior,
          contact: formContact,
          lowLimit: (formType === '0')
            ? formThrLo
            : (formActive === '0')
              ? 0
              : '',
          messageLowOn: (formType === '0')
            ? (formThrLo)
              ? formMsgOnLo
              : ''
            : (formActive === '0')
              ? formMsgOn
              : '',
          messageLowOff: (formType === '0')
            ? (formThrLo)
              ? formMsgOffLo
              : ''
            : (formActive === '0')
              ? formMsgOff
              : '',
          highLimit: (formType === '0')
            ? formThrHi
            : (formActive === '1')
              ? 1
              : '',
          messageHighOn: (formType === '0')
            ? (formThrHi)
              ? formMsgOnHi
              : ''
            : (formActive === '1')
              ? formMsgOn
              : '',
          messageHighOff: (formType === '0')
            ? (formThrHi)
              ? formMsgOffHi
              : ''
            : (formActive === '1')
              ? formMsgOff
              : ''
        };

        /*** Envio de dados ***/
        axios.post('addAlarmConfig/', JSON.stringify(jsonData))
          .then(res => {
            toastr.success(res.data.messageHead, res.data.messageBody);
            tableReload();

            /* se dados foram enviados pelo botão "salvar dados"... */
            if (btn === "btnClose") {
              toggle(); // ...fechar o modal
            }

            else { // se foi pelo "salvar e continuar"...
              /* ...limpar campos do formulario */
              setFormDesc('');
              setFormThrLo('');
              setFormThrHi('');
              setFormMsgOn('');
              setFormMsgOff('');
              setFormMsgOnLo('');
              setFormMsgOnHi('');
              setFormMsgOffLo('');
              setFormMsgOffHi('');
            }
          })
          .catch(err => {
            console.log(err);
            console.log(err.response);

            if (err.response) {
              /* Notificação de erro de envio */
              toastr.warning(
                err.response.data.messageHead,
                err.response.data.messageBody
              );
            }
          });
      }
    }
    else { // alarme múltiplo
      // console.log(multAlarmSet);

      const multipleAlarmsJson = {
        deviceId: null,
        description: formDesc,
        varName: null,
        varTag: null,
        varUnit: null,
        type: formType,
        priority: formPrior,
        lowLimit: '',
        highLimit: '',
        contact: formContact,
        messageLowOn: formMsgOn,
        messageHighOn: formMsgOn,
        messageLowOff: formMsgOff,
        messageHighOff: formMsgOff,
        multipleConfig: multAlarmSet
      };
      console.log(multipleAlarmsJson);
      // return
      /*** Envio de dados ***/
      axios.post('addAlarmConfig/', JSON.stringify(multipleAlarmsJson))
        .then(res => {
          toastr.success(res.data.messageHead, res.data.messageBody);
          tableReload();

          /* se dados foram enviados pelo botão "salvar dados"... */
          if (btn === "btnClose") {
            toggle(); // ...fechar o modal
          }

          else { // se foi pelo "salvar e continuar"...
            /* ...limpar campos do formulario */
            setFormDesc('');
            setFormThrLo('');
            setFormThrHi('');
            setFormMsgOn('');
            setFormMsgOff('');
            setFormMsgOnLo('');
            setFormMsgOnHi('');
            setFormMsgOffLo('');
            setFormMsgOffHi('');
          }
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);

          if (err.response) {
            /* Notificação de erro de envio */
            toastr.warning(
              err.response.data.messageHead,
              err.response.data.messageBody
            );
          }
        });
    }
  };

  function addAlarmOption(e) {
    e.preventDefault()
    // let addAlarmVariable = {
    //   id: multAlarmSet.length,
    //   comparisonType,
    //   formDev,
    //   formProj,
    //   formVar,
    //   formThrHi,
    //   formThrLo,
    //   formActive,
    //   formMsgOn,
    //   formMsgOff,
    // };

    let addAlarmVariable = {
      id: multAlarmSet.length,
      comparisonType: "0",
      formActive: "0",
      formDev: {
        deviceId: "",
        deviceName: ""
      },
      formProj: {
        projId: "",
        projName: ''
      },
      formThrHi: "",
      formThrLo: "",
      formVar: {
        varName: "",
        varTag: "",
        varUnit: ""
      }
    }

    setFormThrHi('')
    setFormThrLo('')
    setComparisonType(comparisonType)
    setFormActive('')
    setFormMsgOn(formMsgOn)
    setFormMsgOff(formMsgOff)
    setFormVar('')
    setFormProj('');
    setFormDev('');

    setMultAlarmSet(data => [...data, addAlarmVariable])


  }

  const inputStyle={
    width: '20%'
  };

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      size={"lg"}
    >
      <Form
        onSubmit={e => {
          e.preventDefault();
          const btn = e.nativeEvent.submitter.name;
          onSubmitForm(btn)
        }}
      >
        <ModalHeader toggle={toggle}>
          {t('alarms.addAlarm')}
        </ModalHeader>

        <ModalBody className="text-left mx-3">
          {/** Tipo/Descrição/Prioridade/Contato **/}
          <Row style={{ width: "100%", flexWrap: "nowrap" }}>
            {/** Tipo **/}
            <Col>
              <FormGroup>
                <Label>{t('alarms.alarmType')}:</Label>
                <Input
                  type="select"
                  className=""

                  value={formType}
                  onChange={e => {
                    setFormType(e.target.value);
                    setFormThrLo('');
                    setFormThrHi('');
                    setFormMsgOn('');
                    setFormMsgOff('');
                    setFormMsgOnLo('');
                    setFormMsgOnHi('');
                    setFormMsgOffLo('');
                    setFormMsgOffHi('');
                    setInvalidMsgOn(false);
                    setInvalidMsgOff(false);
                    setInvalidMsgOnLo(false);
                    setInvalidMsgOnHi(false);
                    setInvalidMsgOffLo(false);
                    setInvalidMsgOffHi(false);
                  }}
                >
                  <option value={'0'}>
                    {t('alarms.analogic')}
                  </option>
                  <option value={'1'}>
                    {t('alarms.digital')}
                  </option>
                  <option value={'2'}>
                    {'Alarmes multiplos'}
                  </option>
                </Input>
              </FormGroup>
            </Col>
            {/* Descrição */}
            <Col>
              <FormGroup>

                <Label>{t('alarms.description')}:</Label>
                <Input
                  type="text"
                  className="ml-1"
                  value={formDesc}
                  onChange={e => {
                    setFormDesc(e.target.value);
                    setInvalidDesc(false);
                  }}
                  invalid={invalidDesc}
                  required={true}
                />

              </FormGroup>
            </Col>

            {/* Prioridade*/}
            <Col>
              <FormGroup>

                <Label>{t('alarms.priority')}:</Label>
                <Input
                  type="select"
                  className="ml-1"
                  value={formPrior}
                  onChange={e => setFormPrior(e.target.value)}
                >
                  <option value="1">
                    {t('alarms.highPriority')}
                  </option>
                  <option value="2">
                    {t('alarms.mediumPriority')}
                  </option>
                  <option value="3">
                    {t('alarms.lowPriority')}
                  </option>
                </Input>

              </FormGroup>
            </Col>

            {/* Contato */}
            <Col>
              <FormGroup>

                <Label>{t('alarms.contact')}:</Label>
                <TreeSelect
                  treeDataSimpleMode
                  maxTagCount="responsive"
                  treeData={contactOptions}
                  value={formContact}
                  onChange={value => {
                    setFormContact(value);
                    // setInvalidCont(null);
                  }}
                  treeCheckable={true}
                  placeholder={t('alarms.select')}
                  size="small"
                  style={{ width: '100%' }}
                  className="ml-1 cursor-pointer"
                // status={invalidCont}
                // className="ml-1 modal-content form-control cursor-pointer"
                />

              </FormGroup>
            </Col>
          </Row>

          {(formType <= '1') ?
            <>
              {/* Projeto/device/varName */}
              <Row lg="3" xs="1">
                {/* Seleção de projeto */}
                <Col>
                  <FormGroup>
                    <Label>{t('alarms.project')}</Label>
                    <Input
                      type="select"
                      value={formProj}
                      onChange={e => {
                        setFormProj(e.target.value);
                        setFormDev('');
                        setFormVar('');
                        setInvalidProj(false);
                        setInvalidDev(false);
                        setInvalidVar(false);
                      }}
                      invalid={invalidProj}
                    >
                      <option key={0} value={''} disabled>
                        {t('alarms.projectSelect')}...
                      </option>
                      {(projectList.length)
                        ? projectList.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))
                        : null
                      }
                    </Input>
                  </FormGroup>
                </Col>

                {/* Seleção de device */}
                <Col>
                  <FormGroup>
                    <Label>{t('alarms.device')}</Label>
                    <Input
                      type="select"
                      value={formDev}
                      onChange={e => {
                        setFormDev(e.target.value);
                        setFormVar('');
                        setInvalidDev(false);
                        setInvalidVar(false);
                      }}
                      disabled={(!deviceList.length)}
                      invalid={invalidDev}
                    >
                      <option key={0} value={''} disabled>
                        {t('alarms.deviceSelect')}...
                      </option>
                      {(deviceList.length)
                        ? deviceList.map(item => (
                          <option key={item.id} value={item.id}>
                            {item.description}
                          </option>
                        ))
                        : null
                      }
                    </Input>
                  </FormGroup>
                </Col>

                {/* Seleção de variavel */}

                <Col>
                  <FormGroup>
                    <Label>{t('alarms.variable')}</Label>
                    <Input
                      type="select"
                      value={formVar}
                      onChange={e => {
                        setFormVar(e.target.value);
                        setInvalidVar(false);
                      }}
                      disabled={(!variableList.length)}
                      invalid={invalidVar}
                    >
                      <option key={0} value={''} disabled>
                        {t('alarms.variableSelect')}...
                      </option>
                      {(variableList.length)
                        ? variableList.map((item, i) => (
                          <option key={i} value={JSON.stringify({
                            varName: item.varName,
                            varTag: item.tag,
                            varUnit: item.unit
                          })}>
                            {item.varName}
                          </option>
                        ))
                        : null
                      }
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                {/* Limites/Estado */}
                {(formType === '0')
                  /** Analógico */
                  ? <>
                    {/* Limite Inferior */}
                    <Col sm="6" xs="12">
                      <FormGroup>
                        <div className="d-flex align-items-center justify-content-start">
                          <Label>{t('alarms.lowLim')}:</Label>
                          <Input
                            type="text"
                            className="ml-1"
                            style={{
                              width: inputStyle.width,
                              borderColor: (invalidThr) ? 'orange' : '#ced4da'
                            }}
                            value={formThrLo}
                            onChange={e => {
                              setFormThrLo(e.target.value);
                              setInvalidThr(false);
                              setInvalidMsgOnLo(false);
                              setInvalidMsgOffLo(false);
                            }}
                          />
                          <Label className="ml-1">
                            {(formVar)
                              ? JSON.parse(formVar).varUnit
                              : ""
                            }
                          </Label>
                        </div>
                      </FormGroup>
                    </Col>

                    {/* Limite Superior */}
                    <Col sm="6" xs="12">
                      <FormGroup>
                        <div className="d-flex align-items-center justify-content-start">
                        <Label>{t('alarms.highLim')}:</Label>
                        <Input
                          type="text"
                          className="ml-1"
                          style={{
                            width: inputStyle.width,
                            borderColor: (invalidThr) ? 'orange' : '#ced4da'
                          }}
                          value={formThrHi}
                          onChange={e => {
                            setFormThrHi(e.target.value);
                            setInvalidThr(false);
                            setInvalidMsgOnHi(false);
                            setInvalidMsgOffHi(false);
                          }}
                        />
                        <Label className="ml-1">
                          {(formVar)
                            ? JSON.parse(formVar).varUnit
                            : ""
                          }
                        </Label>
                        </div>
                      </FormGroup>
                    </Col>
                  </>
                  /** Digital */
                  : <>
                    {/* Estado de Ativação */}
                    <Col lg="4" sm="6" xs="12">
                      <FormGroup>
                        <div className="d-flex align-items-center justify-content-between">
                          <Label>{t('alarms.activeState')}:</Label>
                          <Input
                            type="select"
                            className="ml-1"
                            style={{ width: "55%" }}
                            value={formActive}
                            onChange={e => {
                              setFormActive(e.target.value);
                            }}
                          >
                            <option value={0}>{t('alarms.off')}</option>
                            <option value={1}>{t('alarms.on')}</option>
                          </Input>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg="4" sm="6" xs="0"></Col>
                  </>
                }
              </Row>
            </> : <> {/* // INICIO CONDICIONAL DE ALARMES MULTIPLOS         */}

              {multAlarmSet.map((data, index) => (
                <ConditionalComponent
                  key={index}
                  projectList={projectList}
                  multAlarmSet={multAlarmSet}
                  setMultAlarmSet={setMultAlarmSet}
                  data={data}
                  index={index}
                />
              ))}
            </>
          }

          <hr />

          {/****  MENSAGENS GERAL******/}
          {(formType === '0')
            /** Analógico */
            ? <div>
              {/* Mensagens ON */}
              <Row sm="2" xs="1">
                {/* Mensagem ON Inferior */}
                <Col>
                  <FormGroup>
                    <Label>{t('alarms.msgOnLo')}:</Label>
                    <Input
                      type="textarea"
                      rows="3"
                      value={formMsgOnLo}
                      onChange={e => {
                        setFormMsgOnLo(e.target.value);
                        setInvalidMsgOnLo(false);
                      }}
                      disabled={(!formThrLo)}
                      placeholder={(!formThrLo) ? t('alarms.noLimitLow') : null}
                      invalid={invalidMsgOnLo}
                    />
                  </FormGroup>
                </Col>

                {/* Mensagem ON Superior */}
                <Col>
                  <FormGroup>
                    <Label>{t('alarms.msgOnHi')}:</Label>
                    <Input
                      type="textarea"
                      rows="3"
                      value={formMsgOnHi}
                      onChange={e => {
                        setFormMsgOnHi(e.target.value);
                        setInvalidMsgOnHi(false);
                      }}
                      disabled={(!formThrHi)}
                      placeholder={(!formThrHi) ? t('alarms.noLimitHigh') : null}
                      invalid={invalidMsgOnHi}
                    />
                  </FormGroup>
                </Col>
              </Row>

              {/* Mensagens OFF */}
              <Row sm="2" xs="1">
                {/* Mensagem OFF Inferior */}
                <Col>
                  <FormGroup>
                    <Label>{t('alarms.msgOffLo')}:</Label>
                    <Input
                      type="textarea"
                      rows="3"
                      value={formMsgOffLo}
                      onChange={e => {
                        setFormMsgOffLo(e.target.value);
                        setInvalidMsgOffLo(false);
                      }}
                      disabled={(!formThrLo)}
                      placeholder={(!formThrLo) ? t('alarms.noLimitLow') : null}
                      invalid={invalidMsgOffLo}
                    />
                  </FormGroup>
                </Col>

                {/* Mensagem OFF Superior */}
                <Col>
                  <FormGroup>
                    <Label>{t('alarms.msgOffHi')}:</Label>
                    <Input
                      type="textarea"
                      rows="3"
                      value={formMsgOffHi}
                      onChange={e => {
                        setFormMsgOffHi(e.target.value);
                        setInvalidMsgOffHi(false);
                      }}
                      disabled={(!formThrHi)}
                      placeholder={(!formThrHi) ? t('alarms.noLimitHigh') : null}
                      invalid={invalidMsgOffHi}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            /** Digital */
            : <div>
              {/* Mensagem de alarme ligado */}
              <Row>
                <Col>
                  <FormGroup>
                    <Label>{t('alarms.msgOn')}:</Label>
                    <Input
                      type="textarea"
                      rows="3"
                      value={formMsgOn}
                      onChange={e => {
                        setFormMsgOn(e.target.value);
                        setInvalidMsgOn(false);
                      }}
                      invalid={invalidMsgOn}
                    />
                  </FormGroup>
                </Col>

                {/* Mensagem de alarme desligado */}
                <Col>
                  <FormGroup>
                    <Label>{t('alarms.msgOff')}:</Label>
                    <Input
                      type="textarea"
                      rows="3"
                      value={formMsgOff}
                      onChange={e => {
                        setFormMsgOff(e.target.value);
                        setInvalidMsgOff(false);
                      }}
                      invalid={invalidMsgOff}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          }
          <Row style={{ justifyContent: "space-around" }}>
            {(formType === '2') ? <Button value={multAlarmSet} color="primary" type="button" onClick={addAlarmOption} size={'sm'}><FontAwesomeIcon icon={faPlus} fixedWidth /></Button> : null}
          </Row>
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR *
            * (apenas fecha o modal sem enviar nada para a API) ***/}
          <Button
            name="btnCancel"
            type="button"
            color='primary'
            className="mr-1 mb-1"
            onClick={toggle}
            outline
          >
            {t('alarms.cancel')}
          </Button>
          {/*** BOTÃO DE ENVIAR DADOS PARA A API SEM FECHAR O MODAL ***/}
          <Button
            name="btnOpen"
            type="submit"
            color="primary"
          >
            {t('alarms.saveContinue')}
          </Button>
          {/*** BOTÃO DE ENVIAR DADOS E FECHAR O MODAL ***/}
          <Button
            name="btnClose"
            type="submit"
            color="primary"
          >
            {t('alarms.saveAlarm')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

/****** MODAL DE VINCULAR USUÁRIOS A ALARME ******/
const ModalAddUser = ({ open, toggle, alarm }) => {
  const { t } = useTranslation();

  const [loadUsersProj, setLoadUsersProj] = useState(true);
  const [projUsers, setProjUsers] = useState([]);
  const [loadUsersAlarm, setLoadUsersAlarm] = useState(false);
  const [alarmUsersIds, setAlarmUsersIds] = useState([]);
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);

  function multipleId() {
    try {
      let multipleId = JSON.parse(alarm.multipleConfig).map(data => data.formDev.deviceId)
      return multipleId
    } catch (err) { }
  }

  function deviceId() {
    try {
      let test = alarm.deviceId
      return test
    }
    catch (err) { }
  }

  // Busca usuários
  useEffect(() => {
    // Busca usuários vinculados à planta do dispositivo
    // do qual o alarme faz parte
    if (loadUsersProj) {
      // axios.post('userDeviceSearch/', JSON.stringify({
      //   deviceId: alarm.deviceId
      // }))
      axios.get('/userDeviceSearch', {
        params: {
          deviceId: deviceId(),
          multiple: multipleId()
        }
      })
        .then(res => {
          setProjUsers(res.data);
          setLoadUsersProj(false);
          setLoadUsersAlarm(true);

        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar usuários."); // t() da problema com o useEffect
        });
    }

    // Busca usuários já vinculados ao alarme
    if (loadUsersAlarm) {
      // axios.post('userAlarmSearch/', JSON.stringify({
      //   alarmId: alarm.id
      // }))
      axios.get('/userAlarmSearch', {
        params: {
          alarmId: alarm.id
        }
      })
        .then(res => {
          let ids = [];
          res.data.map((usr) => ids.push(usr.id));
          setAlarmUsersIds(ids);
          setSelectedUsersIds(ids);
          setLoadUsersAlarm(false);
        })
        .catch(err => {
          console.log(err);
          toastr.error('Erro!', "Erro ao buscar usuários ligados ao alarme."); // t() da problema com o useEffect
        });
    }
    // eslint-disable-next-line
  }, [loadUsersProj, loadUsersAlarm, alarm]);

  /*** configurações das colunas da tabela de usuários ***/
  const cols = [
    { // ID (oculto)
      dataField: "userId",
      text: `${t('alarms.id')}`,
      sort: true,
      hidden: true,
    },
    { // nome de usuário
      dataField: "name",
      text: t('alarms.name'),
      sort: true,
    },
    { // empresa
      dataField: "companyName",
      text: t('alarms.companyName'),
      sort: true,
    }
  ];

  /* Checkboxes */
  const selectRow = {
    mode: "checkbox",
    bgColor: "#f8f9fa",
    selected: selectedUsersIds,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedUsersIds([...selectedUsersIds, row.id]);
      } else {
        setSelectedUsersIds(selectedUsersIds.filter(x => x !== row.id));
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        let ids = [];
        rows.map(row => ids.push(row.id));
        setSelectedUsersIds(ids);
      } else {
        setSelectedUsersIds([]);
      }
    }
  }

  const defaultSorted = [{
    dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
    order: 'asc' // desc or asc
  }];

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        {t('alarms.changeUsers')} {alarm.description}
      </ModalHeader>

      <ModalBody className="mx-6">

        <p>{t('alarms.changeUsers')} {alarm.description}</p>

        <BootstrapTable
          bootstrap4
          keyField="id"
          data={projUsers}
          columns={cols}
          condensed
          bordered={false}
          striped={true}
          selectRow={selectRow}
          defaultSorted={defaultSorted}
        />

      </ModalBody>

      <ModalFooter>
        {/*** BOTÃO DE CANCELAR ***/}
        <Button
          name="btnCancel"
          color='primary'
          className="mr-1 mb-1"
          onClick={toggle}
          outline
        >
          {t('alarms.cancel')}
        </Button>

        {/*** BOTÃO DE ENVIAR DADOS E FECHAR O MODAL ***/}
        <Button
          name="btnClose"
          type="submit"
          color="primary"
          onClick={e => {
            e.preventDefault(); // evitar atualizar página

            /* Vincula usuários selecionados ao alarme *
             * caso já não estejam antes */
            selectedUsersIds.forEach((usrId, i) => {
              if (!alarmUsersIds.includes(usrId)) {
                axios.post('addUserToAlarm/', JSON.stringify({
                  userId: usrId,
                  alarmId: alarm.id
                }))
                  .then((res) => {
                    console.log(res.data);
                    // console.log(`${usrId} adicionado`);
                  })
                  .catch(err => {
                    console.log(err);
                    console.log(err.response);

                    if (err.response) {
                      /* Notificação de erro de envio */
                      toastr.warning(
                        err.response.data.messageHead,
                        err.response.data.messageBody
                      );
                    }
                  });
              }
            })

            /* Desvincula usuários des-selecionados do alarme */
            alarmUsersIds.forEach((usrId, i) => {
              if (!selectedUsersIds.includes(usrId)) {
                // axios.post('removeUserFromAlarm/', JSON.stringify({
                //   userId: usrId,
                //   alarmId: alarm.id
                // }))
                axios.delete('removeUserFromAlarm/', {
                  data: {
                    userId: usrId,
                    alarmId: alarm.id
                  }
                })
                  .then((res) => {
                    console.log(res.data);
                    // console.log(`${usrId} removido`);
                  })
                  .catch(err => {
                    console.log(err);
                    console.log(err.response);

                    if (err.response) {
                      /* Notificação de erro de envio */
                      toastr.warning(
                        err.response.data.messageHead,
                        err.response.data.messageBody
                      );
                    }
                  });
              }
            })

            toastr.success(
              `${t('alarms.success')}!`,
              `${t('alarms.alarmUpdated')}.`
            ); // Notificação
            toggle(); // fecha o modal
          }}
        >
          {t('alarms.saveChanges')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export { ModalAddAlarm, ModalAddUser };