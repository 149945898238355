import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, CardTitle, Spinner } from "reactstrap";
import ConsumChart from "./carouselComponents/ConsumChart";
import General from "./carouselComponents/General";
import { MeterInfo } from "./carouselComponents/MeterInfo";
import { WaterTable } from "./carouselComponents/components/WaterTable";

const CarouselCard = ({ generalDataConsumption, selectedUC, loading }) => {
  const { t } = useTranslation();

  const [ucValues, setUcValues] = useState({});

  function groupConsumptionByDayForAllRegisters(registers) {
    const totalConsumptionPerDay = {};

    registers?.forEach((register) => {
      register.consumptionPerDay.forEach((entry) => {
        const date = entry.timestamp.split(" ")[0];
        const consumption = entry.consumption;

        if (!totalConsumptionPerDay[date]) {
          totalConsumptionPerDay[date] = 0;
        }

        totalConsumptionPerDay[date] += consumption;
      });
    });

    const newArray = [];
    Object.keys(totalConsumptionPerDay).forEach((key) => {
      newArray.push({
        timestamp: key,
        consumption: parseFloat(totalConsumptionPerDay[key].toFixed(2)),
      });
    });

    return newArray;
  }

  useEffect(() => {
    if (selectedUC !== "general") {
      const generalUcConsumptionPerDay = groupConsumptionByDayForAllRegisters(
        generalDataConsumption
      );
      const generalUcConsumption = generalUcConsumptionPerDay?.reduce(
        (sum, value) => sum + value.consumption,
        0
      );

      setUcValues({
        generalDataConsumption,
        generalUcConsumptionPerDay,
        generalUcConsumption,
      });
    }
  }, [generalDataConsumption, selectedUC]);

  return (
    <Card style={{ minHeight: "340px" }}>
      <CardHeader className="d-flex justify-content-between">
        <CardTitle tag="h5" className="mb-0">
          {selectedUC === "general"
            ? t("water.plusInfos")
            : t("water.ucInfo") + selectedUC.description}
        </CardTitle>
      </CardHeader>

      {loading ? (
        <div className="text-center mt-2">
          <Spinner color="primary" />
        </div>
      ) : (
        <CardBody className="text-center">
          {selectedUC === "general" ? (
            <>
              {generalDataConsumption?.generalConsumptionPerDay?.length > 0 ? (
                <Carousel interval={null}>
                  <Carousel.Item>
                    <ConsumChart
                      registers={
                        generalDataConsumption.generalConsumptionPerDay
                      }
                      dashName={"general"}
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <WaterTable data={generalDataConsumption?.registers}/>
                  </Carousel.Item>
                </Carousel>
              ) : (
                <p>{t('water.noData')}</p>
              )}
            </>
          ) 
          : 
          (
            <Carousel
              interval={null}
              controls="true"
              className="carouselButton"
            >
              <Carousel.Item>
                <General ucData={ucValues} />
              </Carousel.Item>
              {ucValues?.generalUcConsumptionPerDay?.length > 0 && (
                <Carousel.Item>
                  <CardTitle tag="h5" className="mb-0">
                    {t('water.generalConsumption')} {selectedUC?.description}
                  </CardTitle>
                  <ConsumChart
                    registers={ucValues?.generalUcConsumptionPerDay}
                    dashName={selectedUC?.description}
                  />
                </Carousel.Item>
              )}
              {ucValues?.generalDataConsumption?.map((meter, index) => (
                <Carousel.Item key={index}>
                  <CardTitle tag="h5" className="mb-0">
                    {t("water.registerNo")}: {meter.registerNo}
                  </CardTitle>
                  <MeterInfo meterInfo={meter} />
                  <ConsumChart
                    registers={meter?.consumptionPerDay}
                    dashName={meter.registerNo + index}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          )}
        </CardBody>
      )}
    </Card>
  );
};

export default CarouselCard;
