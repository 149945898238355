import {
  faCaretDown,
  faCaretUp,
  faSignal,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import Header from "../../../../components/Header";
import HeaderTitle from "../../../../components/HeaderTitle";

const PlantsTableSort = (plantList, loading) => {
  const { t } = useTranslation();

  const plants = [
    {
      dataField: "id",
      text: `ID`,
      sort: true,
      hidden: true,
      isKey: true,
    },
    {
      dataField: "name",
      text: t("energy.pvEnergy"),
      sort: true,
      headerStyle: { padding: "12px", maxWidth: "170px", minWidth: "158px" },
      headerClasses: "text-center",
      style: { textAlign: "center" },
      formatter: (cell, row) => {
        return <Link to={`/project-${row.id}-energy`}>{cell}</Link>;
      },
    },
    {
      dataField: "status",
      text: t('energy.status'),
      sort: true,
      headerStyle: {padding: "12px", maxWidth:"170px", minWidth:"75px"},
      headerClasses:"thead-light text-center",
      style: {textAlign:"center"},
      formatter: (cell, row) => {
        if (cell === 0) {
          return <div id={"tumbDown" + row.id}>
            <FontAwesomeIcon icon={faSignal} fixedWidth className="align-middle fa-blink" style={{marginBottom:"2px", color:"red"}}/>
            <UncontrolledTooltip
              placement="top"
              target={"tumbDown" + row.id}
            >
             {t('energy.tooltipOff')}
            </UncontrolledTooltip>
            </div>
        } else if (cell === 1) {
          return <div id={"tumbAlert" + row.id}>
          <FontAwesomeIcon icon={faSignal} fixedWidth className="align-middle" style={{marginBottom:"2px", color:"#FFA500"}}/>
          <UncontrolledTooltip
            placement="top"
            target={"tumbAlert" + row.id}
          >
            {t('energy.tooltipWarning')}
          </UncontrolledTooltip>
          </div>
        }else {
          return <div id={"tumbUp" + row.id}>
          <FontAwesomeIcon icon={faSignal} fixedWidth className="align-middle" style={{marginBottom:"2px", color:"green"}}/>
          <UncontrolledTooltip
            placement="top"
            target={"tumbUp" + row.id}
          >
           {t('energy.tooltipOn')}
          </UncontrolledTooltip>
          </div>
        }
      }
    },
    {
      dataField: "totalConsumption",
      text: t("energy.totalConsumption") + " (kWh)",
      sort: true,
      headerStyle: { padding: "12px", maxWidth: "210px", minWidth: "200px" },
      headerClasses: "thead-light text-center",
      style: { textAlign: "center" },
    },
    {
      dataField: "totalPeakConsumption",
      text: t("energy.peak") + " (kWh)",
      sort: true,
      headerStyle: { padding: "12px", maxWidth: "185px", minWidth: "200px" },
      headerClasses: "thead-light text-center",
      style: { textAlign: "center" },
    },
    {
      dataField: "totalOffPeakConsumption",
      text: t("energy.offPeak") + " (kWh)",
      sort: true,
      headerStyle: { padding: "12px", maxWidth: "170px", minWidth: "135px" },
      headerClasses: "thead-light text-center",
      style: { textAlign: "center" },
    },
  ];

  const sortIcon = {
    sortCaret: (order) =>
      !order ? (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faSort} />
      ) : order === "asc" ? (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faCaretDown} />
      ) : (
        <FontAwesomeIcon className={"fa-xs ml-1"} icon={faCaretUp} />
      ),
  };

  const { SearchBar } = Search;

  function onColumnMatch({ searchText, row }) {
    var normSearch = searchText
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();

    var normName = row.name
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();

    if (normName.includes(normSearch)) {
      return true;
    } else return false;
  }

  return (
    <div className="w-100" sm="12">
      {plantList.plantList ? (
        <Card>
          <CardBody
            className="styleScroll"
            style={{ overflowY: "scroll", height: "435px" }}
          >
            {plantList.plantList ? (
              <ToolkitProvider
                bootstrap4
                keyField="plantId"
                data={plantList.plantList}
                columns={plants}
                search={{ onColumnMatch: onColumnMatch }}
              >
                {(props) => (
                  <div>
                    <div
                      style={{
                        marginTop: "5px",
                        marginBottom: "15px",
                        marginLeft: "-11px",
                      }}
                    >
                      <SearchIcon
                        width={36}
                        height={16}
                        className="text-muted"
                      />
                      <SearchBar
                        style={{
                          width: "100%",
                          height: "32px",
                          fontSize: "10pt",
                        }}
                        placeholder={t("alarms.search")}
                        {...props.searchProps}
                      />
                    </div>

                    <BootstrapTable
                      condensed
                      bordered={false}
                      sort={sortIcon}
                      striped={true}
                      {...props.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            ) : null}
          </CardBody>
        </Card>
      ) : false ? (
        <div className="text-center mb-4">
          {" "}
          <Spinner color="primary" />
        </div>
      ) : (
        <p className="mt-3 text-center">{t("devices.noData")}</p>
      )}
    </div>
  );
};

export const EnergyModule = ({ activeTab }) => {
  const { t } = useTranslation();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("v2/energy/readEnergy", {
        params: {
          // userId: localStorage.getItem('userId'),
          monthRef: new Date(),
        },
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Container fluid>
        <Header>
          <HeaderTitle>{t("energy.energyVision")}</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">{t("sidebar.home")}</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{t("energy.energy")}</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <div>
          {data ? (
            <div>
              <Row sm={12}>
                <Col>
                  <PlantsTableSort plantList={data} loading={loading} />
                </Col>
              </Row>
            </div>
          ) : loading ? (
            <div className="text-center mb-4" style={{ marginTop: "300px" }}>
              {" "}
              <Spinner color="primary" />
            </div>
          ) : (
            <Card>
              <CardBody
                style={{
                  marginTop: "54px",
                  height: "94px",
                  textAlign: "center",
                }}
              >
                {t("energy.noDataPlants")}
              </CardBody>
            </Card>
          )}
        </div>
      </Container>
    </>
  );
};
