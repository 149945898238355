export const PaginationOptions = () => {

  const sizePerPage = 10;

  const options = {  
    sizePerPage: sizePerPage,
    pageStartIndex: 1,
    paginationSize: 3,
    alwaysShowAllBtns: true,
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    hideSizePerPage: true,
    disablePageTitle: true,
    hidePageListOnlyOnePage: true,
    showTotal: true,
  };

  return options;

}