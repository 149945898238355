import React, { useEffect, useState } from "react";
import { Link, useRouteMatch, useLocation } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Input,
  Row,
  Spinner
} from "reactstrap";

import axios from "axios";
import { Search } from "react-feather";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import Header from "../../../../components/Header";
import HeaderTitle from "../../../../components/HeaderTitle";
import { CardWithTabs } from "./CardWithTabs";

/*** Página ***/
const Devices = () => {
  const { t } = useTranslation();

  let { url } = useRouteMatch();
  const projectId = url.split("/")[1].split("-")[1];
  const defaultTab = url.split("/")[1].split("-")[2];
  const refName = url.split("/")[2];
  const ucName = (new URLSearchParams(
    useLocation().search
  ).get('name'))?.replace(/-/g, ' ');
  
  const [searchbar, setSearchbar] = useState("");
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [loadingDevices, setLoadingDevices] = useState(true);
  const [projInfo, setProjInfo] = useState();
  const [loadProject, setLoadProject] = useState(true);
  const [devices, setDevices] = useState();
  const [projectName, setProjectName] = useState();
  const [isCardLoading, setCardLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(defaultTab);

  const source = axios.CancelToken.source();
  //FUNÇÃO PARA FILTRAR DISPOSITIVOS
  async function handleFilterDevices(type) {
    try {
      await axios
        .get("/deviceTypeFilter", {
          cancelToken: source.token,
          params: {
            projId: projectId,
            type: type,
          },
        })
        .then((res) => {
          setFilteredDevices(res.data);
          setLoadingDevices(true);
        })
        .catch((err) => {
          setLoadingDevices(false);
          console.log(err);
        });
    } catch (err) {
      if (axios.isCancel(err)) {
      } else {
        throw err;
      }
    }
  }

  // busca dados do projeto
  useEffect(() => {
    axios
      .get("/projectModules", {
        params: {
          projId: projectId,
        },
      })
      .then((res) => {
        setProjInfo(res.data);
        setLoadProject(false);
        setLoadingDevices(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadProject(false);
      });
  }, [projectId]);

  // busca dispositivos
  useEffect(() => {
    axios
      .get("/deviceViewRestricted", {
        params: {
          projId: projectId,
        },
      })
      .then((res) => {
        setDevices(res.data.devices);
        setProjectName(res.data.projectName);
        setLoadingDevices(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          toastr.warning(
            err.response.data.messageHead,
            err.response.data.messageBody
          );
        }
      })
      .then(() => setCardLoading(false));
  }, [projectId]);

  return (
    <Container fluid>
      {loadProject || isCardLoading ? (
        <div className="text-center">
          <Spinner style={{ marginTop: "350px" }} color="primary" />
        </div>
      ) : !devices ? (
        <div className="pt-7">
          <Row xl="4" md="3" xs="1">
            <Col>
              <Card className="text-center">
                <CardBody className="mt-3">{t("devices.noProject")}</CardBody>

                <CardFooter>
                  <Link to="/projects">
                    <Button color="primary">{t("devices.back")}</Button>
                  </Link>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <Header>

            <div className="m-3">
              <HeaderTitle>
                {refName === "sharing"
                  ? t("headerPlant.consumptionSharing")
                  : refName === "meter"
                  ? ucName ? ucName :t("headerPlant.refMeter")
                  : refName === "uc" 
                  ? ucName ? ucName : t("headerPlant.consumerUnit")
                  : projectName
                }            
              </HeaderTitle>
              
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/">{t("sidebar.home")}</Link>
                </BreadcrumbItem>

                { activeTab !== "default" &&
                <BreadcrumbItem>
                  <Link to={`/${activeTab}`}>{t("sidebar.projects")}</Link>
                </BreadcrumbItem>
                } 
    
                <BreadcrumbItem>
                  <Link to={`/project-${projectId}-${activeTab}`}>
                    {activeTab === "default"
                      ? t("sidebar.devices")
                      : activeTab === "solar"
                      ? t("solar.solar")
                      : activeTab === "noBreak"
                      ? t("devices.nobreak")
                      : activeTab === "water"
                      ? t("projects.water")
                      : activeTab === "energy"
                      ? t("projects.energyBreadcrumb")
                      : null}
                    </Link>
                </BreadcrumbItem>
                
                <BreadcrumbItem active>
                  {refName === "sharing"
                    ? t("headerPlant.consumptionSharing")
                    : refName === "meter"
                    ? t("headerPlant.refMeter")
                    : refName === "uc" 
                    ? t("headerPlant.consumerUnit")
                    : null
                  }
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
            
            {!devices.length ? (
            <Row xl="4" md="3" xs="1">
              <Col>
                <Card className="text-center">
                  <CardBody className="mt-3">{t("devices.noDevices")}</CardBody>

                  <CardFooter>
                    <Link to="/projects">
                      <Button color="primary">{t("devices.back")}</Button>
                    </Link>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            ) : (
              <CardWithTabs
                loadingDevices={loadingDevices}
                setLoadingDevices={setLoadingDevices}
                handleFilterDevices={handleFilterDevices}
                devices={devices}
                filteredDevices={filteredDevices}
                setFilteredDevices={setFilteredDevices}
                searchbar={searchbar
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")}
                isCardLoading={isCardLoading}
                projectId={projectId}
                activeTab={activeTab}
              />
            )}
            
            {/* <div
              className="d-flex align-items-center justify-content-start"
              style={{ width: "250px", height: "px" }}
            >
              <Search
                className="text-muted"
                width={16}
                height={16}
                style={{
                  display:
                    activeTab === "default" && devices.length
                      ? "block"
                      : "none",
                }}
              />
              <Input
                style={{
                  display:
                    activeTab === "default" && devices.length
                      ? "block"
                      : "none",
                }}
                type="text"
                placeholder={t("devices.searchDevices")}
                aria-label="Search"
                className="form-control-lite"
                value={searchbar}
                onChange={(e) => setSearchbar(e.target.value)}
              />
            </div> */}
            {/* <div
              className="d-flex align-items-center justify-content-start"
              style={{ width: "250px", height: "px" }}
            >
              <Search
                className="text-muted"
                width={16}
                height={16}
                style={{
                  display:
                    activeTab === "climate" && devices.length
                      ? "block"
                      : "none",
                }}
              />
              <Input
                style={{
                  display:
                    activeTab === "climate" && devices.length
                      ? "block"
                      : "none",
                }}
                type="text"
                placeholder={t("devices.searchDevices")}
                aria-label="Search"
                className="form-control-lite"
                value={searchbar}
                onChange={(e) => setSearchbar(e.target.value)}
              />
            </div> */}
          </Header>
        </div>
      )}
    </Container>
  );
};

export default Devices;
