import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Card,
  CardHeader,
  CardBody,
  Spinner,
} from "reactstrap";
import { resetValidateFields } from "../../../../../../utils/functions";
import { UnitConsumers } from "./components/UnitConsumers";
import { RefMeter } from "./components/RefMeter";

import { usePlantContext } from "../../PlantProvider";
import {
  createParams,
  listDevices,
  listDistributors,
  updateParams,
} from "../../PlantService";

export const PlantConfig = ({ open, toggle, projName, projId }) => {
  const { t } = useTranslation();
  const { params, setLoadingData, selectedDate, reload, loadingData } =
    usePlantContext();

  const [market, setMarket] = useState(null);
  const [group, setGroup] = useState(null);
  const [tariffModality, setTariffModality] = useState(null);
  const [peakContractedDemand, setPeakContractedDemand] = useState(null);
  const [offPeakContractedDemand, setOffPeakContractedDemand] = useState(null);
  const [taxRateIcms, setTaxRateIcms] = useState(null);
  const [taxRatePisPasep, setTaxRatePisPasep] = useState(null);
  const [taxRateCofins, setTaxRateCofins] = useState(null);

  const [energyDistributorId, setEnergyDistributorId] = useState(null);
  const [distributor, setDistributor] = useState(null);
  const [refMeterId, setRefMeterId] = useState(null);
  const [metersId, setMetersId] = useState([]);

  const [distributors, setDistributors] = useState([]);
  const [modalities, setModalities] = useState([]);
  const [groups, setGroups] = useState([]);

  const [devices, setDevices] = useState([]);
  const [ucs, setUcs] = useState(devices);
  const [refMeter, setRefMeter] = useState(devices);

  const [validateFields, setValidateFields] = useState([
    {
      path: null,
      msg: null,
    },
  ]);

  const getValuesParams = async () => {
    if (params !== 404) {
      setMarket(params.market);
      setGroup(params.group);
      setTariffModality(params.tariffModality);
      setPeakContractedDemand(params.peakContractedDemand);
      setOffPeakContractedDemand(params.offPeakContractedDemand);
      setEnergyDistributorId(params.energyDistributorId);
      setDistributor(params.distributor);
      setTaxRateIcms(params.taxRateIcms);
      setTaxRatePisPasep(params.taxRatePisPasep);
      setTaxRateCofins(params.taxRateCofins);
      setMetersId(JSON.parse(params.meters || []));
      setRefMeterId(JSON.parse(params.refMeter));
    }
    getDevices();
  };

  const getDevices = async () => {
    const devices = await listDevices(projId);
    const filteredDevices = devices.map((device) => {
      return {
        dvcId: device.dvcId,
        model: device.model,
        description: device.description,
        varlist: device.driver.varlist,
        energyStructure: device.driver.energyStructure
          ? device.driver.energyStructure
          : device.energyStructure,
      };
    });
    setDevices(filteredDevices);
  };

  const getDistributors = async (projId) => {
    const distributors = await listDistributors(projId);
    setDistributors(distributors);

    const distributor = distributors.find(
      (distributor) => distributor.name === params.distributor
    );
    if (distributor) {
      setModalities(JSON.parse(distributor.modalities));
      setGroups([]);
    }
  };

  async function handleCreate(projId) {
    setValidateFields([]);
    const preferences = {
      default: [
        "consumption",
        "demand",
        "powerFactor",
        "devicesInfo",
        "listMeters",
      ],
    };
    const params = await createParams(setValidateFields, setLoadingData, {
      projId,
      monthRef: !selectedDate ? new Date() : selectedDate,
      refMeter,
      market,
      group,
      tariffModality,
      energyDistributorId,
      peakContractedDemand,
      offPeakContractedDemand,
      taxRateIcms,
      taxRatePisPasep,
      taxRateCofins,
      meters: ucs || [],
      preferences,
    });

    if (params?.status !== 400) {
      toggle();
      reload();
    }
  }

  async function handleUpdate(projId) {
    setValidateFields([]);
    const params = await updateParams(setValidateFields, setLoadingData, {
      projId,
      monthRef: !selectedDate ? new Date() : selectedDate,
      refMeter,
      market,
      group,
      tariffModality,
      energyDistributorId,
      peakContractedDemand,
      offPeakContractedDemand,
      taxRateIcms,
      taxRatePisPasep,
      taxRateCofins,
      meters: ucs || [],
    });

    if (params?.status !== 400) {
      toggle();
      setLoadingData(true);
      reload();
    }
  }

  useEffect(() => {
    getValuesParams();
    getDistributors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projId]);

  return (
    <>
      {params && (
        <Modal isOpen={open} toggle={toggle} size="lg">
          <ModalHeader>
            <Label>Configurações - {projName}</Label>
          </ModalHeader>

          <ModalBody>
            <Card>
              <CardHeader
                style={{ background: "#153D77", fontSize: "18px" }}
                className="text-white font-weight-bold"
              >
                {t("params.tariff")}
              </CardHeader>
              <CardBody>
                <Row className="mt-2">
                  <Col sm={6}>
                    <Label htmlFor="market">{t("params.market")}</Label>
                    <Input
                      id="market"
                      type="select"
                      name="market"
                      value={market}
                      onChange={(e) => {
                        setMarket(e.target.value);
                      }}
                    >
                      <option hidden>{market ?? t("params.select")}</option>
                      <option value="Cativo">Cativo</option>
                    </Input>
                  </Col>
                  <Col sm={6}>
                    <Label htmlFor="modality">{t("params.distributor")}</Label>
                    <Input
                      id="modality"
                      type="select"
                      name="powerCompany"
                      value={energyDistributorId}
                      onChange={(e) => {
                        const distributorId = parseInt(e.target.value);
                        setEnergyDistributorId(distributorId);
                        const distributor = distributors.filter(
                          (distributor) => distributor.id === distributorId
                        );
                        setModalities(JSON.parse(distributor[0].modalities));
                        setTariffModality("");
                        setGroup("");
                      }}
                    >
                      <option hidden>
                        {distributor ?? t("params.select")}
                      </option>

                      {distributors.map((distributor) => (
                        <option key={distributor.id} value={distributor.id}>
                          {distributor.name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={6}>
                    <Label htmlFor="tariff">{t("params.tariffModality")}</Label>
                    <Input
                      id="tariff"
                      type="select"
                      name="tariffModality"
                      value={tariffModality}
                      disabled={modalities.length === 0}
                      onChange={(e) => {
                        setTariffModality(e.target.value);
                        const modalitySelected = distributors.find(
                          (e) => e.id === energyDistributorId
                        );
                        const parsedTariff =
                          JSON.parse(modalitySelected.tariffs) || [];

                        const newArray = [];
                        parsedTariff.forEach((tariff) => {
                          if (
                            tariff.modalidade === e.target.value &&
                            !newArray.includes(tariff.subgrupo)
                          ) {
                            newArray.push(tariff.subgrupo);
                          }
                        });

                        setGroup("");
                        setGroups(newArray);
                      }}
                    >
                      <option hidden>
                        {tariffModality ?? t("params.select")}
                      </option>
                      {modalities?.map((modality) => (
                        <option key={modality} value={modality}>
                          {modality}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col sm={6}>
                    <Label htmlFor="group">{t("params.subgroup")}</Label>
                    <Input
                      id="group"
                      type="select"
                      name="group"
                      value={group}
                      disabled={groups.length === 0}
                      onChange={(e) => {
                        setGroup(e.target.value);
                      }}
                    >
                      <option hidden>{group ?? t("params.select")}</option>
                      {groups?.map((group) => (
                        <option key={group} value={group}>
                          {group}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader
                style={{ background: "#153D77", fontSize: "18px" }}
                className="text-white font-weight-bold"
              >
                {t("params.refMeter")}
              </CardHeader>
              <CardBody>
                <RefMeter
                  devices={devices}
                  refMeterId={refMeterId}
                  setRefMeter={setRefMeter}
                  refMeter={refMeter}
                />
              </CardBody>
            </Card>

            <Card>
              <CardHeader
                style={{ background: "#153D77", fontSize: "18px" }}
                className="text-white font-weight-bold"
              >
                {t("params.unitConsumers")}
              </CardHeader>
              <CardBody>
                <UnitConsumers
                  devices={devices}
                  metersId={metersId}
                  setUcs={setUcs}
                  ucs={ucs}
                  refMeter={refMeter}
                />
              </CardBody>
            </Card>

            <Card>
              <CardHeader
                style={{ background: "#153D77", fontSize: "18px" }}
                className="text-white font-weight-bold"
              >
                {t("params.contract")}
              </CardHeader>
              <CardBody>
                <Row className="mt-2 d-flex justify-content-center">
                  <Col className="d-flex flex-column" sm={6}>
                    <Label htmlFor="peakDemand">
                      {t("params.contractPeak")}
                    </Label>
                    <Input
                      type="number"
                      mask="99.999,99"
                      id="peakDemand"
                      name="peakContractedDemand"
                      value={peakContractedDemand}
                      onChange={(e) => {
                        setPeakContractedDemand(e.target.value);
                      }}
                      onFocus={(e) => {
                        resetValidateFields(
                          "peakContractedDemand",
                          validateFields,
                          setValidateFields
                        );
                      }}
                    />
                    {validateFields.map((item, index) => {
                      return item.path === "peakContractedDemand" ? (
                        <small key={index} className="text-danger d-block">
                          {item.msg}
                        </small>
                      ) : null;
                    })}
                  </Col>
                  <Col className="d-flex flex-column" sm={6}>
                    <Label htmlFor="offPeakDemand">
                      {t("params.contractOffPeak")}
                    </Label>
                    <Input
                      type="number"
                      mask="99.999,99"
                      id="offPeakDemand"
                      name="offPeakContractedDemand"
                      value={offPeakContractedDemand}
                      onChange={(e) => {
                        setOffPeakContractedDemand(e.target.value);
                      }}
                      onFocus={(e) => {
                        resetValidateFields(
                          "offPeakContractedDemand",
                          validateFields,
                          setValidateFields
                        );
                      }}
                    />
                    {validateFields.map((item, index) => {
                      return item.path === "offPeakContractedDemand" ? (
                        <small key={index} className="text-danger d-block">
                          {item.msg}
                        </small>
                      ) : null;
                    })}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="d-flex flex-column" sm={4}>
                    <Label htmlFor="peakDemand">
                      {t("params.taxRateIcms")}
                    </Label>
                    <Input
                      type="number"
                      id="taxRateIcms"
                      name="taxRateIcms"
                      value={taxRateIcms}
                      onChange={(e) => {
                        setTaxRateIcms(e.target.value);
                      }}
                    />
                  </Col>
                  <Col className="d-flex flex-column" sm={4}>
                    <Label htmlFor="peakDemand">
                      {t("params.taxRatePisPasep")}
                    </Label>
                    <Input
                      type="number"
                      id="taxRatePisPasep"
                      name="taxRatePisPasep"
                      value={taxRatePisPasep}
                      onChange={(e) => {
                        setTaxRatePisPasep(e.target.value);
                      }}
                    />
                  </Col>
                  <Col className="d-flex flex-column" sm={4}>
                    <Label htmlFor="peakDemand">
                      {t("params.taxRateCofins")}
                    </Label>
                    <Input
                      type="number"
                      id="taxRateCofins"
                      name="taxRateCofins"
                      value={taxRateCofins}
                      onChange={(e) => {
                        setTaxRateCofins(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </ModalBody>

          <ModalFooter>
            {params !== 404 ? (
              <Button
                color="primary"
                disabled={!refMeter || !group}
                onClick={() => !loadingData && handleUpdate(projId)}
              >
                {loadingData ? <Spinner size="sm" /> : t("params.save")}
              </Button>
            ) : (
              <Button
                color="primary"
                disabled={!refMeter || !group}
                onClick={() => !loadingData && handleCreate(projId)}
              >
                {loadingData ? <Spinner size="sm" /> : t("params.create")}
              </Button>
            )}
            <Button color="secondary" onClick={toggle}>
              {t("params.cancel")}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
