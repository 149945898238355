import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

export const SortIcon = {
    sortCaret: (order) => (!order) 
      ? <FontAwesomeIcon
        className={'fa-xs ml-1'}
        icon={faSort}
      />
      : (order === 'asc')
        ? <FontAwesomeIcon
          className={'fa-xs ml-1'}
          icon={faCaretDown}
        />
        : <FontAwesomeIcon
            className={'fa-xs ml-1'}
            icon={faCaretUp}
          />
  }