import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { useEffect } from "react";
import moment from 'moment';

const ConsumChart = ({ registers = [], dashName }) => {

  useEffect(() => {
    let root = am5.Root.new("chartdiv" + dashName);
    root._logo.dispose();
    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: root.verticalLayout,
      })
    );

    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    let data = registers;

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "timestamp",
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    function makeSeries(name, fieldName, stacked) {

      const formattedData = data.map(item => {
        return {
          ...item,
          formattedTimestamp: moment(item.timestamp, 'ddd, DD/MM').format('DD/MM/YYYY')
        };
      });

      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "timestamp",
        })
      );

      series.columns.template.setAll({
        tooltipText: "[bold]{formattedTimestamp}[/]\n{name}: {valueY} m³",
        width: am5.percent(30),
        tooltipY: 0,
        strokeOpacity: 0,
      });

      series.data.setAll(formattedData);

      series.appear(1000, 100);

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            text: "",
            fontWeight: "bold",
            fill: am5.color(0x000000),
            centerY: am5.percent(100),
            centerX: am5.percent(50),
            populateText: true,
          }),
        });
      });

      legend.data.push(series);
    }

    makeSeries(
      "Consumo Total", 
      "consumption", 
      false
    );

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [dashName, registers]);

  return (
    <div
      id={"chartdiv" + dashName}
      style={{ width: "100%", height: "500px" }}
    ></div>
  );
};

export default ConsumChart;
