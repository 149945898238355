import React, { useState } from "react";
import {
  Button,
  Form, Input,
  Modal, ModalHeader, ModalBody, ModalFooter, 
} from "reactstrap";
import axios from 'axios';
import { toastr } from "react-redux-toastr";
import { useTranslation } from 'react-i18next';


export const ModalOffAlarm = ({ open, toggle, alarm, tableReload }) => {
/****** MODAL DE DESLIGAR ALARME ******/
  const { t } = useTranslation();
  
  const [captchaInput, setCaptchaInput] = useState('');

  return (
    <Modal 
      isOpen={open}
      toggle={toggle}
      className={"modal-alarmoff"}
    >
      <Form
        onSubmit={e => {
          e.preventDefault();

          if (captchaInput === 'ALARMOFF') {
            axios.put('turnOffAlarm/', JSON.stringify({
              alarmId: alarm.id
            }))
            .then(res => {
              toastr.success(res.data.messageHead, res.data.messageBody);
              tableReload();
              toggle();
            })
            .catch(err => {
              console.log(err);
              console.log(err.response);
            })
          } else {
            toastr.warning(
              `${t('alarms.error')}!`, 
              `${t('alarms.wrongCaptcha')}.`
            );
            setCaptchaInput('')
          }

        }}
      >
        <ModalHeader toggle={toggle}>
          {t('alarms.turnOffAlarm')} {alarm.alarmName}
        </ModalHeader>

        <ModalBody className="text-center mx-6">

          <div className="m-sm-4">
            <h6>{t('alarms.att')}!</h6>
            <p className="mb-0">
              {t('alarms.captchaOff')}:
            </p>

            {/*** CAMPO DE CAPTCHA ***/}
            <Input 
              type="text" 
              value={captchaInput}
              onChange={e => setCaptchaInput(e.target.value)}
            />
          </div>
          
        </ModalBody>

        <ModalFooter>
          {/*** BOTÃO DE CANCELAR ***/}
          <Button 
            color="primary"
            name="btnCancel"
            type="button"
            className="mr-1 mb-1" 
            onClick={toggle}
            outline
          >
            {t('alarms.cancel')}
          </Button>

          {/*** BOTÃO DE ENVIAR DADOS E FECHAR O MODAL ***/}
          <Button
            color="primary"
            name="btnClose"
            type="submit"
          >
            {t('alarms.saveTurnOffAlarm')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
