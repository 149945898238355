import React from "react";
import { useTranslation } from 'react-i18next';
import {
  Modal, Col, Row, CardHeader, ModalHeader, ModalBody, CardBody
} from "reactstrap";

// import "../../../../assets/scss/3-components/_antd.scss";
// import "../../solarModule/devicesModal/DevicesModal.scss";
// import '../../../../../assets/scss/6-solar/DevicesModal.scss';

import LastComm from "./components/LastComm";
import Processed from "./components/Processed";
import ActivePower from "./components/ActivePower";
import EnergyDaily from "./components/EnergyDaily";
import LifetimeEnergy from "./components/LifetimeEnergy";
import Frequency from "./components/Frequency";
import Temperature from "./components/Temperature";
import PowerFactory from "./components/PowerFactory";
import VoltageFaseA from "./components/VoltageFaseA";
import VoltageFaseB from "./components/VoltageFaseB";
import VoltageFaseC from "./components/VoltageFaseC";
import CurrentFaseA from "./components/CurrentFaseA";
import CurrentFaseB from "./components/CurrentFaseB";
import CurrentFaseC from "./components/CurrentFaseC";
import PVCards from "./components/PVCards";
import CurrentCards from "./components/CurrentCards";


/****** MODAL DE DETALHES DO DEVICE ******/
const DevicesModal = ({ open, toggle, data, allData, state }) => {
  const { t } = useTranslation();

let dataGeral = data.deviceName

  // const StyleModal = (window.innerWidth < 480) ? "" : "";

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      className={`modal-dialog`}
      size="xl"
      style={{marginTop: "10px", marginBottom: "-4px"}}
    >
        <ModalHeader 
          toggle={toggle}
          style={{paddingLeft:"32px", borderBottom:"none", paddingBottom: "6px", paddingTop: "10px"}}
        >
          {data.deviceName}
        </ModalHeader>
      <ModalBody style={{paddingTop: "0px"}}>
        <div style={{paddingLeft: "15px", paddingRight: "15px", paddingTop: "0px", marginBottom: "0px"}}>
          <Row sm="12" lg="12" style={{paddingRight: "20px"}}>
            <Col>
              <LastComm allData={allData} state={state} data={data}/>
            </Col>
            <Col>
              <Processed allData={allData} state={state}/>
            </Col>
            <Col>
              <ActivePower allData={allData} ActivePower={data.activePower}/>
            </Col>
            <Col>
              <EnergyDaily allData={allData} EnergyDaily={data.dailyEnergy}/>
            </Col>
            <Col>
              <LifetimeEnergy allData={allData} LifetimeEnergy={data.totalEnergy}/>
            </Col>
            <Col>
              <Frequency allData={allData} data={data}/>
            </Col>
            <Col>
              <Temperature allData={allData} data={data}/>
            </Col>
            <Col>
              <PowerFactory allData={allData} data={data}/>
            </Col>
            
          </Row>
          <Row 
            style={{paddingLeft: "10px", justifyContent:"space-between", paddingRight:"30px", paddingTop: "15px"}}

          >
            <div>
              <CardHeader style={{paddingLeft:"1px", paddingTop: "0px", paddingBottom: "2px"}}>
                <h5>{t('solar.gridVoltage')}</h5>
              </CardHeader>
              <Row>
                <Col>
                  <VoltageFaseA allData={allData} VoltageFaseA={data.A_V}/>
                </Col>
                <Col>
                  <VoltageFaseB allData={allData} VoltageFaseB={data.B_V}/>
                </Col>
                <Col>
                  <VoltageFaseC allData={allData} VoltageFaseC={data.C_V}/>
                </Col>
              </Row>
            </div> 
            <div>
              <CardHeader style={{paddingLeft: "0px", paddingTop: "0px", paddingBottom: "2px"}}>
                <h5>{t('solar.gridCurrent')}</h5>
              </CardHeader>
              <Row>
                <Col>
                  <CurrentFaseA allData={allData} CurrentFaseA={data.A_Curr}/>
                </Col>
                <Col>
                  <CurrentFaseB allData={allData} CurrentFaseB={data.B_Curr}/>
                </Col>
                <Col>
                  <CurrentFaseC allData={allData} CurrentFaseC={data.C_Curr}/>
                </Col>
              </Row>
            </div> 
          </Row>
          {(dataGeral)
            ? <div>
                <CardHeader className="headerdeviceModal" style={{paddingTop:"0px", paddingLeft: "27px", top: "8px", width: "271px", position: "relative", marginLeft: "-120px"}}>
                    <h6>{t('solar.inputVoltage')}</h6>
                  </CardHeader>
                <Row id="larguraDiv" className="ROW NO MODAL PV styleScroll" style={{overflowX:"scroll", marginRight:"5px", marginLeft:"28px", marginTop: "-25px"}}>

                  <PVCards data={data}/>
                </Row>
                <CardHeader className="headerdeviceModal" style={{paddingTop:"0px", paddingLeft: "10px", height: "37px", top: "14px", width: "271px", position: "relative", marginLeft: "-120px"}}>
                    <h6>{t('solar.inputCurrent')}</h6>
                  </CardHeader>
                <Row className="ROW NO MODAL PC" style={{overflowX:"scroll", marginRight:"5px", marginLeft:"28px", marginTop: "-10px", paddingBottom: "10px"}}>

                  <CurrentCards data={data}/>
                </Row>
              </div>
            : <CardBody className='text-center'> {t('solar.noData')} </CardBody>   
          }
        </div>
      </ModalBody>
    </Modal>
  );
}
  
export { DevicesModal };