const enUsHomeTranslations = {
    homePage: {
        title: "Home Page"
    },
    menuCard: {
        myAccount: "My Account",
        sharing: "Consumption Sharing"
    },
    public: {
        expand: "Expand",
        lastComm: "Last Communication",
        deviceModel: "Model",
        deviceStatus: "Status",
    },
    energy: {
        energyVision: "Energy Module Overview",
        energy: "Energy",
        pvEnergy: "Energy Plant",
        totalConsumption: "Total Consumption",
        peak: "Peak consumption",
        offPeak: "Offpeak consumption",
        tooltipOff: "Communication failure on all devices",
        tooltipOn: "All devices are communicating",
        tooltipWarning: "Communication failure on one or more devices",
        noDataPlants: "There is no Energy type plants",
    }
}

export default enUsHomeTranslations;
