import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { useTranslation } from "react-i18next";
import { CardBody, CardHeader, CardTitle, Spinner } from "reactstrap";

import {
  ComposedChart,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import useChartHeight from "../hook";
import { formatEnergy } from "../../../../../utils/functions";

const IrradiationChart = ({ hours, energy, irradiation }) => {
  const { t } = useTranslation();

  // Preparar os dados
  const data = hours.map((element, index) => ({
    time: element.toString().padStart(2, "0"),
    generation: energy[index] || 0,
    irradiance: irradiation[index] || 0,
  }));

  const minGeneration = Math.min(...energy);
  const maxGeneration = Math.max(...energy);
  const minIrradiance = Math.min(...irradiation);
  const maxIrradiance = Math.max(...irradiation);

  const height = useChartHeight()


  return (
    <ResponsiveContainer width="100%" height={height}>
      <ComposedChart data={data}>
        <defs>
          <linearGradient id="colorGeneration" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#E98C45" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#E98C45" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" />
        <YAxis
          yAxisId="left"
          orientation="left"
          domain={[minGeneration, maxGeneration]} // Limites para geração
          width={50}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          domain={[minIrradiance, maxIrradiance]} // Limites para irradiação
          width={50}
        />
        <Tooltip />
        <Legend />
        <Area
          yAxisId="left"
          type="monotone"
          dataKey="generation"
          name={`${t("solar.generation")} (kWh)`}
          stroke="#E98C45"
          fill="url(#colorGeneration)"
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="irradiance"
          name={`${t("solar.irradiance")} (W/m²)`}
          stroke="#BF5D5A"
          dot={false} // Remover os pontos da linha
          strokeWidth={2}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const GeraçãoXirradiação = ({ projectId }) => {
  const { t } = useTranslation();
  const { data, status } = useQuery({
    queryKey: ["readSolarEnergyDay", { projId: projectId }],
    queryFn: () =>
      axios
        .get("/readSolarEnergyDay", {
          params: {
            projId: projectId,
          },
        })
        .then((res) => res.data),
    staleTime: 1000 * 60 * 60, // 1 hour
    refetchInterval: 1000 * 60 * 60, // 1 hour
  });
  return (
    <>
      <CardHeader >
        <CardTitle tag="h5">{t("solar.generationXirradiationDay")}</CardTitle>
      </CardHeader>

      <CardBody>
        {status === "loading" ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <IrradiationChart
            hours={data.dailyDataEnergy.times}
            energy={data.dailyDataEnergy.energy}
            irradiation={data.dailyDataEnergy.irradiation}
          />
        )}
      </CardBody>
    </>
  );
};

export default GeraçãoXirradiação;
