import React from "react";
import SubMenuPlant from "./../SubMenu";
import SubMenuRefMeter from "./../RefMeter/SubMenu";
import SubMenuConsumerUnit from "../ConsumerUnit/SubMenu";

export const SubMenuGeneral = ({ plant, path, backgroundColor }) => {

  return (
        <>
          {/* //NOTE: Submenu para planta */}
          {plant?.companyId && !path && <SubMenuPlant plant={plant} bgColor={backgroundColor} />}

          {/* //NOTE: Submenu para para medidor de referência */}
          {plant?.companyId && path === `meter` && (
            <SubMenuRefMeter plant={plant} bgColor={backgroundColor} />
          )}

          {/* //NOTE: Submenu para unidade de consumo */}
          {plant?.companyId && path === `uc` && (
            <SubMenuConsumerUnit plant={plant} bgColor={backgroundColor}/>
          )}
        </>
  );
};
