import {
  Document,
  Font,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import iGoalLogo from "../../../../../../../assets/img/igoal-logo.png";
import {
  formatAvoidedCO2,
  formatEnergy,
  formatNumber,
} from "../../../../../../../utils/functions";

Font.register({
  family: "Inter",
  fonts: [
    {
      src: "http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf",
      fontWeight: 100,
    },
    {
      src: "http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf",
      fontWeight: 200,
    },
    {
      src: "http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf",
      fontWeight: 300,
    },
    {
      src: "http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf",
      fontWeight: 400,
    },
    {
      src: "http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf",
      fontWeight: 500,
    },
    {
      src: "http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf",
      fontWeight: 600,
    },
    {
      src: "http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf",
      fontWeight: 700,
    },
    {
      src: "http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf",
      fontWeight: 800,
    },
    {
      src: "http://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf",
      fontWeight: 900,
    },
  ],
});
// Definir os estilos
const styles = StyleSheet.create({
  viewer: {
    width: "100%",
    height: Math.floor(window.innerHeight * 0.67),
  },
  page: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: "#f0f0f0",
  },
  section: {
    marginBottom: 20,
  },
  companyHeader: {
    backgroundColor: "#577cb8",
    padding: 10,
    borderRadius: 3,
    marginBottom: 10,
  },
  companyTitle: {
    fontSize: 18,
    color: "#fff",
    fontWeight: 600,
    fontFamily: "Inter",
  },
  plantCard: {
    backgroundColor: "#fff",
    padding: 15,
    borderRadius: 3,
    marginBottom: 10,
  },
  plantTitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#333",
    fontFamily: "Inter",
  },
  plantDataRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 4,
  },
  plantLabel: {
    fontSize: 10,
    color: "#fff",
    textAlign: "center",
    fontFamily: "Inter",
    fontWeight: 600,
  },
  plantValue: {
    fontSize: 10,
    color: "#333",
    border: "1px solid #ddd",
    textAlign: "center",
    fontFamily: "Inter",
  },
  header: {
    fontSize: 22,
    color: "#577cb8",
    fontWeight: 600,
    fontFamily: "Inter",
  },
  header_plant: {
    fontSize: 18,
    marginBottom: 15,
    color: "#577cb8",
    fontWeight: 500,
    fontFamily: "Inter",
    borderBottom: "1px solid #ddd",
    paddingHorizontal: 5,
    paddingTop: 5,
  },
  header_chart: {
    fontSize: 14,
    marginBottom: 10,
    color: "#333",
    fontWeight: 300,
    fontFamily: "Inter",
  },
  chart: {
    width: `100%`,
    height: 100,
    borderBottom: "1px solid #ddd",
    borderLeft: "1px solid #aaa",
    borderBottomLeftRadius: 10,
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 30,
    paddingHorizontal: 15,
    gap: 5,
  },
  bar: {
    backgroundColor: "#82ca9d",
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    width: "100%",
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
    color: "#777",
    fontFamily: "Inter",
  },
});

const PlantInfo = ({ plant, index }) => {
  const { t } = useTranslation();
  const maxEnergy = Math.max(...plant.map((p) => p.plantMonthlyEnergy));

  return (
    <View style={styles.plantCard} break={index > 0}>
      <View>
        <Text style={styles.header_plant}>{plant[0].plantName}</Text>
        <Text style={styles.header_chart}>{`${t("solar.energyLabel")}`}</Text>
        <View style={styles.chart}>
          {plant.map((p, i) => {
            const height = (p.plantMonthlyEnergy / maxEnergy) * 100;
            const heightString = Number.isNaN(height) ? 0 : `${height}%`;

            return (
              <View
                style={[
                  styles.bar,
                  {
                    height: heightString,
                  },
                ]}
                key={p.monthRef + p.plantName + i}
              >
                <Text
                  style={{
                    position: "absolute",
                    bottom: 10,
                    left: 0,
                    right: 0,
                    textAlign: "center",
                    fontSize: 8,
                  }}
                >
                  {formatEnergy(p.plantMonthlyEnergy)}
                </Text>
                <Text
                  style={{
                    position: "absolute",
                    bottom: -10,
                    left: 0,
                    right: 0,
                    textAlign: "center",
                    fontSize: 8,
                  }}
                >
                  {moment(p.monthRef, "MMMM/YYYY").format("MMM/YY")}
                </Text>
              </View>
            );
          })}
        </View>
      </View>

      <View>
        <View style={{ borderWidth: 1, borderColor: "#ddd", marginBottom: 10 }}>
          <View style={{ flexDirection: "row", backgroundColor: "#577cb8" }}>
            <Text style={[styles.plantLabel, { flex: 1, padding: 5 }]}>
              {t("solar.income2")}
            </Text>
            <Text style={[styles.plantLabel, { flex: 1, padding: 5 }]}>
              {t("solar.avoided")} {t("solar.emissions")}
            </Text>
            <Text style={[styles.plantLabel, { flex: 1, padding: 5 }]}>
              {t("solar.cultivated")} {t("solar.treesCultivated")}
            </Text>
            <Text style={[styles.plantLabel, { flex: 1, padding: 5 }]}>
              {t("solar.pr")}
            </Text>
            <Text style={[styles.plantLabel, { flex: 1, padding: 5 }]}>
              {t("solar.solarCapacity")}
            </Text>
            <Text style={[styles.plantLabel, { flex: 1, padding: 5 }]}>
              {t("solar.productivity")}
            </Text>
            <Text style={[styles.plantLabel, { flex: 1, padding: 5 }]}>
              {t("solar.month2")}
            </Text>
          </View>
          {plant.map((p, index) => (
            <View key={index} style={{ flexDirection: "row" }}>
              <Text style={[styles.plantValue, { flex: 1, padding: 5 }]}>
                R$ {formatNumber(p.plantIncome)}
              </Text>
              <Text style={[styles.plantValue, { flex: 1, padding: 5 }]}>
                {formatAvoidedCO2(p.plantAvoidedCO2)}
              </Text>
              <Text style={[styles.plantValue, { flex: 1, padding: 5 }]}>
                {formatNumber(p.plantCultivatedTrees)}
              </Text>
              <Text style={[styles.plantValue, { flex: 1, padding: 5 }]}>
                {(p.plantPerformanceRatio * 100).toFixed(1)} %
              </Text>
              <Text style={[styles.plantValue, { flex: 1, padding: 5 }]}>
                {formatEnergy(p.plantCapacity)}
              </Text>
              <Text style={[styles.plantValue, { flex: 1, padding: 5 }]}>
                {formatEnergy(p.plantProductivity)}
              </Text>
              <Text style={[styles.plantValue, { flex: 1, padding: 5 }]}>
                {moment(p.monthRef, "MMMM/YYYY").format("MMM/YY")}
              </Text>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
};

// Função para gerar o relatório PDF
const SolarReport = ({ data }) => {
  const { t } = useTranslation();

  return (
    <PDFViewer style={styles.viewer}>
      <Document
        title={t("solarReport.solarManagementReport")}
        author="SolarView"
        subject={t("solarReport.solarManagementReport")}
        keywords="solar, usinas, energia"
      >
        <Page size="A4" style={styles.page} wrap>
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              marginVertical: 20,
              position: "relative",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                width: "100%",
                objectFit: "contain",
                position: "absolute",
                left: 0,
              }}
            >
              <Image
                src={iGoalLogo}
                style={{
                  height: 50,
                  width: 130,
                  objectFit: "contain",
                }}
              />
            </View>
            <Text style={styles.header}>
              {t("solarReport.solarManagementReport")}
            </Text>
            <Text style={styles.subtitle}>
              {moment(data.start).format("MMM/YYYY")} -{" "}
              {moment(data.end).format("MMM/YYYY")}
            </Text>
          </View>
          {Object.keys(data.info).map((companyName, index) => (
            <View key={companyName} style={styles.section} break={index > 0}>
              <View style={styles.companyHeader}>
                <Text style={styles.companyTitle}>{companyName}</Text>
              </View>

              {Object.keys(data.info[companyName]).map((plantName, index) => (
                <PlantInfo
                  key={plantName}
                  plant={data.info[companyName][plantName]}
                  index={index}
                />
              ))}
            </View>
          ))}
          <Text
            fixed
            style={{
              position: "absolute",
              bottom: 10,
              left: 0,
              right: 10,
              textAlign: "right",
              color: "#777",
              fontSize: 10,
            }}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
          />
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default SolarReport;
