const { useState, useEffect } = require("react");

const useChartHeight = () => {
  const [height, setHeight] = useState(350);

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth <= 768) {
        setHeight(250);
        return;
      }
      if(window.innerHeight <= 900) {
        const windowHeight = window.innerHeight;

        const cardHeight =  (windowHeight - 652) / 2;
        setHeight(cardHeight);
        return;
      }
      const windowHeight = window.innerHeight;

      const cardHeight = (windowHeight - 580) / 2;
      setHeight(cardHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return height;
};

export default useChartHeight;