import React, { useState, useEffect, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Row, Col, DropdownToggle, DropdownMenu, DropdownItem,
  UncontrolledDropdown
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { toastr } from "react-redux-toastr";

import { ModalAlarmHistory } from "../ModalAlarmHistory";
import SoundEnable from "./components/SoundEnable";
import NotificationSound from '../../../../../../assets/sounds/notification-sound.mp3';
import MyCustomComponent from "./components/MyCustomComponent";
import { useSelector } from "react-redux";


const NotificationsAlarm = ({ deviceId }) => {

  const { t } = useTranslation();
  const currentTheme = useSelector((store) => store.theme.currentTheme);

  const [alarmCount, setAlarmCount] = useState(0);
  const [openModalAlarmHistory, setOpenModalAlarmHistory] = useState(false);

  let history = useHistory();
  let { url } = useRouteMatch();

  const audioPlayer = useRef(null);

  function searchAlarms() {
    axios.get(`/v2/alarms/listAlarmsHistory?devices=${deviceId}`)
      .then(res => {
        setAlarmCount(res.data.length);

        const newAlarms = res.data.filter(item => item.dateOccurred >= Date.now() / 1000 - searchInterval);
        
        function playAudio() {
          audioPlayer.current.play();
        };

        if (newAlarms.length) {
          const toastrOpts = {
            onShowComplete: () => (localStorage.getItem('soundOn') === '1')
              ? playAudio()
              : null,
            showCloseButton: true,
            closeOnToastrClick: false,
            timeOut: 0,
            tapToDismiss: false,
            removeOnHover: false,
            component: (
              <MyCustomComponent
                data={newAlarms}
              />
            )
          };
          newAlarms.forEach(alarm => {
            const date = new Date(alarm.dateOccurred * 1000)
            let dateFormat =("0"+date.getDate()).slice(-2)
            + '/' + ("0"+(date.getMonth()+1)).slice(-2)
            + '/' + date.getFullYear()
            + ' ' + ("0"+date.getHours()).slice(-2)
            + ':' + ("0"+date.getMinutes()).slice(-2)
            + ':' + ("0"+date.getSeconds()).slice(-2);
            
            if (alarm.priority === 1) { // alta
              toastr.error(alarm.description, ` valor: ${alarm.value}
              data: ${dateFormat},
               ${alarm.messageOn}
               , nome: ${alarm.deviceName}`, toastrOpts);
            }

            else if (alarm.priority === 2) { // media
              toastr.warning(alarm.description, ` valor: ${alarm.value}
              data: ${dateFormat},
               ${alarm.messageOn}
               , nome: ${alarm.deviceName}`, toastrOpts);
            }

            else { // baixa
              toastr.info(alarm.description, ` valor: ${alarm.value}
               data: ${dateFormat},
                ${alarm.messageOn}
                , nome: ${alarm.deviceName}`, toastrOpts);

            }

          })
        }
      })
      .catch(err => {
        console.log(err);
        console.log(err.response);
      });
  }

  function toggleModalAlarmHistory() {
    setOpenModalAlarmHistory(!openModalAlarmHistory);
  }

  const searchInterval = 10; // intervalo de tempo de busca de alarmes

  useEffect(() => {
    searchAlarms();

    const intervalSearch = setInterval(() => {
      searchAlarms();

      if (history.location.pathname !== url) {
        clearInterval(intervalSearch);
      }
    }, searchInterval * 1000);
    // eslint-disable-next-line
  }, []);

  return <>
    <UncontrolledDropdown nav inNavbar className="d-flex">
      <DropdownToggle nav className="p-0">
        <div
          className="cursor-pointer d-flex align-items-center justify-content-center px-1"
          onClick={() => searchAlarms()}
        >
          <FontAwesomeIcon 
          icon={faBell} 
          style={{ height: "18px", width: "18px" }}
          color={currentTheme.primary}
          />
          {(alarmCount) ? <span className="indicator" /> : ""}
        </div>
      </DropdownToggle>

      <DropdownMenu right className="dropdown-menu-lg py-0" >
        <div onClick={toggleModalAlarmHistory}>
            <DropdownItem>
              <Row className="align-items-center" >
                <Col xs={2}>
                  <FontAwesomeIcon
                    icon={faBell}
                    className={(alarmCount)
                      ? "text-danger"
                      : "text-success"
                    }
                    color={currentTheme.primary}
                  />
                </Col>

                <Col xs={10}>
                  <div className="text-dark">
                    {t('alarms.alarms')}
                  </div>

                  <div className="text-muted small mt-1">
                    {(alarmCount)
                      ? `${alarmCount} ${(alarmCount === 1)
                        ? t('alarms.activeAlarm')
                        : t('alarms.activeAlarms')}`
                      : t('alarms.noAlarms')
                    }
                  </div>
                </Col>
              </Row>
            </DropdownItem>
        </div>

        <DropdownItem divider />

        <SoundEnable />

      </DropdownMenu>
    </UncontrolledDropdown>

    {(openModalAlarmHistory) &&
      <ModalAlarmHistory
        open={openModalAlarmHistory}
        toggle={toggleModalAlarmHistory}
        devices={deviceId}
      />
    }

    <audio ref={audioPlayer} src={NotificationSound} />
  </>
};

export default NotificationsAlarm;