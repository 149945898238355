const enUsNocTranslations = {
  noc: {
    header: {
      title: "Network Operations Center",
      cardTitle: "Registration of Service Authorizations",
      createModalTitle: "Register Service Authorizations",
      editModalTitle: "Edit Service Authorizations",
      updateModalTitle: "Update Service Authorizations",
      commercialManagement: "Commercial Management",
      titleSA: "Service Authorizations",
    },
    main: {
      pageView: "View page with view of:",
      headerCards: {
        connect: "Connectivity",
        devices: "Devices",
        projects: "Projects",
        modules: "Modules",
        alarms: "Alarms",
        ucs: "Consumer Units",
      },
      accordion: {
        healthyPlants: "Healthy plants",
        unhealthyPlants: "Plants in attention",
        neverCommunicated: "Never communicated",
      },
    },
    labels: {
      online: "Online",
      offline: "Inactive",
      noCommunication: "No Communication",
      solar: "Solar",
      power: "Power",
      water: "Water",
      gas: "Gas",
      climate: "Climate",
      noActiveModules: "No Active Modules",
      highPriority: "High Priority",
      mediumPriority: "Medium Priority",
      lowPriority: "Low Priority",
      overview: "Overview",
    },
    modal: {
      processAt: "Processed at",
      generalConsumptionsUcs: "General Consumption of UCs",
      monthRef: "Reference Month",
      solar: {
        dailyEnergy: "Daily Energy",
        monthlyEnergy: "Monthly Energy",
        lifetimeEnergy: "Accumulated Energy",
        currentPower: "Current Power",
        installedPower: "Installed Power",
        monthlyEconomy: "Monthly Economy",
        avoidedEmissions: "Avoided Emissions",
      },
      water: {
        meteringConsumption: "Measured Consumption",
        commonAreaConsumption: "Common Area Consumption",
        dealerConsumption: "Dealer Consumption",
        consumption: "Consumo",
      },
      power: {
        totalOffPeakDemandReactive: "Total Off-Peak Reactive Demand",
        totalPeakDemandReactive: "Total Peak Reactive Demand",
      },
      graphic: {
        noData: "There is no data to display in the graph",
      },
    },
    form: {
      contract: "Contract",
      company: "Company",
      personResponsible: "Person Responsible",
      projectName: "Project Name",
      descriptionProject: "Project Description",
      typeContract: "Contract Type",
      deadlineForExecution: "Deadline for Execution",
      numberOfDevices: "Number of Devices",
      contractDuration: "Contract Duration",
      totalDuration: "Total Duration",
      monthValue: "Monthly Value",
      address: "Address",
      neighborhood: "Neighborhood",
      city: "City",
      state: "State",
      zipCode: "Zip Code",
      submit: "Submit",
      addFiles: "Add Files",
      update: "Update",
      selectCompany: "Select the company",
      modules: "Modules",
      editCard: "Edit Card",
      commercialResponsible: "Financial Manager",
    },
    error: {
      required: "Required field!",
      errorOccurred: "An error occurred!",
      tryAgain: "Try Again",
    },
    placeholder: {
      contract: "Select a contract",
      company: "Select a company",
      personResponsible: "Select a person responsible",
      projectName: "Enter the project name",
      descriptionProject: "Enter the project description",
      typeContract: "Select a contract type",
      deadlineForExecution: "Enter the deadline for execution",
      numberOfDevices: "Enter the number of devices",
      contractDuration: "Month: 0",
      totalDuration: "Enter the total duration",
      monthValue: "Enter the monthly value",
      address: "Enter the address",
      neighborhood: "Enter the neighborhood",
      city: "Enter the city",
      state: "Enter the state",
      zipCode: "Enter the zip code",
      commercialResponsible: "Select the financial manager",
    },
    steps: {
      step1: "Project",
      step2: "Modules",
      step3: "Contract",
      step4: "Endereço",
      next: "Next",
      back: "Back",
      cancel: "Cancel",
    },
    axiosMsg: {
      success: "Success",
      error: "Error",
      contractRegistered: "Contract registered successfully",
      errorRegisteringContract: "Error registering contract",
      errorReadCardValues: "Error reading card values",
      contractEdited: "Contract edited successfully",
      errorEditingContract: "Error editing contract",
    },
    card: {
      month: "Month(s)",
      as: "Contract",
      diagnostico: "Diagnostic",
      cadastro: "Registration",
      instalacao: "Installation",
      monitoramento: "Monitoring",
      manutencao: "Maintenance",
      movedBy: "Moved By",
      createdBy: "Created By",
    },
    commercialScreen: {
      projectQuantities: "Project quantities",
      financialValues: "Financial values",
      monthlyAvarageContracts: "Monthly average of contracts",
      monthlyDevices: "Monthly devices",
      generalContractData: "General contract data",
      futureMonthlyPayments: "Future monthly payments",
      averageContractsPerMonth: "Average contracts per month",
    },
    tooltip: {
      totalValueDemocontracts: "Total value of demo contracts",
      totalContractValueCurrentMonth:
        "Total contract value for the current month",
      averageValueDemoContracts: "Average value of demo contracts",
      averageContractValue: "Average contract value",
    },
  },
};

export default enUsNocTranslations;
