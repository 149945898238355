/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Maximize2 } from "react-feather";
import { useTranslation } from "react-i18next";
import {
  Button,
  CardHeader,
  CardTitle,
  Modal,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap";
import {
  formatTimestamps,
  getStartEndMonth,
} from "../../../../../../utils/functions.js";
import { usePlantContext } from "../../PlantProvider.js";
import { useRefMeterContext } from "../RefMeterProvider";
import { readBarChartDemand } from "../RefMeterService";
import { DateRangeSelector } from "../../../../../../components/DateRangeSelector.js";
import { useSelector } from "react-redux";
import { COLOR_CHARTS } from "../../../../../../utils/Constants.js";
const moment = require("moment");

export const BarChartDemand = ({
  windowObervable,
  isModal = false,
  origin,
  viewChart,
}) => {
  const { t } = useTranslation();
  const currentTheme = useSelector((store) => store.theme.currentTheme);

  const { dvcId } = useRefMeterContext();
  const { selectedDate } = usePlantContext();

  const [loadingChart, setLoadingChart] = useState(false);
  const [chart, setChart] = useState(null);
  const [start, setStart] = useState(
    moment(selectedDate).startOf("month").toDate()
  );
  const [end, setEnd] = useState(moment(selectedDate).endOf("day").toDate());
  const [range, setRange] = useState("days");
  const [toggleModal, setToggleModal] = useState(false);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  useEffect(() => {
    if (selectedDate) {
      setStart(moment(selectedDate).startOf("month").toDate());
      setEnd(moment(selectedDate).endOf("day").toDate());
    }
  }, [selectedDate]);

  useEffect(() => {
    if (dvcId) getChartData();
  }, [dvcId, start, end]);

  const getChartData = async () => {
    setLoadingChart(true);
    const chart = await readBarChartDemand(dvcId, start, end, range);
    if (chart) setChart(chart);
    if (!chart) setChart(null);
    setLoadingChart(false);
  };

  const data = {
    labels: chart ? formatTimestamps(chart.totalDemandTimestamp, range) : [],
    datasets: [
      {
        label: t("charts.offPeak"),
        data: chart?.offPeakDemand,
        borderWidth: 1,
        backgroundColor: COLOR_CHARTS.offPeak,
        fill: true,
        type: "bar",
      },
      {
        label: t("charts.peak"),
        data: chart?.peakDemand,
        borderWidth: 1,
        backgroundColor: COLOR_CHARTS.peak,
        fill: true,
        type: "bar",
      },
      {
        label: t("charts.contractPeak"),
        data: chart?.contractedPeakDemand,
        borderWidth: 2,
        fill: false,
        backgroundColor: `${COLOR_CHARTS.contractPeak}`,
        borderColor: `${COLOR_CHARTS.contractPeak}`,
        type: "line",
        pointStyle: `dash`,
        borderDash: [10,10]
      },
      {
        label: t("charts.contractOffPeak"),
        data: chart?.contractedOffPeakDemand,
        borderWidth: 2,
        fill: false,
        backgroundColor: `${COLOR_CHARTS.contractOffPeak}`,
        borderColor: `${COLOR_CHARTS.contractOffPeak}`,
        type: "line",
        pointStyle: `dash`,
        borderDash: [10,10]
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              return value.toLocaleString();
            },
          },
        },
      ],
    },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        padding: 30,
        fontSize: 14,
        marginBottom: 10,
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: function (tooltipItem, data) {
          const datasetIndex = tooltipItem.datasetIndex;
          const datasetLabel = data.datasets[datasetIndex].label || "";
          const value = tooltipItem.yLabel;
          return datasetLabel + ": " + value.toLocaleString();
        },
      },
    },
  };

  return (
    //NOTE: Só exibe se existir a estrutura de energia do driver ou estrutura de energia personalizada
    <>
      <CardHeader className="d-flex align-content-center justify-content-between">
        <CardTitle tag="h5" style={{ marginBottom: "0px" }}>
          {t("charts.chartDemand")}{" "}
          {origin === "generalPlant" ? t("homePlant.valueByRefMeter") : ""}
        </CardTitle>
        {!isModal && (
          <Button
            className="bg-transparent border-0"
            id={"button-maximize-dashboard"}
            onClick={handleToggleModal}
          >
            <Maximize2 size={16} color="grey" />
            <UncontrolledTooltip target={"button-maximize-dashboard"}>
              {t("public.expand")}
            </UncontrolledTooltip>
          </Button>
        )}
      </CardHeader>
      <DateRangeSelector
        loading={loadingChart}
        initDate={selectedDate}
        setEntrieStartDate={setStart}
        setEntrieEndDate={setEnd}
        setEntrieRange={setRange}
      >
        {viewChart || chart?.viewChart ? (
          <Bar data={data} options={options} height={150} />
        ) : (
          <p>{t("charts.noData")}</p>
        )}
      </DateRangeSelector>
      {toggleModal && (
        <Modal
          isOpen={toggleModal}
          toggle={handleToggleModal}
          size={windowObervable < 1400 ? "lg" : "xl"}
        >
          <ModalBody>
            <BarChartDemand isModal={true} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
