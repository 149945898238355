import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Maximize2 } from "react-feather";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Button,
  CardHeader,
  CardTitle,
  Modal,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap";
import { DateRangeSelector } from "../../../../../components/DateRangeSelector";
import { usePlantContext } from "../PlantProvider";
import { getGeneralConsumptionPlantData } from "../PlantService";
import { COLOR_CHARTS } from "../../../../../utils/Constants";

export const BarChartConsumptionGeneralPlant = ({
  windowObervable,
  projectId,
  isModal = false,
}) => {
  const { t } = useTranslation();
  const urlParams = useParams();
  const currentTheme = useSelector((store) => store.theme.currentTheme);
  const { selectedDate } = usePlantContext();

  const [loadingChart, setLoadingChart] = useState(false);
  const [chart, setChart] = useState(null);
  const [start, setStart] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [end, setEnd] = useState(moment().endOf("month").format("YYYY-MM-DD"));
  const [range, setRange] = useState("days");
  const [toggleModal, setToggleModal] = useState(false);

  const getChartData = useCallback(async () => {
    setLoadingChart(true);
    const chart = await getGeneralConsumptionPlantData({
      projId: urlParams.id,
      start,
      end,
      searchModel: range,
    });
    setChart(chart);
    setLoadingChart(false);
  }, [urlParams.id, start, end, range]);

  const handleToggleModal = () => setToggleModal(!toggleModal);

  const dates = useMemo(
    () => chart?.arrayConsumption.map((data) => data.date),
    [chart]
  );
  const peakValues = useMemo(
    () => chart?.arrayConsumption.map((data) => data.peak),
    [chart]
  );
  const offPeakValues = useMemo(
    () => chart?.arrayConsumption.map((data) => data.offPeak),
    [chart]
  );

  const data = {
    labels: dates,
    datasets: [
      {
        label: t("charts.offPeak"),
        data: offPeakValues,
        borderWidth: 1,
        backgroundColor: COLOR_CHARTS.offPeak,
        stack: true,
      },
      {
        label: t("charts.peak"),
        data: peakValues,
        borderWidth: 1,
        backgroundColor: COLOR_CHARTS.peak,
        stack: true,
      },
    ],
  };

  const options = {
    maintainAspectRatio: isModal,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              return value.toLocaleString();
            },
          },
        },
      ],
    },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        padding: 30,
        fontSize: 14,
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: function (tooltipItem, data) {
          const datasetIndex = tooltipItem.datasetIndex;
          const datasetLabel = data.datasets[datasetIndex].label || "";
          const value = tooltipItem.yLabel;

          let total = 0;
          data.datasets.forEach((dataset) => {
            total += dataset.data[tooltipItem.index];
          });

          if (datasetIndex === 0) {
            const totalLabel = "Total: " + total.toLocaleString();
            return [totalLabel, datasetLabel + ": " + value.toLocaleString()];
          } else {
            return datasetLabel + ": " + value.toLocaleString();
          }
        },
      },
    },
  };

  useEffect(() => {
    if (urlParams.id) getChartData();
  }, [urlParams.id, getChartData]);

  useEffect(() => {
    if (selectedDate) {
      setStart(moment(selectedDate).startOf("month").toDate());
      setEnd(moment(selectedDate).endOf("day").toDate());
    }
  }, [selectedDate]);

  return (
    <>
      <CardHeader className="d-flex align-content-center justify-content-between">
        <CardTitle tag={"h5"}>
          {t("charts.chartConsumption")}{" "}
          {chart?.hasRefMeter
            ? t("homePlant.valueByRefMeter")
            : t("homePlant.valueByConsumerUnit")}
        </CardTitle>
        {!isModal && (
          <Button
            className="bg-transparent border-0"
            id={"button-maximize-dashboard"}
            onClick={handleToggleModal}
          >
            <Maximize2 size={16} color="grey" />
            <UncontrolledTooltip target={"button-maximize-dashboard"}>
              {t("public.expand")}
            </UncontrolledTooltip>
          </Button>
        )}
      </CardHeader>
      <DateRangeSelector
        loading={loadingChart}
        initDate={selectedDate}
        setEntrieStartDate={setStart}
        setEntrieEndDate={setEnd}
        setEntrieRange={setRange}
      >
        <div style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          flex: 1,
        }}>
          <Bar
            data={data}
            options={options}
          />
        </div>
      </DateRangeSelector>

      {toggleModal && (
        <Modal
          isOpen={toggleModal}
          toggle={handleToggleModal}
          size={windowObervable < 1400 ? "lg" : "xl"}
          style={{
            display: "flex",
          }}

        >
          <ModalBody
            style={{
              maxHeight: windowObervable < 1400 ? "60vh" : "80vh",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <BarChartConsumptionGeneralPlant
              isModal={true}
              projectId={projectId}
            />
          </ModalBody>
        </Modal>
      )}
    </>
  );
};
