import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import {
  Button,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Spinner,
} from "reactstrap";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useQuery } from "@tanstack/react-query";
import { DatePicker } from "antd";
import moment from "moment";
import { queryClient } from "../../../../../App";
import { formatEnergy } from "../../../../../utils/functions";
import useChartHeight from "../hook";

const Selects = ({ pastMonth, setPastMonth }) => {
  return (
    <DatePicker
      value={pastMonth ? moment(pastMonth) : null}
      onChange={(date) => date && setPastMonth(date.toDate())}
      format={"MMM/YYYY"}
      picker={"month"}
      disabledDate={(current) => {
        return current.isAfter(moment());
      }}
      allowClear={false}
    />
  );
};

const EnergyChart = ({ month, pastMonth, days, energy, pastEnergy }) => {
  const data = days.map((day, index) => ({
    date: day.toString().padStart(2, "0"),
    currentMonth: energy[index] || 0,
    pastMonth: pastEnergy[index] || 0,
  }));

  const height = useChartHeight();

  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis tickFormatter={formatEnergy} width={80} />
        <Tooltip
          formatter={(value) => formatEnergy(value)}
          labelFormatter={(value) => `Dia ${value}`}
        />
        <Legend />
        <Bar
          type="monotone"
          dataKey="currentMonth"
          name={`${moment(month).format("MMM/YYYY")}`}
          stroke="#8884d8"
          fill="#8884d8"
        />
        {pastEnergy.length > 0 && (
          <Bar
            type="monotone"
            dataKey="pastMonth"
            name={`${moment(pastMonth).format("MMM/YYYY")}`}
            stroke="#82ca9d"
            fill="#82ca9d"
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};

const EnergyYield = ({ projectId, month }) => {
  const { t } = useTranslation();

  const { data, status } = useQuery({
    queryKey: [
      "readSolarMonthlyEnergy",
      { projId: projectId, monthRef: month },
    ],
    queryFn: () =>
      axios
        .get("/readSolarMonthlyEnergy", {
          params: {
            projId: projectId,
            monthRef: month,
          },
        })
        .then((res) => res.data),
    staleTime: 1000 * 60 * 60, // 1 hour
    refetchInterval: 1000 * 60 * 60, // 1 hour
  });

  const [loading, setLoading] = useState(false);
  const [pastMonth, setPastMonth] = useState(
    new Date(month.getFullYear(), month.getMonth() - 1)
  );
  const [pastEnergy, setPastEnergy] = useState([]);
  const [compare, setCompare] = useState(false);

  const handleCompare = async () => {
    if (
      pastMonth.getMonth() === month.getMonth() &&
      pastMonth.getFullYear() === month.getFullYear()
    ) {
      setCompare(false);
      setPastEnergy([]);
      return;
    }
    setLoading(true);
    try {
      const response = await queryClient.ensureQueryData({
        queryKey: [
          "readSolarMonthlyEnergy",
          { projId: projectId, monthRef: pastMonth },
        ],
        queryFn: () =>
          axios
            .get("/readSolarMonthlyEnergy", {
              params: {
                projId: projectId,
                monthRef: pastMonth,
              },
            })
            .then((res) =>
              res.data.monthlyEnergy ? res.data.monthlyEnergy.energy : []
            ),
      });
      setPastEnergy(response);
      setCompare(true);
    } catch (error) {
      console.log(error);
      toastr.warning("Erro!", "Erro ao buscar dados do mês anterior.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CardHeader>
        <Row>
          <Col xs={12} md={6}>
            <CardTitle tag="h5">{`${t("solar.monthlyComparison2")}`}</CardTitle>
          </Col>

          <Col className="mb-1 d-flex align-items-center justify-content-end" style={{gap: '5px'}}>
            <Selects pastMonth={pastMonth} setPastMonth={setPastMonth} />
            <Button
              color="primary"
              onClick={handleCompare}
              disabled={loading}
              size="small"
            >
              {loading ? <Spinner size="sm" /> : t("Comparar")}
            </Button>
          </Col>
        </Row>
      </CardHeader>

      <CardBody>
        {loading || status === "loading" ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <EnergyChart
            month={month}
            pastMonth={pastMonth}
            days={data.monthlyEnergy.days}
            energy={data.monthlyEnergy.energy}
            pastEnergy={compare ? pastEnergy : []}
          />
        )}
      </CardBody>
    </>
  );
};

export default EnergyYield;
