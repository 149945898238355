import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { UncontrolledTooltip } from "reactstrap";
import { ModalConsumerUnitParams } from "./components/ModalConsumerUnitParams";
import SelectMonth from "./components/SelectMonth";
import { useConsumerUnitContext } from "../ConsumerUnitProvider";
import NotificationsAlarm from "../../Alarm/NotificationsAlarm";
import { usePlantContext } from "../../PlantProvider";
import { useSelector } from "react-redux";

const SubMenu = () => {
  const currentTheme = useSelector((store) => store.theme.currentTheme);
  const { deviceId } = useConsumerUnitContext();
  const { selectedDate } = usePlantContext();

  const [openModal, setOpenModal] = useState(false);

  const handleToggleModal = (status) => {
    setOpenModal(status);
  };

  const { t } = useTranslation();

  return (
    <div className="d-flex mx-4 align-items-center">
      <div className="px-2 flex-column inputs form-control">
        REF {"  "}
        {selectedDate
          ? moment(selectedDate).format("MM/YYYY")
          : moment().format("MM/YYYY")}
      </div>
      <div className="px-2">
        <SelectMonth />
      </div>

      <div
        id="energyconfig"
        className="cursor-pointer d-flex align-items-center justify-content-center px-1"
      >
        <FontAwesomeIcon
          icon={faCog}
          style={{ height: "18px", width: "18px" }}
          color={currentTheme.primary}
          onClick={() => handleToggleModal("params")}
        />

        <UncontrolledTooltip placement="top" target="energyconfig">
          {t("subMenu.configParams")}
        </UncontrolledTooltip>
      </div>

      {openModal === "params" && (
        <ModalConsumerUnitParams
          open={openModal === "params"}
          toggle={() => handleToggleModal(false)}
        />
      )}

      {/* NOTE: Notificações de Alarmes */}
      <NotificationsAlarm
        deviceId={deviceId}
      />

    </div>
  );
};

export default SubMenu;
