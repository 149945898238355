import {
  faAddressCard,
  faBell,
  faBook,
  faBookOpen,
  faBookReader,
  faCarBattery,
  faChalkboard,
  faChartLine,
  faDatabase,
  faLaptopHouse,
  faLock,
  faPlus,
  faSolarPanel,
  faTable,
  faTags,
  faWater,
  faChartArea,
  faSignal
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useEffect, useState } from "react";

const iotRoutes = [
  {
    name: "dashboards",
    icon: faChalkboard,
    pathRoute: "/dashboards",
  },
  {
    name: "dataExplorer",
    icon: faChartLine,
    pathRoute: "/data",
  },
  {
    name: "projects",
    icon: faTable,
    modal: true,
  },
];

const specialistRoutes = [
  {
    name: "solarModuleOn",
    icon: faSolarPanel,
    pathRoute: "/solar",
  },
  {
    name: "powerModuleOn",
    icon: faCarBattery,
    pathRoute: "/energy",
  },
  // TODO: descomentar conforme criação das páginas
  {
    name: "waterModuleOn",
    icon: faWater,
    pathRoute: "/water",
  },
  // {
  //   name: "climateModuleOn",
  //   icon: faTemperatureLow,
  //   pathRoute: "/climate",
  // },
  // {
  //   name: "nobreakModuleOn",
  //   icon: faPowerOff,
  //   pathRoute: "/nobreak",
  // },
];

const toolsRoutes = [
  {
    name: "alarms",
    icon: faBell,
    children: [
      {
        name: "alarmView",
        icon: faBookOpen,
        pathRoute: "/alarmView",
      },
      {
        name: "alarmConfig",
        icon: faBook,
        pathRoute: "/alarmConfig",
      },
      {
        name: "alarmsData",
        icon: faBookReader,
        pathRoute: "/alarmsRegisters",
      },
    ],
  },
];

const adminRoutes = [
  {
    name: "registers",
    icon: faTags,
    children: [
      {
        name: "usersData",
        icon: faAddressCard,
        pathRoute: "/userRegisters",
      },
      {
        name: "projectsData",
        icon: faLaptopHouse,
        pathRoute: "/projectRegisters",
      },
    ],
  },
  {
    name: "security",
    icon: faLock,
    children: [
      {
        name: "logsData",
        icon: faDatabase,
        pathRoute: "/logsRegisters",
      },
    ],
  },
  {
    name: "management",
    icon: faBook,
    children: [
      {
        name: "myDriversData",
        icon: faPlus,
        pathRoute: "/addNewDriver",
      },
      {
        name: "gateways",
        icon: faBook,
        pathRoute: "/gatewayManagement",
      },
    ],
  },
];

const nocRoutes = [
  {
    name: "noc",
    icon: faChartArea,
    children: [
      {
        name: "nocCenter",
        icon: faSignal,
        pathRoute: "/noc",
      },
      {
        name: "nocAs",
        icon: faPlus,
        pathRoute: "/serviceAuthorizations-",
      },
      {
        name: "nocAsCommercial",
        icon: faPlus,
        pathRoute: "/serviceAuthorizations/CommercialManagement",
      },
    ],
  },
];

export const useSidebar = () => {
  const modulesOnByProjectsStorage = JSON.parse(
    localStorage.getItem("modulesOnByProjects")
  );

  const [modulesOnByProjects, setModulesOnByProjects] = useState(
    modulesOnByProjectsStorage !== null
      ? modulesOnByProjectsStorage
      : {
          data: {
            solarModuleOn: 0,
            powerModuleOn: 0,
            waterModuleOn: 0,
            gasModuleOn: 0,
            climateModuleOn: 0,
            nobreakModuleOn: 0,
          },
          isLoading: false,
        }
  );

  useEffect(() => {
    const fetchReadPlantModulesByUser = async () => {
      setModulesOnByProjects({ ...modulesOnByProjects, isLoading: true });
      try {
        const response = await axios.get("/v2/user/readPlantModulesByUser");
        setModulesOnByProjects({
          ...modulesOnByProjects,
          data: response.data,
        });
        localStorage.setItem(
          "modulesOnByProjects",
          JSON.stringify({
            ...modulesOnByProjects,
            data: response.data,
          })
        );
      } catch (error) {
        console.error(error);
        setModulesOnByProjects({ ...modulesOnByProjects, isLoading: false });
      }
    };

    if (!modulesOnByProjectsStorage) fetchReadPlantModulesByUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    modulesOnByProjects,
    iotRoutes,
    specialistRoutes,
    toolsRoutes,
    adminRoutes,
    nocRoutes,
  };
};
