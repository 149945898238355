import React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { DollarSign } from "react-feather";
import { Spinner } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { formatEnergy } from "../../../../../utils/functions";

const WeeklyEnergy = ({ weeklyEnergy, loading, loadingModal }) => {
  const { t } = useTranslation();
  
  return (
    <Row style={{minWidth: "147px"}}>
      <Col sm="12" style={{paddingRight:"0px"}}>
        <Card style={{maxHeight:"117px", height: "117px"}}>
          <CardBody style={{padding: "15px"}}>
            <Row>
              <Col className="mt-0">
                <CardTitle tag="h5">{t('solar.energy')} <br></br>{t('solar.weeklyEnergy')}</CardTitle>
              </Col>
              <Col className="col-auto" style={{paddingLeft:"0px"}}>
                <div style={{width:"26px",height:"26px"}}>
                  <div className="avatar-title rounded-circle bg-primary-dark">
                    <DollarSign className="feather align-middle" />
                  </div>
                </div>
              </Col>
            </Row>
            {(loadingModal)
              ? <div className="text-center mb-4"><Spinner /></div>
              : (loading)
                ? <div className="text-center mb-4"> <Spinner color="primary"/></div>
                : (weeklyEnergy)
                  ? <h1 className="display-5 mb-4 text-center" style={{fontSize:"17px", fontWeight:"500"}}>{formatEnergy(weeklyEnergy)}</h1>
                  : <p className="text-center">Não há dados</p>
            }
          </CardBody>
        </Card>
      </Col>
    </Row>

  );
}   

export default WeeklyEnergy;