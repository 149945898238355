import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, CardHeader, Spinner } from "reactstrap";
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatEnergy } from "../../../../../../../utils/functions";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

const prefixedColors = [
  "#8884d8",
  "#82ca9d",
  "#ffc658",
  "#ff7f50",
  "#ff6347",
  "#ff4500",
  "#ff1493",
  "#ff00ff",
  "#ee82ee",
  "#dda0dd",
  "#da70d6",
  "#ba55d3",
  "#9932cc",
  "#8a2be2",
  "#800080",
];

const GeneratedEnergy = () => {
  const { t } = useTranslation();
  const [height, setHeight] = useState(350);

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth <= 768) {
        setHeight(200);
        return;
      }
      const windowHeight = window.innerHeight;

      const cardHeight = Math.min((windowHeight - 750) , 270 );
      setHeight(cardHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { data, isLoading: loading } = useQuery({
    queryKey: ["solarEnergy"],
    queryFn: async () => {
      const { data } = await axios.get("/readSolarEnergy");
      return data
    },
    refetchOnWindowFocus: false,
  });

  const [barProps, setBarProps] = useState({});
  const [colors, setColors] = useState([]);

  const onClickLegend = (e) => {
    const { dataKey } = e;
    setBarProps((prevProps) => {
      return {
        ...prevProps,
        [dataKey]: !prevProps[dataKey],
      };
    });
  };

  const solarData = useMemo(() => data?.energy || [], [data]);

  useEffect(() => {
    if(!solarData) return;
    setColors(
      solarData.map(
        (item, index) => prefixedColors[index % prefixedColors.length]
      )
    );
    const labels = Object.keys(solarData[0] || {}).filter(
      (item) => item !== "date"
    );
    setBarProps(
      labels.reduce((acc, label) => {
        acc[label] = true;
        return acc;
      }, {})
    );
  }, [solarData]);

  if (loading) {
    return (
      <Card>
        <CardBody className="text-center">
          <Spinner color="primary" />
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <h4>{`${t("solar.energyLabel")}`}</h4>
      </CardHeader>
      <CardBody>
        <ResponsiveContainer
          width="100%"
          height={height}
        >
          <BarChart data={solarData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              name="Month"
              tickFormatter={(value) => {
                return moment(value).format("MMM/YY");
              }}
            />
            <YAxis
              name="Generated Energy"
              tickFormatter={(value) => formatEnergy(value)}
              width={100}
            />
            <Tooltip
              formatter={(value) => formatEnergy(value)}
              labelFormatter={(value) => moment(value).format("MMMM/YY")}
            />
            <Legend
              onClick={onClickLegend}
              wrapperStyle={{ maxHeight: 40, overflow: "auto" }}
            />
            <Brush dataKey="date" height={15} stroke="#8884d8" />
            {Object.keys(solarData[0] || {})
              .filter((item) => item !== "date")
              .map((key, index) => {
                return (
                  <Bar
                    dataKey={key}
                    name={key}
                    fill={colors[index]}
                    key={key}
                    hide={!barProps[key]}
                  />
                );
              })}
          </BarChart>
        </ResponsiveContainer>
      </CardBody>
    </Card>
  );
};

export default GeneratedEnergy;
