import {
  faCarBattery,
  faSolarPanel,
  faWater,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import L from "leaflet";
import React from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, CardTitle, Spinner } from "reactstrap";

/* configura o pin do mapa de acordo com o seu estado de alarme */
const IconVermelho = L.icon({
  iconUrl: require("../../assets/iconsHomeMap/icone-vermelho.png"),
  iconAnchor: new L.point(15, 50), // variável que define a posição do pin sem que o mesmo se mova.
  popupAnchor: new L.point(0, -49),
});

const IconVerde = L.icon({
  iconUrl: require("../../assets/iconsHomeMap/icone-verde.png"),
  iconAnchor: new L.point(15, 50),
  popupAnchor: new L.point(0, -49),
});
const IconAmarelo = L.icon({
  iconUrl: require("../../assets/iconsHomeMap/icone-amarelo.png"),
  iconAnchor: new L.point(15, 50),
  popupAnchor: new L.point(0, -49),
});
const IconCinza = L.icon({
  iconUrl: require("../../assets/iconsHomeMap/icone-cinza.png"),
  iconAnchor: new L.point(15, 50),
  popupAnchor: new L.point(0, -49),
});

const MapCard = () => {
  const { t } = useTranslation();

  const { data: deviceList, status } = useQuery({
    queryKey: "deviceUserSearch",
    queryFn: async () => {
      const response = await axios.get("/deviceUserSearch");
      return response.data;
    },
    refetchInterval: 1000 * 30,
  });

  const devicesWithCoordinates = deviceList?.filter(
    (item) => item.latitude !== null && item.longitude !== null
  );

  const minLat = devicesWithCoordinates?.length
    ? Math.min(...devicesWithCoordinates.map((item) => item.latitude))
    : null;
  const maxLat = devicesWithCoordinates?.length
    ? Math.max(...devicesWithCoordinates.map((item) => item.latitude))
    : null;
  const minLong = devicesWithCoordinates?.length
    ? Math.min(...devicesWithCoordinates.map((item) => item.longitude))
    : null;
  const maxLong = devicesWithCoordinates?.length
    ? Math.max(...devicesWithCoordinates.map((item) => item.longitude))
    : null;

  const loadDevices = status === "loading";
  // busca o timestamp atual em milisegundos.
  const timestamp = Math.floor(Date.now() / 1000);
  // variável que armazena um booleano, de acordo com o tamanho da tela atual.
  var draggableHandler = window.innerWidth < 768 ? false : true;

  return (
    <Card>
      <CardHeader style={{ paddingBottom: "0px" }}>
        <CardTitle
          tag="h5"
          style={{ paddingBottom: "0px", marginBottom: "0px" }}
        >
          {t("home.map")}
        </CardTitle>
      </CardHeader>
      <CardBody style={{ paddingTop: "5px" }}>
        {devicesWithCoordinates?.length &&
        minLat !== null &&
        minLong !== null ? (
          <div>
            {/* configura o mapa */}
            <MapContainer
              // configura o mapa para não se mover no mobile, somente com dois dedos.
              dragging={draggableHandler}
              // configura a área visível do mapa, de acordo com as posições máximas e mínimas dos dispositivos mostrados no mapa.
              bounds={[
                [minLat - 0.005, minLong - 0.005],
                [maxLat + 0.005, maxLong + 0.005],
              ]}
              // configura a altura do mapa no container
              style={{ height: "261px" }}
            >
              {/* configura o tilelayer do mapa */}
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {/* condição se existe latitude e longitude para exibir o pin */}
              {devicesWithCoordinates?.map((item) =>
                item.latitude && item.longitude ? (
                  // configura link do pin, de acordo com o id do projeto que o dispositivo está vinculado.
                  <Marker
                    // posição do pin e sua cor, de acordo com a variável de alarme do dispositivo.
                    position={[item.latitude, item.longitude]}
                    icon={
                      item.lastComm === null
                        ? IconCinza
                        : item.alarm
                        ? IconVermelho
                        : item.lastComm >= timestamp - item.commInterval
                        ? IconVerde
                        : IconAmarelo
                    }
                    key={item.id}
                  >
                    <Popup>
                      <div>
                        <Link
                          cursor="pointer"
                          to={`/project-${item.projId}-default`}
                        >
                          <h4 style={{ cursor: "pointer" }}>
                            {item.description}
                          </h4>
                        </Link>
                      </div>
                      {item.solarModuleOn === 1 && (
                        <div>
                          <Link
                            cursor="pointer"
                            to={`/project-${item.projId}-solar`}
                          >
                            <FontAwesomeIcon
                              icon={faSolarPanel}
                              fixedWidth
                              className="align-middle mr-2 "
                            />
                            {t("home.solarModuleOn")}
                          </Link>
                        </div>
                      )}
                      {item.powerModuleOn === 1 && (
                        <div>
                          <Link
                            cursor="pointer"
                            to={`/project-${item.projId}-energy`}
                          >
                            <FontAwesomeIcon
                              icon={faCarBattery}
                              fixedWidth
                              className="align-middle mr-2 "
                            />
                            {t("home.powerModuleOn")}
                          </Link>
                        </div>
                      )}
                      {item.waterModuleOn === 1 && (
                        <div>
                          <Link
                            cursor="pointer"
                            to={`/project-${item.projId}-water`}
                          >
                            <FontAwesomeIcon
                              icon={faWater}
                              fixedWidth
                              className="align-middle mr-2 "
                            />
                            {t("home.waterModuleOn")}
                          </Link>
                        </div>
                      )}
                      {item.gasModuleOn === 1 && (
                        <div>
                          <Link
                            cursor="pointer"
                            to={`/project-${item.projId}-water`}
                          >
                            <FontAwesomeIcon
                              icon={faWater}
                              fixedWidth
                              className="align-middle mr-2 "
                            />
                            {t("home.gasModuleOn")}
                          </Link>
                        </div>
                      )}
                    </Popup>
                  </Marker>
                ) : null
              )}
            </MapContainer>
          </div>
        ) : loadDevices ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <MapContainer
            center={[-30.0177494, -51.2063027]}
            zoom={9}
            style={{ height: "261px" }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          </MapContainer>
        )}
      </CardBody>
    </Card>
  );
};

export default MapCard;
