import axios from "axios";
import { toastr } from "react-redux-toastr";

export const listDistributors = async () => {
  try {
    const response = await axios.get(`/v2/energy/listDistributors`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const listMeters = async ({ projectId, monthRef }) => {
  try {
    const get = await axios.get(
      `/v2/energy/listMeters?projId=${projectId}&monthRef=${new Date(
        monthRef
      ).toISOString()}`
    );
    return get.data;
  } catch (error) {
    console.log(error);
  }
};

export const getGeneralConsumptionPlantData = async ({
  projId,
  start,
  end,
  searchModel,
}) => {
  try {
    const now = new Date();
    const timezoneOffsetMinutes = now.getTimezoneOffset();

    const dateStart = new Date(start);
    const dateEnd = new Date(end);
    
    const get = await axios.get(
      `/v2/energy/readChartConsumption?projId=${projId}&start=${dateStart.toISOString()}&end=${dateEnd.toISOString()}&searchModel=${searchModel}&timezoneOffsetMinutes=${timezoneOffsetMinutes}`
    );
    return get.data;
  } catch (error) {
    console.log(error);
  }
};

export const readParamsPlant = async (projId) => {
  try {
    const response = await axios.get("/v2/energy/readParamsPlant", {
      params: { projId },
    });
    return response.data;
  } catch (error) {
    return error.response?.status || error.response;
  }
};

export const readPlant = async (projId) => {
  try {
    const response = await axios.get(`/v2/energy/readPlant?projId=${projId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createParams = async (setValidateFields, setLoadingData, jsonData) => {
  setLoadingData(true);
  try {
    const response = await axios.post(`/v2/energy/createPlant`, jsonData);
    toastr.success(response.data.message);
    return response.data.energyData;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      setValidateFields(error.response.data.errors);
      return error.response;
    }
    console.error(error);
  }
  finally {
    setLoadingData(false);
  }
};

export const updateParams = async (setValidateFields, setLoadingData,  jsonData) => {
  setLoadingData(true);
  try {
    const response = await axios.put(`/v2/energy/updatePlant`, jsonData);
    toastr.success(response.data.message);
    return response.data.energyData;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      setValidateFields(error.response.data.errors);
      return error.response;
    }
    console.error(error);
  }
  finally {
    setLoadingData(false);
  }
};

export const updatePreferences = async (projId, preferences) => {
  try {
    const response = await axios.put(`/v2/energy/updatePreferences`, {
      projId, preferences
    });
    toastr.success(response.data.message);
    return response.data.energyData;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      return error.response;
    }
    console.error(error);
  }
};

export const listDevices = async (projId) => {
  try {
    const response = await axios.get(`/v2/energy/listDevices?projId=${projId}&type=energy`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const readDeviceStatus = async (projectId) => {
  try {
    const response = await axios.get(`/v2/energy/readDeviceStatus`, {
      params: { projectId },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deleteUc = async (
  setValidateFields,
  projId,
  ucId,
  captchaInput
) => {
  try {
    const response = await axios.delete(
      `/v2/energy/deleteUc?projId=${projId}&ucId=${ucId}&captchaInput=${captchaInput}`
    );
    toastr.success(response.data.message);
    return response.data.energyData;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      setValidateFields(error.response.data.errors);
    }
    console.error(error);
    return error.response;
  }
};

export const createUcs = async (projId, meters) => {
  try {
    const response = await axios.post(`/v2/energy/createUcs`, {
      projId,
      meters,
      monthRef: new Date(),
    });
    toastr.success(response.data.message);
    return response.data.energyData;
  } catch (error) {
    console.error(error);
  }
};

export const resetPlant = async (
  setValidateFields,
  jsonData
) => {
  try {
    const response = await axios.delete(
      `/v2/energy/deletePlant?projId=${jsonData.projId}&captcha=${jsonData.captcha}`
    );
    toastr.success(response.data.message);
    return response.data.energyData;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      setValidateFields(error.response.data.errors);
    }
    console.error(error);
    return error.response;
  }
};
