import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Label,
  FormGroup,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { updatePreferences } from "../../PlantService";
import { usePlantContext } from "../../PlantProvider";

const Preferences = ({ projId, open, toggle }) => {
  const { t } = useTranslation();

  const options = [
    "consumption",
    "carbonEmission",
    "demand",
    "powerFactor",
    "devicesInfo",
    "listMeters",
  ];

  const { params, reload } = usePlantContext();
  const [preferences, setPreferences] = useState([]);

  useEffect(() => {
    if (params !== 404) {
      setPreferences(params?.preferences || ["consumption", "demand", "powerFactor", "listMeters"]);
    }
  }, [params]);

  function handlePreferences(preference) {
    const updatedPreferences = preferences?.includes(preference)
      ? preferences?.filter((item) => item !== preference)
      : [...preferences, preference];

    setPreferences(updatedPreferences);
  }

  async function savePreferences() {
    await updatePreferences(projId, preferences);
    reload();
  }

  return (
    <Dropdown isOpen={open} toggle={toggle}>
      <DropdownToggle caret>{t("subMenu.preferenceView")}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>{t("preferences.title")}</DropdownItem>
        <DropdownItem divider />
        {preferences &&
          options.map((option) => (
            <DropdownItem key={option} toggle={false}>
              <FormGroup check onClick={(e) => e.stopPropagation()}>
                <Label check>
                  <Input
                    type="checkbox"
                    value={option}
                    checked={preferences?.includes(option)}
                    onChange={() => handlePreferences(option)}
                    disabled={
                      option === "consumption" || option === "listMeters"
                    }
                  />
                  {t(`preferences.${option}`)}
                </Label>
              </FormGroup>
            </DropdownItem>
          ))}
        <DropdownItem divider />
        <DropdownItem>
          <Button color="secondary" onClick={savePreferences} block>
            {t("preferences.save")}
          </Button>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default Preferences;
