/**
 * Sample to design thermometer using the Linear Gauge
 */
import * as React from "react";
import {
  LinearGaugeComponent,
  AnnotationsDirective,
  Annotations,
  Inject,
  AnnotationDirective,
  AxesDirective,
  AxisDirective,
  PointersDirective,
  PointerDirective
} from '@syncfusion/ej2-react-lineargauge';
export let range = ['#30b32d', '#ffdd00', '#f03e3e'];
const SAMPLE_CSS = `
     .control-fluid {
         height: 0px !important;
     }`;
function Thermometer({ minValue, maxValue, deviceData, varUnit, cardHeight, cardId }) {
  function load(args) {
  }
  return (
    <div className='control-pane'>
      <style>
        {SAMPLE_CSS}
      </style>
      <div className='control-section'>
        <LinearGaugeComponent
          // style={{ height: 200 }}
          // width="160px"
          width="160px"
          height={
            (cardHeight < 80) ? "100px"
              : (cardHeight > 81 && cardHeight < 120) ? "140px"
                : (cardHeight > 121 && cardHeight < 210) ? "200px"
                  : (cardHeight > 211 && cardHeight < 300) ? "280px"
                    : (cardHeight > 301) ? "320px"
                      : "400px"
          }
          load={load.bind(this)}
          id={'gauge' + cardId}
          background='transparent'
          orientation='Vertical'
          container={{ width: 13, roundedCornerRadius: 5, type: (cardHeight < 70) ? "Normal" : "Thermometer", border: { width: 1 } }}
        >
          <Inject services={[Annotations]} />
          <AxesDirective>
            <AxisDirective
              minimum={minValue}
              maximum={maxValue}
              majorTicks={{ height: 7, interval: (maxValue > 80) ? 40 : 20 }}
              minorTicks={{ height: 3, interval: 10 }}
              line={{ width: 0 }}
              labelStyle={{ font: { fontFamily: 'inherit' } }}>
              <PointersDirective>
                <PointerDirective
                  value={deviceData}
                  height={12}
                  width={12}
                  offset={3}
                  markerType='Triangle'
                  placement='Center'
                  color='#68b7dc'>
                </PointerDirective>
              </PointersDirective>
            </AxisDirective>
            <AxisDirective
              minimum={minValue}
              maximum={maxValue}
              opposedPosition={true}
              majorTicks={{ height: 7, interval: (maxValue > 80) ? 40 : 20 }}
              minorTicks={{ height: 3, interval: 10 }}
              line={{ width: 0 }}
              labelStyle={{ font: { fontFamily: 'inherit' } }}>
              <PointersDirective>
                <PointerDirective
                  value={deviceData}
                  height={13}
                  width={13}
                  type='Bar'
                  color='#68b7dc'>
                </PointerDirective>
              </PointersDirective>
            </AxisDirective>
          </AxesDirective>
          <AnnotationsDirective>
            {/* <AnnotationDirective
            content={`<div><p style="font-size:13px;margin-left: -12px;margin-top: -20px;">${varUnit.toString()}</p></div>`}
            axisIndex={0}
            axisValue={100}
            x={0}
            y={0}
            zIndex='1'>
          </AnnotationDirective> */}
            <AnnotationDirective
              content={`<div><p style="font-size:13px;margin-left: -12px;margin-top: -20px;">${varUnit}</p></div>`}
              axisIndex={1}
              axisValue={100}
              x={0}
              y={0}
              zIndex='1'>
            </AnnotationDirective>
          </AnnotationsDirective>
        </LinearGaugeComponent>
      </div>
    </div>);
}
export default Thermometer;