import {
  faPercent,
  faStreetView,
  faSun,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Zap } from "react-feather";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import { formatEnergy } from "../../../../../utils/functions";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export default function LateralPerformanceCards({ data, projectId }) {
  const { t } = useTranslation();
  const { data: newData, status } = useQuery({
    queryKey: [
      "readSolarPlantInfo",
      {
        projId: projectId,
      },
    ],
    queryFn: () =>
      axios
        .get("readSolarPlantInfo", {
          params: {
            projId: projectId,
          },
        })
        .then((res) => res.data),
    staleTime: 1000 * 60 * 15, // 1 hour
    refetchInterval: 1000 * 60 * 15, // 1 hour
  });

  if (!newData || status === "loading") return <div>Loading...</div>;

  const {
    capacityPerDay,
    capacityFactor,
    performanceRatio,
    totalDevices,
    deviceOnline,
  } = newData.info;

  const avgAvailability = (deviceOnline / totalDevices) * 100;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <CardInfo
        title={t("solar.pr")}
        value={`${(performanceRatio * 100).toFixed(1).replace(".", ",")} %`}
        icon={
          <Zap
            className="feather align-middle"
            style={{ width: "25px", height: "25px" }}
          />
        }
      />

      <CardInfo
        title={t("solar.expectedGeneration")}
        value={formatEnergy(capacityPerDay)}
        icon={
          <FontAwesomeIcon icon={faStreetView} style={{ fontSize: "22px" }} />
        }
      />

      <CardInfo
        title={t("solar.capacityFactor")}
        value={`${(capacityFactor * 100).toFixed(1).replace(".", ",")} %`}
        icon={<FontAwesomeIcon icon={faSun} style={{ fontSize: "21px" }} />}
      />

      <CardInfo
        title={t("solar.disponibility")}
        value={`${avgAvailability.toFixed(1).replace(".", ",")} %`}
        icon={<FontAwesomeIcon icon={faPercent} style={{ fontSize: "18px" }} />}
      />
    </div>
  );
}

const CardInfo = ({ title, value, icon }) => {
  const [height, setHeight] = useState(350);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setHeight(undefined);
        return;
      }
      if (window.innerHeight <= 900) {
        const windowHeight = window.innerHeight;

        const cardHeight = (windowHeight - 469) / 4;
        setHeight(cardHeight);
        return;
      }

      setHeight(`100%`);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Card
      style={{
        minWidth: "200px",
        marginBottom: "8px",
        width: "100%",
        height,
      }}
    >
      <CardHeader style={{ padding: "14px 14px 12px 14px" }}>
        <Row>
          <Col className="mt-0">
            <CardTitle tag="h5">{title}</CardTitle>
          </Col>
          <Col className="col-auto" style={{ paddingLeft: "0px" }}>
            <div>{icon}</div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody
        style={{
          paddingTop: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {value || value === 0 ? (
          <h3 className="text-center">{value}</h3>
        ) : (
          <div className="text-center mb-4">
            <Spinner />
          </div>
        )}
      </CardBody>
    </Card>
  );
};
