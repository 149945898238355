import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { TreeSelect } from 'antd';
import DatePicker from "react-datepicker";
// import setHours from "date-fns/setHours";
import ptBR from 'date-fns/locale/pt-BR';
import enGB from 'date-fns/locale/en-GB';
import {
  Badge, 
  Breadcrumb, BreadcrumbItem,
  Button, ButtonGroup, ButtonToolbar,
  Card, CardBody, CardHeader, CardTitle,
  Container,
  Form, FormGroup, Input, Label,
  Row, 
  Col,
  Spinner,
  UncontrolledTooltip
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faBell,
  faBellSlash,
  faCaretDown,
  faCaretUp,
  faSearch,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { toastr } from "react-redux-toastr";

import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import ModalOffAlarm from "./ModalOffAlarm";

/*** Badge de prioridade ***/
const PriorityBadge = ({ priority }) => {
  const { t } = useTranslation();

  return (priority === 1)
    ? <Badge color="danger">{t('alarms.high')}</Badge>
    : (priority === 2) 
      ? <Badge color="warning">{t('alarms.medium')}</Badge>
      : <Badge color="primary">{t('alarms.low')}</Badge>
};

/* Ícone de desligar alarme */ 
const AlarmTurnOffIcon = ({ alarm, tableReload }) => {
  const { t } = useTranslation();

  const [openOffAlarm, setOpenOffAlarm] = useState(false);
  // ativa/desativa modal
  function toggleOffAlarm() {
    setOpenOffAlarm(!openOffAlarm);
  }

  return (!alarm.dateFinished) // verifica se alarme ainda está ativo
    // desativar alarme
    ? <div
        className="text-center"
        id={"alarm"+alarm.id}
        onClick={()=>setOpenOffAlarm(true)}
      >
        <FontAwesomeIcon
          icon={faBell}
          fixedWidth
          className="align-middle cursor-pointer"
        />
        {(openOffAlarm) // modal de desligar alarme
          ? <ModalOffAlarm
              open={openOffAlarm}
              toggle={toggleOffAlarm}
              tableReload={tableReload}
              alarm={alarm}
            />
          : null
        }
        <UncontrolledTooltip // tooltip
          placement="top"
          target={"alarm"+alarm.id}
        >
          {t('alarms.turnOffAlarm')}
        </UncontrolledTooltip>
      </div>
    // alarme já está desligado
    : <div className="text-center" >
        <div id={"alarm"+alarm.id} >
          <FontAwesomeIcon
            icon={faBellSlash}
            fixedWidth
            className="align-middle"
            color="gray"
          />
          <UncontrolledTooltip // tooltip
            placement="top"
            target={"alarm"+alarm.id}
          >
            {t('alarms.alarmTurnedOff')}
          </UncontrolledTooltip>
        </div>
      </div>
  

};

/*** Tabela ***/
const AlarmViewTable = () => {
  const { i18n, t } = useTranslation();
  const { SHOW_PARENT } = TreeSelect;

  const [loadAlarms, setLoadAlarms] = useState(true);
  const [alarms, setAlarms] = useState([]);
  const [isMore, setMore] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [active, setActive] = useState(true);
  const [inactive, setInactive] = useState(true);
  const [high, setHigh] = useState(true);
  const [moderate, setModerate] = useState(true);
  const [low, setLow] = useState(true);
  const [searchField, setSearchField] = useState("");
  const [treeOptions, setTreeOptions] = useState();
  const [treeValue, setTreeValue] = useState([]);
  const [projects, setProjects] = useState([]);
  const [devices, setDevices] = useState([]);
  // const [startDate, setStartDate] = useState(new Date(Date.now() - (7 * 24 * 60 * 60 * 1000))); // data/hora de início é 1 semana atrás]
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const sizePerPage = 10; // alarmes por página
  const maxAlarms = 30; // máximo de alarmes por chamada

  useEffect(() => { // busca alarmes
    if(loadAlarms){ 
      axios.get('/alarmSearch', {params: {
        offset: 0,
        maxAlarms: maxAlarms
      }})
      .then(res => {
        setAlarms(res.data.alarms);

        setMore(res.data.isMore);
      })
      .catch(err => {
        console.log(err);
        console.log(err.response);

        toastr.warning(t('alarms.error'), t('alarms.errorLoadAlarms'));
      })
      .finally(()=>setLoadAlarms(false));
    }
  }, [maxAlarms, t, loadAlarms]);

  useEffect(() => { // busca projetos do usuário
    axios.get('/projectUserSearch')
    .then(res => {
      let optAux = [];
      if (res.data.length > 0) {
        res.data.forEach(proj => {
          const str = 'proj' + proj.id;
          optAux.push({
            title: proj.name,
            value: str,
            key: str,
            id: str,
            isLeaf: false,
            checkable: true,
            children: []
          });
        });
      }
      setTreeOptions(optAux);
    })
    .catch(err => {
      console.log(err);
      console.log(err.response);
    });
  }, []);

  /** Carrega dispositivos do projeto ao expandir */
  function onLoadData(treeNode) {
    return new Promise(resolve => {
      const index = treeOptions.findIndex(item => {
        return item.key === treeNode.key
      });
      setTimeout(() => {
        if (treeNode.children.length) {
          resolve();
          return;
        } else {
          axios.get('/deviceSearch', {params: {
            projId: treeNode.key.split('proj')[1]
            // projId: treeNode.id
          }})
          .then(res => {
            let optAux = treeOptions;
            if (res.data.length > 0) {
              res.data.forEach(dev => {
                const str = 'dev' + dev.id;
                optAux[index].children.push({
                  title: dev.description,
                  value: str,
                  key: str,
                  id: str,
                  isLeaf: true,
                  checkable: true
                });
              });
            }
            setTreeOptions();
            setTreeOptions(optAux);
          })
          .catch(err => {
            console.log(err);
            console.log(err.response);
          })
          .finally(()=>{
            resolve();
            return
          });
        }
      }, 300);
    });
  };

  /** Atualiza estados de projeto e dispositivo selecionado */
  function onChange(checkedKeys) {
    setTreeValue(checkedKeys);
    if (checkedKeys.length > 0) {
      let projAux = [];
      let devAux = [];
      checkedKeys.forEach(key => {
        if (key.includes('proj')) {
          projAux.push(key.split('proj')[1]);
        } else if (key.includes('dev')) {
          devAux.push(key.split('dev')[1]);
        }
      })
      setProjects(projAux);
      setDevices(devAux);
    } else {
      setProjects([]);
      setDevices([]);
    }
  };

  /* Função de filtro avançado */
  function onFilterSearch() {
    setCurrPage(1);
    setLoadAlarms(true);

    axios.get('alarmFilterSearch/', {params: {
      active: active,
      inactive: inactive,
      high: high,
      moderate: moderate,
      low: low,
      projects: projects,
      devices: devices,
      startDate: startDate,
      endDate: endDate,
      searchField: searchField.toLowerCase(),
      offset: 0,
      maxAlarms: maxAlarms
    }})
    .then(response => {
      setAlarms(response.data.alarms);
      setMore(response.data.isMore);
    })
    .catch(err => {
      console.log(err);
      console.log(err.response);
    })
    .finally(() => setLoadAlarms(false));
  };

  /* Colunas da tabela */
  const alarmCols = [
    { // ID (oculto)
      dataField: "id",
      text: `ID`,
      sort: true,
      hidden: true,
      isKey: true
    },
    { // nome do alarme
      dataField: "alarmName",
      text: t('alarms.alarm'),
      sort: true,
    },
    { // nome do dispositivo
      dataField: "deviceName",
      text: t('alarms.deviceName'),
      sort: true,
    },
    { // variável
      dataField: "varName",
      text: t('alarms.varName'),
      formatter: cell => (cell === null) 
        ? '-' 
        : (cell.length > 20)
          ? cell.slice(0,17) + "..."
          : cell
    },
    { // tipo
      dataField: "alarmType",
      text: t('alarms.type'),
      formatter: (cell) => (cell) 
        ? 'D' 
        : 'A'
    },
    { // valor/unidade
      dataField: "value",
      text: t('alarms.value'),
      headerStyle: { width: "60px" },
      formatter: (cell, row) => cell + ((row.varUnit) ? ' ' + row.varUnit : null)
    },
    { // prioridade
      dataField: "priority",
      text: t('alarms.priority'),
      formatter: cell => <PriorityBadge priority={cell} />
    },
    { // data de ocorrencia
      dataField: "dateOccurred",
      text: t('alarms.dateOcc'),
      sort: true,
      formatter: cell => {
        if (cell) {
          var date = new Date(cell*1000);

          return ("0"+date.getDate()).slice(-2)
            + '/' + ("0"+(date.getMonth()+1)).slice(-2)
            + '/' + date.getFullYear()
            + ' ' + ("0"+date.getHours()).slice(-2)
            + ':' + ("0"+date.getMinutes()).slice(-2)
            + ':' + ("0"+date.getSeconds()).slice(-2);
        } else return '-'
      }
    },
    { // data de finalização
      dataField: "dateFinished",
      text: t('alarms.dateOff'),
      sort: true,
      formatter: cell => {
        if (cell) {
          var date = new Date(cell*1000);

          return ("0"+date.getDate()).slice(-2)
            + '/' + ("0"+(date.getMonth()+1)).slice(-2)
            + '/' + date.getFullYear()
            + ' ' + ("0"+date.getHours()).slice(-2)
            + ':' + ("0"+date.getMinutes()).slice(-2)
            + ':' + ("0"+date.getSeconds()).slice(-2);
        } else return '-'
      }
    },
    { // mensagem ON
      dataField: "messageOn",
      text: t('alarms.onMsg'),
      formatter: cell => (cell === null) 
        ? '-' 
        : (cell.length > 20)
          ? cell.slice(0,17) + "..."
          : cell
    },
    { // mensagem OFF
      dataField: "messageOff",
      text: t('alarms.offMsg'),
      formatter: (cell, row) => (cell === null || !row.dateFinished) 
        ? '-' 
        : (cell.length > 20)
          ? cell.slice(0,17) + "..."
          : cell
    },
    { // desligar alarme
      dataField: "actions",
      text: t('alarms.actions'),
      headerStyle: { width: "60px" },
      formatter: (_, row) => (
        <AlarmTurnOffIcon
          className="text-center"
          alarm={row}
          tableReload={()=>setLoadAlarms(true)}
        />
      )
    }
  ];

  /* Ícones de ordenação (setinhas) */
  const sortIcon = {
    sortCaret: (order) => (!order) 
      ? <FontAwesomeIcon
        className={'fa-xs ml-1'}
        icon={faSort}
      />
      : (order === 'asc')
        ? <FontAwesomeIcon
          className={'fa-xs ml-1'}
          icon={faCaretDown}
        />
        : <FontAwesomeIcon
            className={'fa-xs ml-1'}
            icon={faCaretUp}
          />
  }

  /* Legenda (Mostrando xx-xx de xx alarmes) */
  const TableLegend = ({from, to, size}) => {
    const { t } = useTranslation();

    return (
      <span 
        className="react-bootstrap-table-pagination-total" 
        style={{
          display: (window.innerWidth < 700) ? "none" : "block",
          marginBottom: (window.innerWidth < 700) ? "15px" : "0"
        }}
      >
        {t('alarms.showing')} { from }-{ to } {t('alarms.of')} { size } {t('alarms.results')}
      </span>
    );
  };

  /* Botões de Paginação */
  const CustomPagination = ({pages, onPageChange}) => {
    let activePage = pages.find(item => item.active).page;

    return <Col 
      className={"d-flex justify-content-" + ((window.innerWidth < 700) ? "start" : "end")} 
      style={{
        position: (window.innerWidth < 700) ? "absolute" : "relative"
      }}
    >
      <ButtonToolbar className="mr-2">
        <ButtonGroup>
          {pages.map((p, i) => (p.page === ">" || p.page === ">>")
            ? <Button key={i}
                color="primary" 
                outline={!p.active} 
                disabled={!isMore && p.disabled}
                onClick={() => {
                  // verifica se está na última página
                  if (activePage === pages[pages.length-3].page) {
                    setLoadAlarms(true);

                    axios.get('/alarmFilterSearch', {params: {
                      active: active,
                      inactive: inactive,
                      high: high,
                      moderate: moderate,
                      low: low,
                      projects: projects,
                      devices: devices,
                      startDate: startDate,
                      endDate: endDate,
                      searchField: searchField.toLowerCase(),
                      offset: alarms.length,
                      maxAlarms: maxAlarms
                    }})
                    .then(res => {
                      setCurrPage((alarms.length % sizePerPage === 0)
                        ? activePage + 1
                        : activePage
                      )
                      let temp = alarms;
                      res.data.alarms.map(item => temp.push(item));
                      setAlarms(temp);
                      setMore(res.data.isMore);

                    })
                    .catch(err => {
                      console.log(err);
                      console.log(err.response);
                    })
                    .finally(()=>setLoadAlarms(false));
                  }

                  else {
                    onPageChange(p.page);
                  }
                }}
              >
                {p.page}
              </Button>

            : <Button key={i}
                color="primary" 
                outline={!p.active} 
                disabled={p.disabled}
                onClick={() => onPageChange(p.page)}
              >
                {p.page}
              </Button>
          )}
        </ButtonGroup>
      </ButtonToolbar>
    </Col>
  };

  /* Opções de Paginação */
  const paginationOptions = {
    page: currPage,
    sizePerPage: sizePerPage,
    pageStartIndex: 1,
    paginationSize: 3,
    // withFirstAndLast: true, // Hide the going to First and Last page button
    alwaysShowAllBtns: true, // Always show next and previous button
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
    // firstPageTitle: 'Next page',
    // prePageTitle: 'Pre page',
    // nextPageTitle: 'First page',
    // lastPageTitle: 'Last page',
    hideSizePerPage: true, // Hide the sizePerPage dropdown always    
    disablePageTitle: true,
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: true,
    pageListRenderer: ({pages, onPageChange}) => <CustomPagination pages={pages} onPageChange={onPageChange}/>,
    paginationTotalRenderer: (from, to, size) => <TableLegend from={from} to={to} size={size} />
  };

  

  return <div>
    {/* Ferramentas de busca */}
    <div className="alarm-search">
    {/* <div> */}
      <Form 
        autocomplete="off"
        onSubmit={e => {
          e.preventDefault();
          onFilterSearch();
        }}
      >
        <Row className="mb-2">
          {/* Busca por termo/projeto/prioridade */}
          <Col md={5}>
            {/* Busca por termo */}
            <FormGroup className="mb-0">
              <Label for="search" className="ml-1">
                {t('alarms.searchField')}
              </Label>
              <Input
                type="text"
                name="search"
                id="search"
                placeholder={t('alarms.textPlaceholder')}
                value={searchField}
                onChange={e=>{
                  e.preventDefault();
                  setSearchField(e.target.value)
                }}
              />
            </FormGroup>

            {/* Busca por projeto */}
            <FormGroup className="mb-0 mt-2">
              <Label for="project" className="ml-1">
                {t('alarms.searchProject')}
              </Label>
              <TreeSelect 
                id="project"
                treeDataSimpleMode
                maxTagCount="responsive"
                treeData={treeOptions}
                value={treeValue}
                onChange={onChange}
                loadData={onLoadData}
                treeCheckable={true}
                showCheckedStrategy={SHOW_PARENT}
                placeholder={t('alarms.treePlaceholder')}
                size="small"
                style={{width: '100%'}}
                className="cursor-pointer"
              />
            </FormGroup>

            {/* Checkboxes de prioridade  */}
            <div className="d-flex justify-content-between mt-2">
              <Label className="ml-1">
                {t('alarms.priority')}:
              </Label>
              <FormGroup check inline className="mb-0 ml-2">
                <Label check className="cursor-pointer">
                  <Input
                    type="checkbox"
                    name="high"
                    className="cursor-pointer"
                    checked={high}
                    onChange={()=>setHigh(!high)}
                    style={{verticalAlign: "middle"}}
                  />
                  <PriorityBadge priority={1} />
                </Label>
              </FormGroup>

              <FormGroup check inline className="mb-0 ml-auto">
                <Label check className="cursor-pointer">
                  <Input
                    type="checkbox"
                    name="moderate"
                    className="cursor-pointer"
                    checked={moderate}
                    onChange={()=>setModerate(!moderate)}
                    style={{verticalAlign: "middle"}}
                  />
                  <PriorityBadge priority={2} />
                </Label>
              </FormGroup>

              <FormGroup check inline className="mb-0 ml-auto">
                <Label check className="cursor-pointer">
                  <Input
                    type="checkbox"
                    name="low"
                    className="cursor-pointer"
                    checked={low}
                    onChange={()=>setLow(!low)}
                    style={{verticalAlign: "middle"}}
                  />
                  <PriorityBadge priority={3} />
                </Label>
              </FormGroup>
            </div>
          </Col>

          {/* Busca por período/estado de ativação */}
          <Col md={5}>
            {/* Inicio */}
            <FormGroup className="mb-0">
              <Label for="start" className="ml-1">
                {t('alarms.searchStart')}:
              </Label>
              <DatePicker
                id="start"
                className="form-control"
                showTimeSelect
                isClearable
                placeholderText={t('alarms.datepickerPlaceholder')}
                selected={startDate}
                maxDate={(endDate) ? endDate : new Date()}
                onChange={date => setStartDate(date)}
                locale={(i18n.language==="br") ? ptBR : enGB}
                dateFormat={(i18n.language ==="en")
                  ? "MMMM d, yyyy, hh:mm aa"
                  : "dd/MM/yyyy, HH:mm"
                }
              />
            </FormGroup>
            
            {/* Fim */}
            <FormGroup className="mb-0 mt-2">
              <Label for="end" className="ml-1">
                {t('alarms.searchEnd')}:
              </Label>
              <DatePicker
                id="end"
                className="form-control"
                showTimeSelect
                isClearable
                placeholderText={t('alarms.datepickerPlaceholder')}
                selected={endDate}
                minDate={startDate}
                maxDate={new Date()}
                onChange={date => setEndDate(date)}
                dateFormat={(i18n.language ==="en")
                  ? "MMMM d, yyyy, hh:mm aa"
                  : "dd/MM/yyyy, HH:mm"
                }
              />
            </FormGroup>

            {/* Checkboxes de estado de ativação */}
            <div className="d-flex justify-content-between mt-2">
              <Label className="ml-1">
              {t('alarms.searchActive')}:
              </Label>
              <FormGroup check inline className="mb-0 ml-2">
                <Label check className="cursor-pointer">
                  <Input
                    type="checkbox"
                    name="active"
                    className="cursor-pointer"
                    checked={active}
                    onChange={()=>setActive(!active)}
                  />
                  {t('alarms.active')}
                </Label>
              </FormGroup>
                
              <FormGroup check inline className="mb-0 ml-auto">
                <Label check className="ml-5 cursor-pointer">
                  <Input
                    type="checkbox"
                    name="inactive"
                    className="cursor-pointer"
                    checked={inactive}
                    onChange={()=>setInactive(!inactive)}
                  />
                  {t('alarms.inactive')}
                </Label>
              </FormGroup>

              <FormGroup check inline className="mb-0 ml-auto">
              </FormGroup>
            </div>
          </Col>

          {/* Botão de busca */}
          <Col md={2} className="d-flex align-items-end justify-content-end">
            <Button color="primary" type="submit">
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </Col>
        </Row>
      </Form>
    </div>

    {(loadAlarms) // se está carregando...
      // retorna spinner
      ? <div className="text-center">
          <Spinner color="primary" />
        </div>
      // se não está mais carregando, verifica se há alarmes
      : (!alarms.length)
        // se não há alarmes e não está mais carregando, é porque não tem alarmes mesmo
        ? <p className="text-center mt-5">
            {t('alarms.noAlarmHistory')}.
          </p>
        // se não está mais carregando e há alarmes, mostra a tabela
        : <div>
            <BootstrapTable // Tabela
              bootstrap4
              keyField="id"
              data={ alarms }
              columns={ alarmCols }
              condensed
              bordered={false}
              striped={true}
              sort={sortIcon}
              pagination={paginationFactory(paginationOptions)}
            />
          </div>
    }
  </div>
};

/*** Página ***/
const AlarmView = () => {
  const { t } = useTranslation();
  
  return (
    <Container fluid>
      <Header>
        <HeaderTitle>{t('alarms.titleView')}</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">{t('sidebar.home')}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/">{t('sidebar.alarms')}</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>{t('sidebar.alarmView')}</BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Card style={{
        overflowX:"scroll",
        paddingBottom: (window.innerWidth < 700) ? "30px" : "0",
        marginTop: "-26px"
      }}>
        <CardHeader>
          <CardTitle tag="h5" className="mb-0">
            {t('sidebar.alarmView')}
          </CardTitle>
        </CardHeader>

        <CardBody style={{minWidth: "1010px"}}>
          
          <AlarmViewTable />

        </CardBody>
      </Card>
    </Container>
  );
};

export default AlarmView;
